import useControl from 'react-map-gl/dist/esm/components/use-control';
import React, { CSSProperties, useState } from 'react';
import { IControl } from 'react-map-gl';
import { createPortal } from 'react-dom';
import { CiSatellite1 } from 'react-icons/ci';

const SatelliteButton = ({
	onClick,
	style,
}: {
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	style: CSSProperties | undefined;
}) => {
	return (
		<div className={`mapboxgl-ctrl mapboxgl-ctrl-group`} id='satellite-control-btn' style={style}>
			<button className='p-1 mapboxgl-ctrl-custom-satellite' onClick={onClick}>
				<CiSatellite1 className='mapboxgl-ctrl-icon' />
			</button>
		</div>
	);
};

const SatelliteControl = ({
	onClick,
	style,
}: {
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	style?: CSSProperties | undefined;
}) => {
	const [container, setContainer] = useState<HTMLDivElement | null>(null);

	useControl<IControl>(
		() => {
			const el = document.createElement('div') as HTMLDivElement;
			el.setAttribute('id', 'satellite-control-container');
			setContainer(el);

			const onAdd = (map: mapboxgl.Map) => {
				map.getCanvasContainer().appendChild(el);
				return el;
			};

			const onRemove = (_map: mapboxgl.Map) => {
				el.remove();
			};

			return {
				onAdd,
				onRemove,
			};
		},
		{ position: 'bottom-right' },
	);

	return container
		? createPortal(<SatelliteButton onClick={onClick} style={style} />, container)
		: null;
};

export default SatelliteControl;
