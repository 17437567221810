import { FormHelperText, TextField } from '@mui/material';
import { ComponentProps, useCallback } from 'react';
import {
	Controller,
	FieldError,
	FieldValues,
	Control,
	Path,
	PathValue,
	RegisterOptions,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function ControlledField<
	Schema extends FieldValues,
	Name extends Path<Schema>,
	ComponentType extends typeof TextField,
>({
	name,
	className,
	control,
	rules,
	Component,
	inputProps,
	componentProps,
	translateErrorMessage = false,
	wrapperClassName = 'flex flex-col w-full',
}: {
	name: Name;
	control: Control<Schema>;
	Component: ComponentType;
	componentProps?: ComponentProps<ComponentType>;
	inputProps?: ComponentProps<ComponentType>['inputProps'];
	id?: string;
	className?: string;
	wrapperClassName?: string;
	required?: boolean;
	rules?: Omit<
		RegisterOptions<Schema, Name>,
		'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
	>;
	translateErrorMessage?: boolean | Record<string, unknown>;
}) {
	const { t } = useTranslation();

	const controllerError = useCallback(
		(error: FieldError | undefined) => {
			if (!translateErrorMessage) {
				return error?.message;
			}
			if (error && error.message) {
				return t(
					error.message,
					typeof translateErrorMessage === 'object' ? translateErrorMessage : undefined,
				);
			}
			return undefined;
		},
		[t, translateErrorMessage],
	);

	return (
		<div className={wrapperClassName} data-test-id={`${name}-controlled-wrapper`}>
			<Controller
				name={name}
				control={control}
				rules={rules}
				defaultValue={'' as PathValue<Schema, Name>}
				render={({ field: { onBlur, onChange, value, ref }, fieldState: { error } }) => {
					const errorMsg = controllerError(error);
					const ActualComponent = Component as any;
					const {
						InputProps: componentInputProps,
						helperText: componentHelperText,
						...componentPropsRest
					} = componentProps || {};
					return (
						<>
							<ActualComponent
								error={Boolean(errorMsg)}
								data-test-id={`${name}-test-field`}
								className={className}
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								inputRef={ref}
								InputProps={{
									...componentInputProps,
									...inputProps,
									style: { height: inputProps?.height, padding: inputProps?.padding },
									className: `disabled:!bg-slate-100 ${inputProps?.className}`,
								}}
								{...componentPropsRest}
							/>
							<FormHelperText error={Boolean(errorMsg)} sx={{ pl: 1.5 }}>
								{Boolean(errorMsg) && errorMsg ? errorMsg : componentHelperText}
							</FormHelperText>
						</>
					);
				}}
			/>
		</div>
	);
}
