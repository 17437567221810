import { useMutations } from '@/graphql/useMutations';
import { UPLOAD_MEDIA } from '@/graphql/media';
import { DELETE_MEDIA } from '@/graphql/media/mutation';
import { IUPLOAD_MEDIA_RESPONSE } from '@/graphql/media/mutation';

export const useMediaUpload = (callback?: any) =>
	useMutations<unknown, IUPLOAD_MEDIA_RESPONSE>(['multipleMediaUpload'], UPLOAD_MEDIA, {
		onSuccess: () => {
			callback?.();
		},
	});

export const useDeleteMedia = (callback?: any) =>
	useMutations(['deleteMedia'], DELETE_MEDIA, {
		onSuccess: () => {
			callback?.();
		},
	});
