import { useGetWizardsShown } from '@/api/auth';
import { wizardsShownInterface } from '@/graphql/registration/query';
import { PropsWithChildren, createContext, memo } from 'react';
import { useClient } from './GraphqlRequestContext';

type ContextValue = null | wizardsShownInterface;

export const WizardsShownContext = createContext<ContextValue>(null);

const WizardsShownContextProviderBase = ({ children }: PropsWithChildren) => {
	const { hasAuthorizationHeader } = useClient();
	const { data } = useGetWizardsShown(hasAuthorizationHeader);
	return (
		<WizardsShownContext.Provider value={data ? data.wizardsShown : null}>
			{children}
		</WizardsShownContext.Provider>
	);
};

export const WizardsShownContextProvider = memo(WizardsShownContextProviderBase);
