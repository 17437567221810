import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

type MapStyleContextType = {
	isMapStyleSatellite: boolean;
	handleMapStyle: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const MapStyleContext = createContext<MapStyleContextType>({
	isMapStyleSatellite: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	handleMapStyle: () => {},
});

export const MapStyleProvider = ({ children }: { children: ReactNode }) => {
	const [isMapStyleSatellite, setIsMapStyleSatellite] = useState<boolean>(false);

	const handleMapStyle = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event.preventDefault();
			setIsMapStyleSatellite((prevIsMapStyleSatellite: boolean) => !prevIsMapStyleSatellite);
		},
		[setIsMapStyleSatellite],
	);

	return (
		<MapStyleContext.Provider value={{ isMapStyleSatellite, handleMapStyle }}>
			{children}
		</MapStyleContext.Provider>
	);
};

export const useMapStyle = () => {
	return useContext(MapStyleContext);
};
