import { memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary, ErrorFallback } from '@components/error-boundary';
import { TalkSessionProvider } from '@/context/TalkSessionContext';
import SideMenu from '../sideMenu/SideMenu';
import config from '@/config/config';
import { CONFIG } from '@/config';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../Button';

export const MAIN_ID = 'main-id-keelspot';

function AppLayout() {
	const location = useLocation();
	const isMapPage =
		location.pathname.includes('map') ||
		location.pathname.includes('matchmaking') ||
		location.pathname.includes('carriers');
	const headerWarning = config.WARNING_MESSAGE ? true : false;
	const { getAccessTokenSilently } = useAuth0();
	const ksspoofid = localStorage.getItem('spoof');
	async function unspoof() {
		localStorage.removeItem('spoof');
		const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
		fetch(`${CONFIG.API_V2}/auth/unspoof`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}).then((res) => {
			window.location.reload();
		});
	}

	async function updateUserSession() {
		const sessId = sessionStorage.getItem('sessionId');
		if (sessId) {
			const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
			await fetch(`${CONFIG.API_V2}/track/update/${sessId}`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access_token}`,
				},
			});
		}
	}

	useEffect(() => {
		const startSession = async () => {
			try {
				const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
				const response = await fetch(`${CONFIG.API_V2}/track/start-session`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${access_token}`,
					},
				});
				const data = await response.json();
				if (data._id) {
					sessionStorage.setItem('sessionId', data._id);
				}
			} catch (error) {
				console.error('Error starting session:', error);
			}
		};

		startSession();
		const interval = setInterval(updateUserSession, 25000);
		return () => clearInterval(interval);
	}, []);

	return (
		<TalkSessionProvider>
			<div className='min-h-full lg:flex lg:flex-col'>
				{headerWarning && (
					<div className='w-full h-4 fixed top-0 z-[999] bg-orange-500 text-white text-xs font-extrabold text-center items-center'>
						{config.WARNING_MESSAGE}
					</div>
				)}
				{ksspoofid && (
					<div className='w-full h-6 fixed top-0 z-[999] bg-teal-500 text-white text-xs font-extrabold text-center items-center'>
						<div className='flex w-full justify-center items-center'>
							<b>Spoofing user!</b>{' '}
							<Button onClick={unspoof} className='mx-2' size={'xs'}>
								Unspoof
							</Button>
						</div>
					</div>
				)}
				<SideMenu />
				<div
					id={MAIN_ID}
					className={`mx-auto min-h-full ${
						isMapPage
							? 'grow w-full pt-[72px]'
							: 'grow w-full py-[100px] px-2 sm:px-6 md:px-8 lg:px-12 xl:px-16 max-w-[1400px]'
					}`}
				>
					<ErrorBoundary
						FallbackComponent={ErrorFallback}
						onError={() => 'ss'}
						// onReset={() => window.location.replace(window.location.href)}
					>
						<Outlet />
					</ErrorBoundary>
				</div>
			</div>
		</TalkSessionProvider>
	);
}

export default memo(AppLayout);
