import {
	FC,
	createContext,
	useContext,
	useEffect,
	PropsWithChildren,
	useState,
	useRef,
} from 'react';

import { CONFIG } from '@/config';
import { useAuth0 } from '@auth0/auth0-react';
import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { ClientError, Response } from 'graphql-request/dist/types';

interface IGraphqlRequestContext {
	client: GraphQLClient;
	publicClient: GraphQLClient;
	hasAuthorizationHeader: boolean;
}

const initialValue: IGraphqlRequestContext = {
	client: new GraphQLClient(CONFIG.BASE_URI),
	publicClient: new GraphQLClient(CONFIG.PUBLIC_BASE_URI),
	hasAuthorizationHeader: false,
};
const GraphqlRequestContext = createContext<IGraphqlRequestContext>(initialValue);

const AUTH_ERROR_CODES = [401];

const hasAuthToken = () => {
	return Boolean(localStorage.getItem('hasAuthToken'));
};

export const GraphqlRequestContextProvider: FC<PropsWithChildren> = ({ children }) => {
	const [clientAuthorized, setHasAuthorizationHeader] = useState(false);
	const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

	const responseMiddleware = (response: Response<unknown> | Error) => {
		try {
			if (response instanceof ClientError) {
				const {
					response: { status, errors },
				} = response;
				if (AUTH_ERROR_CODES.includes(status) && hasAuthToken()) {
					logout();
				}
				console.error(
					`Request error:
						status ${status}
						details: ${errors?.map(({ message }) => message)}
					`,
				);
				return;
			}

			if (response instanceof Error) {
				return;
			}

			if (response.errors) {
				const traceId = response.headers.get('x-b3-traceid') || 'unknown';
				console.error(
					`[${traceId}] Request error:
						status ${response.status}
						details: ${response.errors}
					`,
				);
			}
		} catch (e) {
			logout();
		}
	};

	const requestMiddleware = async (request: RequestInit): Promise<RequestInit> => {
		try {
			if (hasAuthToken()) {
				const tokenExpirationTime = parseInt(localStorage.getItem('tokenExpirationTime') || '0', 10);
				const currentTime = Date.now() / 1000;
				const timeToRefresh = 300; // Refresh the token 5 minute before it expires

				if (tokenExpirationTime - currentTime < timeToRefresh) {
					const { access_token, expires_in } = await getAccessTokenSilently({ detailedResponse: true });
					client.current.setHeader('authorization', `Bearer ${access_token}`);
					localStorage.setItem('tokenExpirationTime', String(expires_in));
				}
			}
		} catch (e) {
			logout();
		}

		return {
			...request,
		};
	};

	const publicResponseMiddleware = (response: Response<unknown> | Error) => {
		if (response instanceof ClientError) {
			if (response instanceof ClientError) {
				const {
					response: { status, errors },
				} = response;
				console.error(
					`Request error:
						status ${status}
						details: ${errors?.map(({ message }) => message)}
					`,
				);
				return;
			}
		}
		if (response instanceof Error) {
			return;
		}
		if (response?.errors) {
			const traceId = response.headers.get('x-b3-traceid') || 'unknown';
			console.error(
				`[${traceId}] Request error:
        			status ${response.status}
        			details: ${response.errors}
				`,
			);
		}
	};

	const client = useRef(
		new GraphQLClient(CONFIG.BASE_URI, {
			requestMiddleware,
			responseMiddleware,
		}),
	);

	const publicClient = useRef(
		new GraphQLClient(CONFIG.PUBLIC_BASE_URI, {
			responseMiddleware: publicResponseMiddleware,
		}),
	);

	useEffect(() => {
		if (isAuthenticated) {
			getAccessTokenSilently({ detailedResponse: true }).then(({ access_token, expires_in }) => {
				client.current.setHeader('authorization', `Bearer ${access_token}`);
				localStorage.setItem('tokenExpirationTime', String(expires_in));
				localStorage.setItem('hasAuthToken', 'true');
				setHasAuthorizationHeader(true);
			});
		} else {
			client.current.setHeader('authorization', '');
			localStorage.setItem('hasAuthToken', 'false');
			setHasAuthorizationHeader(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	return (
		<GraphqlRequestContext.Provider
			value={{
				client: client.current,
				hasAuthorizationHeader: clientAuthorized,
				publicClient: publicClient.current,
			}}
		>
			{children}
		</GraphqlRequestContext.Provider>
	);
};

export const useClient = () => {
	const context = useContext(GraphqlRequestContext);

	if (context) {
		return context;
	}

	throw new Error(`useClient must be used within a GraphqlRequestContextProvider`);
};
