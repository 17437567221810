import { Modal } from '@/components/modal';
import { SpecificationTextGroup } from '../atomic/SpecificationText';

export const CreditScoreModal = ({
	onClose,
	creditGrade,
	creditDescription,
}: {
	onClose: () => void;
	creditGrade: string | undefined;
	creditDescription: string | undefined;
}) => (
	<Modal title='Credit Rating' isOpen onClose={onClose} dialogAction={false}>
		<div className='pb-2 px-6 w-[500px]'>
			<div className='flex flex-row justify-between w-full'>
				<div className='flex'>
					<SpecificationTextGroup
						items={[
							{
								subTitle: 'Credit Grade',
								title: creditGrade || '',
								postTitle: '',
							},
						]}
					/>
				</div>
				<div className='flex'>
					<SpecificationTextGroup
						items={[
							{
								subTitle: 'Description',
								title: creditDescription || '',
								postTitle: '',
							},
						]}
					/>
				</div>
			</div>
			<div className='py-8'>
				<p>
					Credit information is provided by Creditsafe™. For a full report, contact{' '}
					<a href='mailto:sav@keelspot.com'>Keelspot</a>.
				</p>
			</div>
		</div>
	</Modal>
);
