import { ReactNode, FC } from 'react';
import { styled } from '@mui/material/styles';

import { ButtonProps, FormControlLabel, Typography } from '@mui/material';
// const drawerWidth = 280;

interface MenuButtonProps extends ButtonProps {
	$active?: boolean;
	spacing?: string;
	component?: any;
}

export const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 2),
	...theme.mixins.toolbar,
}));
// eslint-disable-next-line no-empty-pattern

interface RadioInputProps {
	title: string;
	text: string;
	icon: ReactNode;
}

export const radioInputContent: FC<RadioInputProps> = (radioprop) => (
	<div className='flex items-center'>
		{radioprop.icon}
		<div className='sm:ml-8 py-2'>
			<Typography className='leading-4 my-2' variant='body1'>
				{radioprop.title}
			</Typography>
			<Typography variant='body2'>{radioprop.text}</Typography>
		</div>
	</div>
);

export const SwitchRadioButton = styled(FormControlLabel)<MenuButtonProps>((props: any) => ({
	borderRadius: 8,
	boxShadow: '0px 1px 2px 0px rgba(119, 118, 117, 0.06)',
	border: `2px solid rgba(0, 0, 0, 0)`,
	width: 'fit-content',
	color: props.theme.palette.text.secondary,
	fontSize: '14px',
	fontWeight: '400',
	'& p:first-of-type': {
		color: props.theme.palette.text.primary,
		fontSize: '18px',
		fontWeight: '600',
	},
	...(props.$active && {
		border: `2px solid ${props.theme.palette.primary.main}`,
		'& p:first-of-type': {
			color: props.theme.palette.primary.main,
			fontSize: '18px',
			fontWeight: '600',
		},
		'& svg': {
			color: props.theme.palette.primary.main,
		},
	}),
})) as any;
