import { Map, Marker, Source, Layer, GeoJSONSource, MapRef } from 'react-map-gl';
import { FC, PropsWithChildren, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sources, layers } from './mapConfig';
import { CONFIG } from '@/config';
import { PointA } from '@/assets/icons/pointA';
import { useMapBox } from '@/context/MapBoxContext';
import bbox from '@turf/bbox';
import * as turf from '@turf/turf';
import {
	getLayers,
	VESSEL_ROUTE_LINE_LAYER_ID,
	VESSEL_ROUTE_LINE_SOURCE_ID,
	VESSEL_ROUTE_MARKER_LAYER_ID,
	VESSEL_ROUTE_MARKER_SOURCE_ID,
	VESSEL_SELECTED_LAYER_ID,
} from './layer';
import { APP, FREIGHT } from '@/config/path';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import { OperationEnum } from '@/enums/OperationEnum';
import { useSelectVessel } from '@/context/SelectedVesselContext';
import { useGetVesselHistoricalPositionDatalastic } from '@/api/vessel';
import { IVesselHistoryPosition } from '@/interfaces/datalastic/IVesselHistory';
import { IVesselPathCoordinate } from '@/interfaces/map/IVesselPath';
import { Stack } from '@mui/material';
import { MapLabel } from './Label';

export const MAP_STYLES = {
	street: 'mapbox://styles/keelspot/clcakamjn00do14oxyjdgdl2g',
	satellite: 'mapbox://styles/keelspot/cldona1p3000z01phre7nqthz',
};
const CLUSTER_ZOOM_LEVEL = 15;
const TERMINAL_ZOOM_LEVEL = 15;
const VESSEL_PATH_SOURCES_ID = [VESSEL_ROUTE_MARKER_SOURCE_ID, VESSEL_ROUTE_LINE_SOURCE_ID];
const VESSEL_PATH_LAYERS_ID = [VESSEL_ROUTE_MARKER_LAYER_ID, VESSEL_ROUTE_LINE_LAYER_ID];

type ICoordinate =
	| {
			lng: number;
			lat: number;
			key: any;
			draggable: true;
			onDrag?: (...props: any[]) => void;
			icon?: React.ReactNode;
			label?: string;
	  }
	| {
			lng: number;
			lat: number;
			key: any;
			draggable: false;
			onDrag?: never;
			label?: string;
			icon?: React.ReactNode;
	  };

type Props = PropsWithChildren<{
	coordinates?: Array<ICoordinate>;
	isMapStyleSatellite?: any;
	onRef?: any;
	supercluster?: any;
	onDblClick?: any;
	onLoad?: () => void;
	autoFlyToNewCoordinates?: boolean;
	scrollZoomInital?: boolean;
	defaultConfig?: {
		lat?: number;
		lng?: number;
		zoom?: number;
	};
	mapStyles?: { [key: string]: string };
}>;
export const MapBox: FC<Props> = ({
	children,
	coordinates = [],
	isMapStyleSatellite,
	onRef,
	supercluster,
	onDblClick,
	autoFlyToNewCoordinates,
	onLoad,
	defaultConfig,
	scrollZoomInital = true,
	mapStyles,
}) => {
	const { currentUser } = useGetCurrentUser();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [map, setMap, visibleFreightsList] = useMapBox();
	const dnlSource = sources.dnl;
	const dnlLayers = layers.dnl;
	const uniqueRef = useRef<MapRef>(null);
	const { clusterLayer, unclusteredPointLayer, routeLineLayer, routeMarkerLayer } = useMemo(
		getLayers,
		[],
	);
	// const [hoveredMarker, setHoveredMarker] = useState(null);
	const [selectedVessel, setSelectedVessel] = useSelectVessel();
	const [scrollZoom, setScrollZoom] = useState(scrollZoomInital);

	const { data: vesselHistoricalPosition } = useGetVesselHistoricalPositionDatalastic(
		selectedVessel.id,
	);

	const [viewport, setViewport] = useState<any>({
		width: '100%',
		height: '100%',
		latitude: searchParams.get('lat') ?? defaultConfig?.lat ?? 40,
		longitude: searchParams.get('lng') ?? defaultConfig?.lng ?? 10,
		zoom: searchParams.get('zoom') ?? defaultConfig?.zoom ?? 1.75,
	});

	useEffect(() => {
		if (onRef) onRef(uniqueRef);
	}, [onRef]);

	useEffect(() => {
		if (coordinates.length > 1 && map) {
			const coordinateLine = coordinates.map(({ lng, lat }) => [lng, lat]);
			const line = turf.lineString(coordinateLine);

			const [minLng, minLat, maxLng, maxLat] = bbox(line);

			map?.fitBounds(
				[
					[minLng, minLat],
					[maxLng, maxLat],
				],
				{ padding: { top: 80, bottom: 20, left: 100, right: 150 }, duration: 1000 },
			);
		}
	}, [coordinates, map]);

	useEffect(() => {
		if (!map) return;

		const handleIdle = () => {
			setFirstVisibleFreights();
			map.off('idle', handleIdle);
		};

		map.on('idle', handleIdle);

		return () => {
			map.off('idle', handleIdle);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map]);

	useEffect(() => {
		setFirstVisibleFreights();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewport]);

	useMemo(() => {
		if (autoFlyToNewCoordinates && coordinates.length > 0) {
			const mapRef = onRef ? uniqueRef?.current : map;
			const coordinate = coordinates[0];

			mapRef?.flyTo({
				center: [coordinate.lng, coordinate.lat],
				duration: 100,
				zoom: TERMINAL_ZOOM_LEVEL,
				curve: 1,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coordinates, autoFlyToNewCoordinates]);

	const setFirstVisibleFreights = useCallback(() => {
		if (!supercluster || !map) return;

		const clustersLayer = map.getLayer('clusters');
		const unClusteredLayer = map.getLayer('unclustered-point-circle');
		const features =
			(clustersLayer || unClusteredLayer) &&
			map.queryRenderedFeatures({
				layers: ['clusters', 'unclustered-point-circle'],
			});

		if (features?.length === 0) return visibleFreightsList.setVisibleFreights([]);

		const visibleClusterIds = features?.flatMap((f: any) => {
			if (f?.properties?.cluster && f?.properties?.cluster === true) {
				const clusterId = f?.properties?.cluster_id;
				if (!clusterId) return;

				const leaves = supercluster?.getLeaves(clusterId, Infinity);
				return leaves.map((leave: any) => leave?.properties?.id);
			}
			return f.properties.id;
		});
		const uniqueClusterIds = [...new Set(visibleClusterIds)];
		visibleFreightsList.setVisibleFreights(uniqueClusterIds);
	}, [supercluster, map, visibleFreightsList]);

	const markers = useMemo(
		() =>
			coordinates.map(({ lng, lat, draggable, onDrag, icon, label }) => (
				<Marker
					key={`lng-${lng}-lat-${lat}`}
					longitude={lng}
					latitude={lat}
					anchor='bottom'
					draggable={draggable}
					onDragEnd={onDrag}
					onClick={(e) => {
						e.originalEvent.stopPropagation();
					}}
				>
					<Stack alignItems='center'>
						{label && <MapLabel>{label}</MapLabel>}
						{icon || PointA}
					</Stack>
				</Marker>
			)),
		[coordinates],
	);

	const vesselPathCoordinates: IVesselPathCoordinate[] = useMemo(() => {
		if (!selectedVessel.id || !selectedVessel.coordinates || !vesselHistoricalPosition) {
			return [];
		}

		const coordinates: IVesselPathCoordinate[] = vesselHistoricalPosition.map(
			(path: IVesselHistoryPosition) => ({
				coordinate: [path.lon, path.lat],
				speed: path.speed,
				course: path.course,
				heading: path.heading,
				destination: path.destination,
				last_position_epoch: path.last_position_epoch,
				last_position_UTC: path.last_position_UTC,
			}),
		);
		return coordinates;
	}, [selectedVessel, vesselHistoricalPosition]);

	const lineSource = useMemo(() => {
		if (!selectedVessel.coordinates) return;
		return {
			type: 'geojson',
			data: {
				type: 'Feature',
				geometry: {
					type: 'LineString',
					coordinates: [
						[...selectedVessel.coordinates],
						...vesselPathCoordinates.map((position: IVesselPathCoordinate) => [...position.coordinate]),
					],
				},
			},
		};
	}, [vesselPathCoordinates, selectedVessel]);

	const markerSource = useMemo(() => {
		return {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: vesselPathCoordinates.map((path: IVesselPathCoordinate) => ({
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: path.coordinate,
					},
					properties: {
						lon: path.coordinate[0],
						lat: path.coordinate[1],
						speed: path.speed,
						course: path.course,
						heading: path.heading,
						destination: path.destination,
						last_position_epoch: path.last_position_epoch,
						last_position_UTC: path.last_position_UTC,
					},
				})),
			},
		};
	}, [vesselPathCoordinates]);

	const onClick = useCallback(
		(event: any) => {
			setScrollZoom(true);
			const feature = event?.features[0];
			const clusterId = feature?.properties?.cluster_id;
			const mapCurrentZoom = map?.getZoom();

			if (!clusterId && feature) {
				const shipmentId = feature?.properties?.id;
				// Is in transit?
				if (feature?.properties?.deliveryUrl !== undefined) {
					return navigate(feature?.properties?.deliveryUrl);
				}

				if (currentUser?.operation === OperationEnum.SHIP_OPERATOR) {
					return navigate(`/${APP}/${FREIGHT.REQUEST}/${shipmentId}`);
				}
				return navigate(`/${APP}/${FREIGHT.INDEX}/offers/${shipmentId}`);
			}

			const mapboxSource = map.getSource('bundles') as GeoJSONSource;
			if (!mapboxSource) return;

			mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
				if (err) {
					return;
				}
				const flyZoom = mapCurrentZoom >= CLUSTER_ZOOM_LEVEL ? mapCurrentZoom : CLUSTER_ZOOM_LEVEL;

				if (!feature?.geometry?.coordinates) return;

				map.flyTo({
					center: feature.geometry.coordinates,
					zoom: zoom >= 18 ? flyZoom : zoom,
					duration: 100,
				});
			});
		},
		[map, navigate, currentUser],
	);

	const handleViewportChange = useCallback((newViewport: any) => {
		setViewport(newViewport);
	}, []);

	useEffect(() => {
		setSelectedVessel({ id: null, coordinates: null, matchmaking: false });
	}, [setSelectedVessel]);

	useEffect(() => {
		if (map && selectedVessel.id && selectedVessel.coordinates && vesselHistoricalPosition) {
			if (!map.getSource(VESSEL_ROUTE_LINE_SOURCE_ID)) {
				map.addSource(VESSEL_ROUTE_LINE_SOURCE_ID, lineSource);
			}
			if (!map.getSource(VESSEL_ROUTE_MARKER_SOURCE_ID)) {
				map.addSource(VESSEL_ROUTE_MARKER_SOURCE_ID, markerSource);
			}

			if (!map.getLayer(VESSEL_ROUTE_LINE_LAYER_ID)) {
				map.addLayer(routeLineLayer, VESSEL_SELECTED_LAYER_ID);
			}

			if (!map.getLayer(VESSEL_ROUTE_MARKER_LAYER_ID)) {
				map.addLayer(routeMarkerLayer, VESSEL_SELECTED_LAYER_ID);
			}
			return;
		}

		VESSEL_PATH_LAYERS_ID.forEach((layer) => {
			if (map?.getLayer(layer)) {
				map.removeLayer(layer);
			}
		});

		VESSEL_PATH_SOURCES_ID.forEach((source) => {
			if (map?.getSource(source)) {
				map.removeSource(source);
			}
		});
	}, [
		lineSource,
		map,
		markerSource,
		routeLineLayer,
		routeMarkerLayer,
		selectedVessel,
		vesselHistoricalPosition,
		vesselPathCoordinates,
	]);

	// Remove these hoveredMarker business, seems slow.

	// map?.on('mouseenter', VESSEL_ROUTE_MARKER_LAYER_ID, (e: any) => {
	// 	if (map) {
	// 		const canvas = map.getCanvas();
	// 		canvas.style.cursor = 'pointer';
	// 	}
	// 	if (e.features.length > 0 && !selectedVessel.id) setHoveredMarker(e.features[0].properties);
	// });

	// map?.on('mouseleave', VESSEL_ROUTE_MARKER_LAYER_ID, (_e: any) => {
	// 	if (map) {
	// 		const canvas = map.getCanvas();
	// 		canvas.style.cursor = '';
	// 	}
	// 	if (selectedVessel.id) setHoveredMarker(null);
	// });

	return (
		<Map
			onMove={handleViewportChange}
			initialViewState={viewport}
			reuseMaps
			ref={onRef ? uniqueRef : (e) => setMap(e && e.getMap())}
			style={mapStyles ? mapStyles : { height: '100%' }}
			mapboxAccessToken={CONFIG.MAPBOX_ACCESS_TOKEN}
			dragRotate={false}
			interactiveLayerIds={[clusterLayer.id!, unclusteredPointLayer.id!]}
			onClick={onClick}
			mapStyle={isMapStyleSatellite ? MAP_STYLES.satellite : MAP_STYLES.street}
			attributionControl={true}
			onLoad={onLoad}
			scrollZoom={scrollZoom}
		>
			<Source type={dnlSource.type} tiles={dnlSource.tiles} maxzoom={dnlSource.maxzoom}>
				{dnlLayers.map((layer) => (
					<Layer key={layer.id} {...layer} />
				))}
				{/* Remove hovered marker business */}
				{/* {hoveredMarker && <RouteMarkerPopup marker={hoveredMarker} />} */}
			</Source>

			{/* This isn't used any more, but can be readded if we want harbour boundaries again some day. */}
			{/* <Source
				type='raster'
				tileSize={2048}
				tiles={[
					// Add "&layers=havnedata" to re-enable harbour boundaries
					// Play with data here:
					// https://kartkatalog.geonorge.no/kart?lat=6735874.07073762&lon=-32294.66521654282&zoom=13.595433421323946
					'https://openwms.statkart.no/skwms1/wms.havnedata?bbox={bbox-epsg-3857}&service=WMS&request=GetMap&format=image/png&transparent=true&crs=EPSG:3857&width=2048&height=2048&version=1.3.0&layers=kaiomrade',
				]}
			>
				<Layer type='raster' paint={{ 'raster-opacity': 1 }} />
			</Source> */}
			{markers}
			{children && children}
		</Map>
	);
};
