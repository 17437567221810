import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const langDetectorOptions = {
	// order and from where user language should be detected
	order: ['localStorage', 'cookie', 'navigator'],

	// keys or params to lookup language from
	lookupCookie: 'locale',
	lookupLocalStorage: 'locale',

	// cache user language on
	caches: ['localStorage', 'cookie'],
	excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

	// only detect languages that are in the whitelist
	checkWhitelist: true,
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		ns: ['translation'],
		defaultNS: 'translation',
		supportedLngs: ['en' /* 'nb' */],
		backend: {
			loadPath: `/locales/{{lng}}/{{ns}}.json`,
			requestOptions: {
				cache: 'no-store',
			},
		},
		detection: langDetectorOptions,
		interpolation: {
			escapeValue: false,
		},
		react: { useSuspense: true },
	});

export default i18n;
