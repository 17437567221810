import { gql } from 'graphql-request';

export const NOTIFICATION_LIST = gql`
	query listNotifications {
		listNotifications {
			message
			data {
				_id
				notification
				type
				active
				relationId
				createdAt
				shipment {
					_id
					name
				}
				freightOwner {
					_id
					name
				}
			}
		}
	}
`;
