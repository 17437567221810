import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type BaseProps = {
	children: ReactNode;
	className?: string;
	ariaLabel?: string;
	id?: string;
};

type ButtonProps = BaseProps & {
	as: 'button';
	onClick: (e: any) => void;
};

type AnchorProps = BaseProps & {
	as: 'a';
	href: string;
	target?: string;
};

// Combine BaseProps and ButtonProps or AnchorProps
type Props = ButtonProps | AnchorProps;

const isButtonProps = (props: Props): props is ButtonProps => {
	return props.as === 'button';
};

export const InlineTextButton: React.FC<Props> = (props) => {
	const { children, className } = props;
	const buttonClassNames = twMerge(
		'font-semibold text-base p-0 m-0 bg-transparent border-0 text-keelspot-primary-main hover:text-keelspot-primary-dark underline cursor-pointer',
		className,
	);

	if (isButtonProps(props)) {
		return (
			<button
				className={buttonClassNames}
				onClick={(e) => {
					e.preventDefault();
					props.onClick(e);
				}}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						e.preventDefault();
						props.onClick(e);
					}
				}}
			>
				{children}
			</button>
		);
	} else {
		return (
			<a href={props.href} className={buttonClassNames} target={props.target}>
				{children}
			</a>
		);
	}
};
