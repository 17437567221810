// @ts-nocheck

import { useQueries } from '@/graphql/useQueries';
import { useMutations } from '@/graphql/useMutations';
import {
	AUTH_ME,
	CREATE_COMPANY,
	REGISTER_USER,
	UPDATE_AUTH,
	INVITE_MEMBERS,
	INVITED_MEMBER,
	DEACTIVATE_MEMBERS,
} from '@/graphql/registration';
import {
	AuthMeResponse,
	GET_WIZARDS_SHOWN,
	GetWizardsShownResponse,
	wizardsShownInterface,
} from '@/graphql/registration/query';
import { UPDATE_WIZARDS_SHOWN } from '@/graphql/registration/mutation';

export const useGetWizardsShown = (isAuthenticated: boolean) =>
	useQueries<GetWizardsShownResponse>({
		queryName: 'getWizardsShown',
		gql: GET_WIZARDS_SHOWN,
		config: {
			enabled: isAuthenticated,
		},
	});

export const useUpdateWizardsShown = (callback?: () => void) =>
	useMutations<Partial<{ input: { wizardsShown: wizardsShownInterface } }>, GetWizardsShownResponse>(
		['getWizardsShown'],
		UPDATE_WIZARDS_SHOWN,
		{
			onSuccess: () => {
				callback?.();
			},
		},
	);

export const useAuthMe = (isAuthenticated: boolean) =>
	useQueries<AuthMeResponse>({
		queryName: 'authMe',
		gql: AUTH_ME,
		config: {
			enabled: isAuthenticated,
		},
	});
export const useCreateCompany = (callback?: any) =>
	useMutations(['company'], CREATE_COMPANY, {
		onSuccess: () => {
			callback?.();
		},
		onError: (e: any) => {
			console.log(e);
		},
	});
export const useRegisterUser = (callback?: any) =>
	useMutations(
		['authMe'],
		REGISTER_USER,
		{
			onSuccess: () => {
				callback?.();
			},
		},
		'public',
	);
export const useUpdateAuth = (callback?: any) =>
	useMutations<
		{
			input: Partial<{
				firstName: string;
				lastName: string;
				phone: string;
				operations: Array<string>;
				acceptTermsAndConditions: true;
				wizardsShown: wizardsShownInterface;
				notifyFRDomestic: boolean;
				notifyFRInternational: boolean;
				notifyFRProject: boolean;
				notifyFRPartial: boolean;
				notifyFRContainer: boolean;
				notifyFRBulk: boolean;
				notifyCapacity: boolean;
			}>;
		},
		{
			authMe: {
				message: string;
				data: {
					_id: string;
				};
			};
		}
	>(['authMe'], UPDATE_AUTH, {
		onSuccess: () => {
			callback?.();
		},
	});

export const useInviteMembers = (callback?: any) =>
	useMutations(['listInvitedMember'], INVITE_MEMBERS, {
		onSuccess: () => {
			callback?.();
		},
	});

export const useGetInvitedMembers = () =>
	useQueries<any>({
		queryName: 'listInvitedMember',
		gql: INVITED_MEMBER,
	});

export const useDeactivateMember = (callback?: any) =>
	useMutations(['listInvitedMember'], DEACTIVATE_MEMBERS, {
		onSuccess: () => {
			callback?.();
		},
	});
