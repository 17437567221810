// @ts-nocheck

import { IVessel, IVesselAdditionalInfo } from '@/interfaces/datalastic/IVessel';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AISWrapper } from './Styles';
interface IEquipmentList {
	label: string;
	property: keyof IVesselAdditionalInfo;
	unit?: string;
}

export const AIS = ({ payload }: { payload: IVessel }) => {
	const { t } = useTranslation();
	const equipmentList: IEquipmentList[] = [
		{
			label: t('vesselDetails.additionalInfo.crane'),
			property: 'cranes',
			unit: t('common.units.labels.tonsLong'),
		},
		{ label: t('vesselDetails.additionalInfo.forklift'), property: 'forklift' },
		{ label: t('vesselDetails.additionalInfo.excavator'), property: 'excavator' },
		{ label: t('vesselDetails.additionalInfo.sideDoors'), property: 'sideDoor' },
		{ label: t('vesselDetails.additionalInfo.refeerRooms'), property: 'refeerRooms' },
		{
			label: t('vesselDetails.additionalInfo.refeerSlots'),
			property: 'refeerSlots',
			unit: t('common.units.labels.meterCubic'),
		},
	];

	const isAdditionalInfoPayloadEmpty = useMemo(() => {
		return equipmentList.every((equipment) => {
			const propertyValue = payload?.additionalInfo?.[equipment.property];
			return !propertyValue;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payload?.additionalInfo]);

	return (
		<>
			<AISWrapper className='mt-8'>
				<Typography variant='h6' className='px-6 mb-4'>
					{t('vesselDetails.vesselInformation')}
				</Typography>
				<div>
					<Typography variant='body2' className='font-medium'>
						{t('vesselDetails.name')}
					</Typography>
					<Typography variant='body2'>{payload.name}</Typography>
				</div>
				{payload?.dimension?.grossTonnage && (
					<div>
						<Typography variant='body2' className='font-medium'>
							Gross Tonnage
						</Typography>
						<Typography variant='body2'>{payload?.dimension?.grossTonnage}</Typography>
					</div>
				)}
				{payload?.dimension?.netTonnage && (
					<div>
						<Typography variant='body2' className='font-medium'>
							Net Tonnage
						</Typography>
						<Typography variant='body2'>{payload?.dimension?.netTonnage}</Typography>
					</div>
				)}
				{payload?.dimension?.dwt && (
					<div>
						<Typography variant='body2' className='font-medium'>
							DWT
						</Typography>
						<Typography variant='body2'>{payload?.dimension?.dwt} T</Typography>
					</div>
				)}
				<div>
					<Typography variant='body2' className='font-medium'>
						{t('vesselDetails.imo')}
					</Typography>
					<Typography variant='body2'>{payload.imo || payload.imoNumber}</Typography>
				</div>
				<div>
					<Typography variant='body2' className='font-medium'>
						{t('vesselDetails.callSign')}
					</Typography>
					<Typography variant='body2'>{payload.callSign}</Typography>
				</div>
				<div>
					<Typography variant='body2' className='font-medium'>
						{t('vesselDetails.additionalInfo.vesselType')}
					</Typography>
					<Typography variant='body2'>{payload.dl_subtype}</Typography>
				</div>
				<div>
					<Typography variant='body2' className='font-medium'>
						{t('vessel.destination')}
					</Typography>
					<Typography variant='body2'>{payload.destination}</Typography>
				</div>
			</AISWrapper>
			<AISWrapper>
				<div className='flex bg-neutral-200'>
					<Typography variant='subtitle2'> {t('vesselDetails.equipmentOnboard')}</Typography>
				</div>
				{isAdditionalInfoPayloadEmpty ? (
					<div>
						<Typography variant='body2' className='font-medium'>
							{t('vesselDetails.noEquipmentOnboard')}
						</Typography>
					</div>
				) : (
					equipmentList.map(
						(equipment) =>
							payload?.additionalInfo?.[equipment.property] && (
								<div key={equipment.label}>
									<Typography variant='body2' className='font-medium'>
										{equipment.label}
									</Typography>
									<Typography variant='body2'>
										<>
											{payload.additionalInfo[equipment.property]}
											{equipment.unit}
										</>
									</Typography>
								</div>
							),
					)
				)}
			</AISWrapper>
		</>
	);
};
