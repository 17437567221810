import { useState } from 'react';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import { Modal } from '@/components/modal';
import { CircularProgress, Typography } from '@mui/material';
import { SwitchOperationsMenu } from './OperationsMenu';
import { useTranslation } from 'react-i18next';
import Button from '@/components/Button';
import TextLabel from '@/components/atomic/TextLabel';
import { OperationEnum } from '@/enums/OperationEnum';
import { fetchToken } from '@/pages/demo/DemoFrontpage';
import { MdWarning } from 'react-icons/md';
interface SwitchOperationsProps {
	isOpen: boolean;
	onClose: () => void;
}

export const SwitchOperationsDemoModal = ({ onClose, isOpen }: SwitchOperationsProps) => {
	const { currentUser } = useGetCurrentUser();
	const [isLoading, setIsLoading] = useState(false);
	const [failedTokenFetch, setFailedTokenFetch] = useState(false);
	const isShipper = currentUser?.operation === OperationEnum.FREIGHT_FORWARDER;

	return (
		<Modal isOpen={isOpen} onClose={onClose} title={' '} dialogAction={false} dialogWidth='550px'>
			<div className='flex flex-col px-8 py-4 pb-8 gap-6'>
				<div className='flex flex-col gap-2 items-center'>
					{failedTokenFetch ? (
						<TextLabel
							size='md'
							text='Failed to fetch token. Please try again later.'
							color='error'
							icon={<MdWarning size={20} />}
						/>
					) : (
						<>
							<Typography className='text-center' variant='h5'>
								{isShipper ? 'Switch mode to Carrier?' : 'Switch mode to Shipper?'}
							</Typography>
							<Typography className='text-center' variant='body1'>
								In demo mode you can switch between roles at any time
							</Typography>
						</>
					)}
				</div>
				<div className='flex justify-center'>
					<div className='flex flex-col gap-1 min-w-[200px] mb-4'>
						{failedTokenFetch ? (
							<Button onClick={onClose} variant='outlined'>
								Go back
							</Button>
						) : (
							<>
								<Button
									startIcon={isLoading ? <CircularProgress size={24} /> : null}
									onClick={() => {
										if (!isLoading) {
											fetchToken(isShipper ? 'carrier' : 'shipper', setIsLoading, setFailedTokenFetch);
										}
									}}
								>
									Try as {isShipper ? 'Carrier' : 'Shipper'}
								</Button>
								<Button onClick={onClose} variant='outlined'>
									No, cancel
								</Button>
							</>
						)}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export const SwitchOperationsModal = ({ onClose, isOpen }: SwitchOperationsProps) => {
	const { t } = useTranslation();
	const { currentUser } = useGetCurrentUser();
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title={t('operationsDialog.heading')}
			dialogAction={false}
			dialogWidth='550px'
		>
			<div className='mb-5'>
				<span className='text-basefont-medium'>{t('operationsDialog.switchDescription')}</span>
			</div>
			<SwitchOperationsMenu onClose={onClose} operation={currentUser?.operation} />
		</Modal>
	);
};
