export type TextLabelProps = {
	color?:
		| 'primary'
		| 'primaryDark'
		| 'secondary'
		| 'error'
		| 'success'
		| 'successDark'
		| 'info'
		| 'warning'
		| 'black'
		| 'white';
	text: string | React.ReactNode;
	icon?: React.ReactNode;
	size?: 'xs' | 'sm' | 'md' | 'lg';
	square?: boolean;
	className?: string;
};

const colorClasses = {
	primary: 'text-keelspot-primary-dark bg-keelspot-primary-superLight',
	primaryDark: 'text-white bg-keelspot-primary-dark',
	secondary: 'text-keelspot-secondary-dark bg-keelspot-secondary-superLight',
	error: 'text-keelspot-error-dark bg-keelspot-error-superLight',
	success: 'text-keelspot-success-dark bg-keelspot-success-superLight',
	successDark: 'text-white bg-keelspot-success-dark',
	info: 'text-keelspot-info-dark bg-keelspot-info-superLight',
	warning: 'text-keelspot-warning-dark bg-keelspot-warning-superLight',
	black: 'text-white bg-keelspot-darkGrey',
	white: 'text-keelspot-darkGrey bg-white border-solid border-1 border-keelspot-font-grey',
	graybox:
		'text-keelspot-text-secondary bg-white border-solid border-1 border-keelspot-text-secondary',
};

const TextLabel = ({
	text,
	color = 'primary',
	icon,
	size = 'md',
	square,
	className,
}: TextLabelProps) => {
	const convertSize = (size: string) => {
		switch (size) {
			case 'xs':
				return 'px-1 pr-3 py-1 leading-4 text-xs gap-1';
			case 'sm':
				return 'px-2 pr-2 py-1 leading-4 text-xs gap-1';
			case 'md':
				return 'px-2 pr-3 py-2 leading-6 text-sm gap-2';
			case 'lg':
				return 'px-6 pr-8 py-4 leading-7 text-md gap-2';
			default:
				return '';
		}
	};
	return (
		<div
			className={`inline-flex align-center items-center ${convertSize(
				size || 'md',
			)} font-semibold rounded${square ? '' : '-md'} ${colorClasses[color]} ${className}`}
		>
			{icon}
			<span>{text}</span>
		</div>
	);
};

export default TextLabel;
