import React, { useContext, useState } from 'react';

const initialState: any[] = [null, null];

const MapboxContext = React.createContext(initialState);

const MapboxProvider = ({ children }: { children?: any }) => {
	const [map, setMap] = useState();
	const [visibleFreights, setVisibleFreights] = useState([]);
	const visibleFreightsList = React.useMemo(
		() => ({ visibleFreights, setVisibleFreights }),
		[visibleFreights],
	);
	return (
		<MapboxContext.Provider value={[map, setMap, visibleFreightsList]}>
			{children}
		</MapboxContext.Provider>
	);
};

export function useMapBox() {
	const context = useContext(MapboxContext);
	if (context === undefined) throw Error('You forgot to wrap your app with <MapProvider />');
	return context;
}

export const useMapboxContext = () => useContext(MapboxContext);

export { MapboxProvider, MapboxContext };
