// @ts-nocheck
import { styled, Dialog, IconButton, DialogProps } from '@mui/material';
interface StyledDialogProps extends DialogProps {
	dialogWidth?: string;
}

export const StyledDialog = styled(Dialog)<StyledDialogProps>`
	.MuiDialog-paper {
		width: '800px';
		max-width: ${(props) => props.dialogWidth};
	}
	.MuiDialogContent-root {
		padding-bottom: 0;
	}
	.MuiDialogActions-root {
		padding: 16px 24px;
	}
` as any;

export const ModalCloseButton = styled(IconButton)(({ theme }) => ({
	color: theme.palette.grey[500],
})) as any;
