/* eslint-disable react/display-name */
import React, { Fragment, useState, useCallback, useMemo } from 'react';
import { styled, Button, Popover, MenuItem } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSidebar } from '../layouts/hooks/useSidebar';
import { SwitchOperationsModal } from './SwitchOperations';
import { useDisclosure } from '@/hooks/useDisclosure';
import KeelspotLogo from '@/assets/KeelspotLogo';
import { Navbar } from '../layouts/Navbar';
import { MdMenu } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Arrow } from '@/assets/icons/arrow';
import * as PATH from '@config/path';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import { OperationEnum } from '@/enums/OperationEnum';
import { useMapDrawer } from '@/context/MapDrawerContext';
import { isInDemoMode } from '@/utils/isInDemoMode';

const Backdrop = styled('div')`
	opacity: 0;
	@keyframes backdrop-animation {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 0.5;
		}
	}
	animation: 0.25s backdrop-animation ease;
	animation-fill-mode: forwards;
	&.closed {
		animation-direction: reverse;
	}
`;

const SwitchOperations = React.memo(
	({ setSidebarOpen }: { setSidebarOpen: (open: boolean) => void }) => {
		const { isOpen, onOpen, onClose } = useDisclosure();
		const { t } = useTranslation();
		return (
			<>
				<Button
					startIcon={<Arrow />}
					onClick={() => {
						onOpen();
						setSidebarOpen(false);
					}}
				>
					{t('accountDropdown.switchOperation')}
				</Button>
				<SwitchOperationsModal isOpen={isOpen} onClose={onClose} />
			</>
		);
	},
);

const LinkButton = React.memo(({ item, pathname, navigate, setSidebarOpen }: any) =>
	item.path?.startsWith('http') ? (
		<Button
			key={item.path}
			className='lg:flex m-0'
			component='a'
			href={item.path}
			target='_blank'
			startIcon={item.icon}
		>
			{item.title}
		</Button>
	) : (
		<Button
			onClick={() => {
				setSidebarOpen(false);
				navigate(item.path as string);
			}}
			className='lg:flex m-0'
			key={item.path}
			startIcon={item.icon}
			variant={item.path && pathname?.includes(item.path) ? 'contained' : undefined}
		>
			{item.title}
		</Button>
	),
);

const LinkAnchor = React.memo(({ item, pathname, navigate, linkClassName }: any) =>
	item.path?.startsWith('http') ? (
		<a
			key={item.path}
			rel='noreferrer'
			className={`text-slate-400 ${linkClassName}`}
			href={item.path}
			target='_blank'
		>
			{item.title}
		</a>
	) : (
		<button
			onClick={() => navigate(item.path as string)}
			className={`${linkClassName} ${
				item.path && pathname?.includes(item.path) ? 'text-white' : 'text-slate-400'
			}`}
			key={item.path}
		>
			{item.title}
		</button>
	),
);

export default function MiniDrawer() {
	const { currentUser } = useGetCurrentUser();
	const navigate = useNavigate();
	const [_currentTab, setCurrentTab] = useMapDrawer();
	const [popoverId, setPopoverId] = useState('');
	const { pathname } = useLocation();
	const { t } = useTranslation();
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [backDrop, setBackDrop] = useState(false);
	const { sections } = useSidebar();
	// const [map] = useMapBox();
	const menuOptions = useMemo(
		() => sections.find((element) => element.title === 'General'),
		[sections],
	);
	const linkClassName = useMemo(
		() =>
			'hover:text-white transition-all cursor-pointer font-semibold text-base decoration-transparent hidden lg:flex p-4 bg-transparent border-0',
		[],
	);

	const handleLogoClick = useCallback(() => {
		if (pathname === `/${PATH.APP}/${PATH.MAP}`) {
			setCurrentTab(1);
			// map.flyTo({
			// 	center: [5, 60],
			// 	zoom: 2,
			// });
		} else {
			navigate(
				`/${PATH.APP}/${
					currentUser?.operation === OperationEnum.SHIP_OPERATOR ? PATH.CARRIERS.INDEX : PATH.MAP
				}`,
			);
		}
	}, [pathname, setCurrentTab, navigate, currentUser]);

	return (
		<>
			<div
				className={`${
					sidebarOpen ? '' : 'pointer-events-none'
				} transition-transform flex fixed top-[0] left-[0] h-[100vh] z-[9999]`}
				style={{
					transform: `translateX(-${sidebarOpen ? 0 : 280}px)`,
				}}
				onTransitionEnd={() => {
					if (!sidebarOpen) {
						setBackDrop(false);
					}
				}}
			>
				<div className='relative bg-black w-[280px] flex flex-col h-[100vh] gap-12 px-4 py-12'>
					<KeelspotLogo onClick={handleLogoClick} className='mx-auto cursor-pointer' />
					<div className='flex flex-col'>
						{menuOptions?.items.map((item) =>
							item.children ? (
								<Fragment key={item.title}>
									{item.children.map((childItem) => (
										<LinkButton
											key={childItem.path}
											item={childItem}
											pathname={pathname}
											navigate={navigate}
											setSidebarOpen={setSidebarOpen}
										/>
									))}
								</Fragment>
							) : (
								<LinkButton
									key={item.path}
									item={item}
									pathname={pathname}
									navigate={navigate}
									setSidebarOpen={setSidebarOpen}
								/>
							),
						)}
						{!isInDemoMode &&
							(currentUser?.isAdmin ||
								currentUser?.companyPrimaryOperationSlug === OperationEnum.SHIP_OPERATOR) && (
								<SwitchOperations setSidebarOpen={setSidebarOpen} />
							)}
					</div>
				</div>
			</div>
			{backDrop && (
				<Backdrop
					onClick={() => setSidebarOpen(false)}
					className={`fixed left-0 right-0 top-0 h-[100vh] z-[998] transition-opacity bg-black${
						sidebarOpen ? '' : ' closed'
					}`}
				/>
			)}
			<div className='fixed top-[0] z-[997] flex max-w-full w-full h-[72px] bg-keelspot-headerBackground shadow-lg justify-between p-2 pr-4 md:px-4 lg:px-6 items-center'>
				<div className='flex items-center grow'>
					<Button
						startIcon={<MdMenu />}
						className='min-w-0 lg:hidden'
						onClick={() => {
							setSidebarOpen(!sidebarOpen);
							setBackDrop(true);
						}}
					>
						<span className='hidden md:flex text-white'>{t('common.menu')}</span>
					</Button>
					<div className='flex justify-center cursor-pointer w-[100px] md:w-[140px] md:mx-auto lg:mx-0 lg:pr-6'>
						<KeelspotLogo onClick={handleLogoClick} className='w-full' color='#fff' />
					</div>
					{menuOptions?.items.map((item) =>
						item.children ? (
							<Fragment key={item.path}>
								<Popover
									anchorEl={document.getElementById(item.title)}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									keepMounted
									onClose={() => setPopoverId('')}
									open={popoverId === item.title}
									PaperProps={{ sx: { width: 'fit-content' } }}
									transitionDuration={0}
								>
									{item.children.map((childItem) => (
										<MenuItem
											className='flex gap-2 text-keelspot-primary-main'
											key={childItem.title}
											onClick={() => {
												setPopoverId('');
												navigate(childItem.path as string);
											}}
										>
											{childItem.icon}
											<span className='font-semibold'>{childItem.title}</span>
										</MenuItem>
									))}
								</Popover>
								<Button
									className={`${linkClassName} text-white`}
									id={item.title}
									onClick={() => setPopoverId(item.title)}
								>
									{item.title}
								</Button>
							</Fragment>
						) : (
							<LinkAnchor
								key={item.path}
								item={item}
								pathname={pathname}
								navigate={navigate}
								linkClassName={linkClassName}
							/>
						),
					)}
				</div>
				<Navbar />
			</div>
		</>
	);
}
