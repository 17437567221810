/* eslint-disable react/prop-types */
// @ts-nocheck

import { useMemo, useState, useCallback, useEffect } from 'react';
import { MapBox } from '@/components/map/MapBox';
import {
	FullscreenControl,
	NavigationControl,
	ScaleControl,
	Source,
	Layer,
	Marker,
	Popup,
} from 'react-map-gl';
import { useGetPositionsAndHistories } from '@/apiV2/vessels';
import { useMapStyle } from '@/context/MapStyleContext';
import { MapContainer } from '@/components/map/Styles';
import SatelliteControl from '@/components/map/SatelliteControl';
import { useMapBox } from '@/context/MapBoxContext';
import Card from '@/components/Card';
import Typography from '@mui/material/Typography/Typography';
import { Checkbox, FormControlLabel, Slider } from '@mui/material';
import { useGetPlaces } from '@/apiV2/places';
import Button from '@/components/Button';
import { MdLocationPin } from 'react-icons/md';
import TabButton, { TabButtonsWrapper } from '@/components/TabButton';
import TabPanel from '../Deliveries/viewOngoingFreight/TabPanel';

const HeatmapMap = () => {
	const { isMapStyleSatellite, handleMapStyle } = useMapStyle();
	const [mapRef] = useMapBox();
	const {
		places,
		loading: placesLoading,
		fetching,
		fetchAllPlaces,
		fetchNewPlaces,
		hidePlace,
		claimPlace,
	} = useGetPlaces();
	const [netTonnageRange, setNetTonnageRange] = useState([1000, 70000]);
	const [cargoOnly, setCargoOnly] = useState(true);
	const [hoveredPlace, setHoveredPlace] = useState(null);
	const [mapBounds, setMapBounds] = useState(null);
	const {
		response: vessels,
		loading,
		error,
	} = useGetPositionsAndHistories(netTonnageRange, cargoOnly);

	// Fetch all places once when component mounts
	useEffect(() => {
		fetchAllPlaces();
	}, [fetchAllPlaces]);

	const handleMapMove = useCallback(() => {
		if (mapRef) {
			const bounds = mapRef.getBounds();
			setMapBounds(bounds);
		}
	}, [mapRef]);

	useEffect(() => {
		if (mapRef) {
			mapRef.on('moveend', handleMapMove);
			handleMapMove(); // Initial call to set bounds
			return () => {
				mapRef.off('moveend', handleMapMove);
			};
		}
	}, [mapRef, handleMapMove]);

	const visiblePlaces = useMemo(() => {
		if (!places || !mapBounds) return [];

		return places.filter(
			(place) =>
				place.longitude >= mapBounds.getWest() &&
				place.longitude <= mapBounds.getEast() &&
				place.latitude >= mapBounds.getSouth() &&
				place.latitude <= mapBounds.getNorth(),
		);
	}, [places, mapBounds]);

	const heatmapData = useMemo(() => {
		if (!vessels) return { type: 'FeatureCollection', features: [] };

		const features = vessels.flatMap((vessel) =>
			vessel.history
				.filter((point) => point.speed === 0)
				.map((point) => ({
					type: 'Feature',
					properties: {
						point_count: 1,
						speed: point.speed,
						vesselName: vessel.name,
					},
					geometry: {
						type: 'Point',
						coordinates: [point.longitude, point.latitude],
					},
				})),
		);

		return {
			type: 'FeatureCollection',
			features,
		};
	}, [vessels]);

	const heatmapLayer = {
		id: 'heatmap',
		type: 'heatmap',
		paint: {
			'heatmap-weight': ['interpolate', ['linear'], ['get', 'point_count'], 0, 0, 10, 0.15],
			'heatmap-intensity': ['interpolate', ['linear'], ['zoom'], 0, 1, 9, 3],
			'heatmap-color': [
				'interpolate',
				['exponential', 6.5],
				['heatmap-density'],
				0,
				'rgba(0, 0, 255, 0)',
				0.1,
				'royalblue',
				0.3,
				'cyan',
				0.5,
				'lime',
				0.7,
				'yellow',
				0.9,
				'red',
			],
			'heatmap-radius': ['interpolate', ['linear'], ['zoom'], 0, 2, 9, 15],
			'heatmap-opacity': 0.8,
		},
	};

	const handleTonnageChange = (event, newValue) => {
		setNetTonnageRange(newValue);
	};

	const handleCargoOnlyChange = (event) => {
		setCargoOnly(event.target.checked);
	};

	const handleFetchNewPlaces = () => {
		if (mapRef) {
			const mapBounds = mapRef.getBounds();
			fetchNewPlaces(mapBounds);
		}
	};

	return (
		<div className='flex flex-col flex-col-reverse lg:flex-row h-full'>
			<div className={`h-full lg:block grow lg:static lg:top-[720px]`}>
				<div className='w-full lg:w-auto h-full lg:fixed lg:bottom-[0] lg:left-[0] lg:right-[530px] '>
					<MapContainer className='h-full w-full'>
						<MapBox isMapStyleSatellite={isMapStyleSatellite}>
							<Source type='geojson' data={heatmapData}>
								<Layer {...heatmapLayer} />
							</Source>

							{places &&
								places?.map((place) => (
									<Marker key={place._id} longitude={place.longitude} latitude={place.latitude}>
										<MdLocationPin
											size={25}
											color='blue'
											onMouseEnter={() => setHoveredPlace(place)}
											onMouseLeave={() => setHoveredPlace(null)}
										/>
									</Marker>
								))}

							{hoveredPlace && (
								<Popup
									longitude={hoveredPlace.longitude}
									latitude={hoveredPlace.latitude}
									closeButton={false}
									anchor='top'
								>
									<PlaceInfo place={hoveredPlace} />
								</Popup>
							)}

							<SatelliteControl onClick={handleMapStyle} />
							<ScaleControl position='bottom-right' />
							<NavigationControl showCompass={false} position='bottom-right' />
							<FullscreenControl position='bottom-right' />
						</MapBox>
						<div className='fixed p-4 pt-24'>
							<Card className='bg-white p-4'>
								<div className='flex flex-col space-y-4 text-keelspot-text-secondary text-sm'>
									<Typography className='text-keelspot-text-secondary' gutterBottom>
										Tonnage
									</Typography>
									<Slider
										value={netTonnageRange}
										onChange={handleTonnageChange}
										valueLabelDisplay='auto'
										min={0}
										max={90000}
										step={2000}
									/>
									<FormControlLabel
										control={<Checkbox checked={cargoOnly} onChange={handleCargoOnlyChange} />}
										label='Cargo only'
									/>
									{loading && (
										<span className='text-xs text-keelspot-text-secondary'>
											<i>Loading data..</i>
										</span>
									)}
								</div>
							</Card>
							<Button onClick={handleFetchNewPlaces} variant='contained' color='success' className='mt-4'>
								{fetching ? 'Fetching..' : 'Fetch Places'}
							</Button>
						</div>
					</MapContainer>
				</div>
			</div>
			<div className='lg:static lg:mt-0 bg-white w-[100%] border-0 lg:max-w-[530px] lg:grow lg:border-l borderLeftSolid lg:border-keelspot-border-light'>
				<div className='pt-[60px] lg:pt-0'>
					<div className='pt-1 lg:pt-8 left-0 right-0 top-[72px] fixed lg:sticky lg:top-[72px] bg-white z-10 border-b borderBottomSolid border-keelspot-border-light'>
						<TabButtonsWrapper className='gap-0'>
							<TabButton role='tab' className={'selected'} selected={true}>
								Place Results ({visiblePlaces.length})
							</TabButton>
						</TabButtonsWrapper>
					</div>
					{places && (
						<TabPanel value={1} index={1} className='overflow-hidden'>
							{visiblePlaces.map((place) => (
								<PlaceInfo
									key={place._id}
									place={place}
									isHovered={hoveredPlace && hoveredPlace._id === place._id}
									buttons={true}
									hidePlace={hidePlace}
									claimPlace={claimPlace}
								/>
							))}
						</TabPanel>
					)}
				</div>
			</div>
		</div>
	);
};

const PlaceInfo = ({ place, isHovered, buttons, hidePlace, claimPlace }) => {
	const [hidden, setHidden] = useState(false);
	const [claimed, setClaimed] = useState(place.claimed);

	if (hidden) {
		return null; // If hidden is true, don't render anything
	}

	return (
		<div className={`rounded-md p-1 ${isHovered ? 'bg-gray-200' : ''}`}>
			<Typography variant='body2'>
				<b>{place.name}</b>
			</Typography>
			<Typography variant='body2'>🏢 {place.address}</Typography>
			{place.phone && (
				<Typography variant='body2' className='text-keelspot-text-secondary'>
					☎️ {place.phone}
				</Typography>
			)}
			{place.website && (
				<Typography variant='body2'>
					🌐{' '}
					<a href={place.website} target='_blank' rel='noopener noreferrer'>
						{'Homepage'}
					</a>
				</Typography>
			)}
			{place.type && <Typography variant='body2'>{place.type}</Typography>}
			{buttons && (
				<div className='flex flex-row pt-1 gap-1'>
					<Button
						size='sm'
						variant='outlined'
						color='error'
						onClick={() => {
							setHidden(true);
							hidePlace(place._id);
						}}
					>
						Hide
					</Button>
					<a
						href={`https://app.dnbhoovers.com/search/results/company?q=${place.name}`}
						className='no-underline'
						target='_blank'
						rel='noreferrer'
					>
						<Button size='sm' variant='outlined' color='primary'>
							D & B
						</Button>
					</a>
					{!claimed ? (
						<Button
							size='sm'
							variant='outlined'
							color='success'
							onClick={() => {
								setClaimed(true);
								claimPlace(place._id);
							}}
						>
							Claim
						</Button>
					) : (
						<span className='text-xs pt-2 items-center text-keelspot-text-secondary'>Claimed!</span>
					)}
				</div>
			)}
		</div>
	);
};

export default HeatmapMap;
