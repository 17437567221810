import { ButtonHTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type TabButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	selected: boolean;
	children?: ReactNode;
};

const cssClass = {
	baseClass:
		'text-keelspot-text-secondary hover:text-keelspot-text-primary hover:bg-keelspot-pale-blueLight border-transparent shadow-xs',
	selectedClass: 'text-keelspot-primary-main border-keelspot-primary-dark',
};

export const TabButtonsWrapper = ({
	children,
	className,
}: {
	children: ReactNode;
	className?: string;
}) => (
	<div className={twMerge('flex item-center gap-1 overflow-x-auto whitespace-nowrap', className)}>
		{children}
	</div>
);

const TabButton = ({ selected, className, children, ...rest }: TabButtonProps) => (
	<button
		className={twMerge(
			'transition-all rounded-t-lg text-sm leading-none font-semibold cursor-pointer bg-white px-3 py-4 border-solid border-0 border-b-2',
			cssClass[selected ? 'selectedClass' : 'baseClass'],
			className,
		)}
		{...rest}
	>
		{children}
	</button>
);

export default TabButton;
