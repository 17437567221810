import { gql } from 'graphql-request';

export const CREATE_SHIPMENT = gql`
	mutation createShipment($input: CreateShipmentInput!) {
		createShipment(input: $input) {
			message
			data {
				_id
			}
		}
	}
`;

export const DELETE_SHIPMENT = gql`
	mutation ($input: String!) {
		deleteShipment(_id: $input) {
			message
		}
	}
`;
