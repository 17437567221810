import { useUpdateAuth } from '@/api/auth';
import { FREIGHT_FORWARDER, SHIP_OPERATOR } from '@/config/path';
import {
	RadioGroup,
	Radio,
	FormControl,
	FormHelperText,
	Button,
	CircularProgress,
} from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SwitchRadioButton, radioInputContent } from './styles';
import { useTranslation } from 'react-i18next';
import { RequestFreightIcon } from '@/assets/icons/requestFreightIcon';
import { OfferVesselIcon } from '@/assets/icons/offerVesselIcon';

interface SidebarProps {
	operation?: string;
	onClose?: () => void;
}

export const SwitchOperationsMenu: FC<SidebarProps> = (props) => {
	const { t } = useTranslation();
	const { operation, onClose } = props;
	const navigate = useNavigate();

	const [value, setValue] = useState(operation ?? '');
	const [error, setError] = useState(false);
	const [helperText, setHelperText] = useState('');

	const onSuccess = () => {
		navigate('/app/map');
	};
	const { mutate, isLoading } = useUpdateAuth(onSuccess);

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue(event.target.value);
		setHelperText('');
		setError(false);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (!value) {
			setHelperText('Please select an option.');
			setError(true);
			return;
		}
		mutate({
			input: {
				operations: [value],
			},
		});
	};

	return (
		<form onSubmit={handleSubmit} className='w-full'>
			<FormControl className='pb-3 w-full' error={error} variant='standard'>
				<div>
					<RadioGroup
						aria-labelledby='demo-radio-buttons-group-label'
						defaultValue={operation}
						name='switch-operations-radio-buttons'
						value={value}
						onChange={handleRadioChange}
					>
						<SwitchRadioButton
							className={`flex items-center gap-2 p-4 m-0 mb-4 `}
							value={FREIGHT_FORWARDER}
							control={<Radio />}
							$active={value === FREIGHT_FORWARDER}
							label={radioInputContent({
								title: `${t('operationsDialog.goodsOwnerHeading')}`,
								text: t('operationsDialog.goodsOwnerDescription'),
								icon: (
									<RequestFreightIcon
										className={'hidden md:shrink-0'}
										active={value === FREIGHT_FORWARDER}
									/>
								),
							})}
						/>
						<SwitchRadioButton
							className={`flex items-center gap-2 p-4 m-0 mb-4 `}
							value={SHIP_OPERATOR}
							control={<Radio />}
							$active={value === SHIP_OPERATOR}
							label={radioInputContent({
								title: `${t('operationsDialog.shipOperatorHeading')}`,
								text: t('operationsDialog.shipOperatorDescription'),
								icon: <OfferVesselIcon className={'hidden md:shrink-0'} active={value === SHIP_OPERATOR} />,
							})}
						/>
					</RadioGroup>
					<FormHelperText className='w-100 text-center'>{helperText}</FormHelperText>
					<div className='flex justify-end mt-5'>
						<Button onClick={onClose} className='normal-case'>
							{t('common.cancel')}
						</Button>
						<Button
							type='submit'
							variant='contained'
							startIcon={isLoading ? <CircularProgress color='inherit' size={20} /> : null}
							disabled={value === operation}
						>
							{t('operationsDialog.confirmButton')}
						</Button>
					</div>
				</div>
			</FormControl>
		</form>
	);
};
