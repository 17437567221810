import { /* useRef, */ memo } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import KeelspotLogo from '@/assets/KeelspotLogo';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAuth0 } from '@auth0/auth0-react';

// import { LanguageButton } from './toolbar';

const Wrapper = styled('div')`
	min-height: 100vh;
	height: 100%;
	background: #fcfaf9;
`;

const AuthRoutesHeader = styled('header')`
	position: fixed;
	width: 100%;
	min-height: 6rem;
	background: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 2rem;
	z-index: 1000;
	> div {
		display: flex;
		width: 33.33%;
		flex-grow: 1;
		align-items: center;
		justify-content: center;
		&:first-of-type {
			justify-content: flex-start;
		}
		&:last-of-type {
			justify-content: flex-end;
		}
	}
`;

function AuthRoutesLayout() {
	const { t } = useTranslation();
	const { logout } = useAuth0();
	// const languageRef = useRef<HTMLDivElement>(null);

	const handleLogout = async (): Promise<void> => {
		try {
			logout({ returnTo: window.location.origin });
		} catch (err) {
			console.error(err);
			// toast.error('Unable to logout.');
		}
	};
	return (
		<Wrapper>
			<AuthRoutesHeader className='shadow-md'>
				<div>
					<Button
						aria-label={t('common.backToWebpage')}
						className='min-w-0 -ml-4'
						onClick={() => {
							handleLogout();
						}}
					>
						<ArrowBackIcon /> <span className='hidden sm:visible'>{t('common.backToWebpage')}</span>
					</Button>
				</div>
				<div>
					<KeelspotLogo color={'#0C84DB'} />
				</div>
				<div />
				{/*
				<div ref={languageRef}>
					<LanguageButton
						currentComponent={languageRef?.current}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
					/>
					</div>
					*/}
			</AuthRoutesHeader>
			<main className='p-2 mt-20'>
				<Outlet />
			</main>
		</Wrapper>
	);
}

export default memo(AuthRoutesLayout);
