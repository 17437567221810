import { useEffect } from 'react';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import { APP, MAP } from '@/config/path';
import { useNavigate } from 'react-router-dom';
import { Loader } from './Loader';

// import { CARRIERS } from '@/config/path';
// import { OperationEnum } from '@/enums/OperationEnum';

export function InitialLoader() {
	const { currentUser } = useGetCurrentUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (!currentUser) {
			return;
		}

		// Just do this.
		// if (currentUser.operation === OperationEnum.SHIP_OPERATOR) {
		// 	navigate(`${APP}/${CARRIERS.INDEX}`);
		// } else {
		// 	navigate(`${APP}/${MAP}`);
		// }

		navigate(`${APP}/${MAP}`);
	}, [currentUser, navigate]);

	return <Loader />;
}

export default InitialLoader;
