import { useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import { CONFIG } from '@/config';

interface Place {
	_id: string;
	name: string;
	address: string;
	phone?: string;
	website?: string;
	latitude: number;
	longitude: number;
	claimed: boolean;
}

export const useGetPlaces = () => {
	const { getAccessTokenSilently } = useAuth0();
	const { currentUser } = useGetCurrentUser();
	const [places, setPlaces] = useState<Place[]>([]);
	const [loading, setLoading] = useState(false);
	const [fetching, setFetching] = useState(false);

	const fetchAllPlaces = useCallback(async () => {
		if (!currentUser) return;
		setLoading(true);
		try {
			const accessToken = await getAccessTokenSilently();
			const getUrl = `${CONFIG.API_V2}/all-places`;
			const getResponse = await fetch(getUrl, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			});
			const existingPlaces = await getResponse.json();

			// If we have existing places, use them
			if (existingPlaces.length > 0) {
				setPlaces(existingPlaces);
			}
		} catch (error) {
			console.error('Error fetching places:', error);
		} finally {
			setLoading(false);
		}
	}, [getAccessTokenSilently, currentUser]);

	const fetchPlacesInBox = useCallback(
		async (bounds: mapboxgl.LngLatBounds) => {
			if (!currentUser) return;
			setLoading(true);
			try {
				const accessToken = await getAccessTokenSilently();
				const [minLng, minLat, maxLng, maxLat] = bounds.toArray().flat();

				// First, try to fetch existing places from our database
				const queryString = `minLat=${minLat}&maxLat=${maxLat}&minLng=${minLng}&maxLng=${maxLng}`;
				const getUrl = `${CONFIG.API_V2}/places?${queryString}`;
				const getResponse = await fetch(getUrl, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
					},
				});
				const existingPlaces = await getResponse.json();

				// If we have existing places, use them
				if (existingPlaces.length > 0) {
					setPlaces(existingPlaces);
				}
			} catch (error) {
				console.error('Error fetching places:', error);
			} finally {
				setLoading(false);
			}
		},
		[getAccessTokenSilently, currentUser],
	);

	const fetchNewPlaces = useCallback(
		async (bounds: mapboxgl.LngLatBounds) => {
			if (!currentUser) return;
			setLoading(true);
			setFetching(true);
			try {
				const accessToken = await getAccessTokenSilently();
				const [minLng, minLat, maxLng, maxLat] = bounds.toArray().flat();

				// Fetch new places from Google Maps API
				const postUrl = `${CONFIG.API_V2}/fetch-places`;
				const postResponse = await fetch(postUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify({
						minLat,
						maxLat,
						minLng,
						maxLng,
					}),
				});
				const newPlaces = await postResponse.json();
				await fetchAllPlaces();
				setFetching(false);
			} catch (error) {
				console.error('Error fetching new places:', error);
			} finally {
				setLoading(false);
			}
		},
		[getAccessTokenSilently, currentUser],
	);

	const hidePlace = useCallback(
		async (id: string) => {
			try {
				const accessToken = await getAccessTokenSilently();
				const postUrl = `${CONFIG.API_V2}/hide-place/${id}`;
				const postResponse = await fetch(postUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
					},
				});

				await fetchAllPlaces();
			} catch (error) {
				console.error('Error hiding new places:', error);
			} finally {
				setLoading(false);
			}
		},
		[getAccessTokenSilently, currentUser],
	);

	const claimPlace = useCallback(
		async (id: string) => {
			try {
				const accessToken = await getAccessTokenSilently();
				const postUrl = `${CONFIG.API_V2}/claim-place/${id}`;
				const postResponse = await fetch(postUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
					},
				});

				await fetchAllPlaces();
			} catch (error) {
				console.error('Error claiming new places:', error);
			} finally {
				setLoading(false);
			}
		},
		[getAccessTokenSilently, currentUser],
	);

	return {
		places,
		loading,
		fetching,
		fetchPlacesInBox,
		fetchNewPlaces,
		fetchAllPlaces,
		hidePlace,
		claimPlace,
	};
};
