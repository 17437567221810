import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const ProfileAvatarRoot = styled(Box)({
	alignItems: 'center',
	padding: '16px',
	display: 'flex',
}) as any;

export const ProfileDetails = styled(Box)({
	marginLeft: '16px',
}) as any;
export const ProfileMenuList = styled(Box)({
	margin: '8px 0px',
}) as any;
export const NotificationPop = styled('div')({
	width: '10px',
	height: '10px',
	backgroundColor: 'red',
	borderRadius: '50%',
}) as any;
export const Notification = styled('div')({
	display: 'grid',
	gridTemplateColumns: 'repeat(3, 1fr)',
	gridTemplateRows: '1fr',
	gridColumnGap: '10px',
	gridRowGap: '0px',
}) as any;

export const IconWrapper = styled('div')`
	${(props: any) => ({
		'.MuiBadge-badge': {
			background: props.theme.palette.secondary.light,
			borderRadius: '20px',
			color: '#fff',
		},
	})}
	width: 40px;
	height: 40px;
	border-radius: 50%;
	padding: 4px;
	cursor: pointer;
	color: red;
	svg {
		color: #fff;
	}
	.MuiBadge-dot {
		top: 6px;
		right: 6px;
	}
` as any;
