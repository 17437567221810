import { forwardRef, ButtonHTMLAttributes, ReactNode } from 'react';

type SizeTypes = 'xs' | 'small' | 'medium' | 'large';
type VariantTypes = 'outlined' | 'contained' | 'text' | 'ghost';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	variant?: VariantTypes;
	color?: 'primary' | 'secondary' | 'error' | 'success' | 'info' | 'warning' | 'header' | 'muted';
	startIcon?: ReactNode;
	endIcon?: ReactNode;
	size?: SizeTypes;
	children?: ReactNode;
};

// Need to create these in advance so tailwind adds them to our css
const colorClassMap = {
	muted: {
		contained:
			'border-0 text-keelspot-primary-superLight bg-keelspot-text-secondary hover:bg-keelspot-text-primary focus:bg-keelspot-text-primary',
		outlined:
			'bg-white focus:bg-keelspot-primary-superLight hover:bg-keelspot-primary-superLight border-2 border-solid border-keelspot-text-secondary text-keelspot-text-secondary hover:text-keelspot-text-primary focus:text-keelspot-text-primary',
		ghost:
			'bg-white focus:bg-keelspot-primary-superLight hover:bg-keelspot-primary-superLight border-2 border-solid border-transparent hover:border-keelspot-text-secondary text-keelspot-text-secondary hover:text-keelspot-text-primary focus:text-keelspot-text-primary',
		text:
			'border-0 text-keelspot-text-secondary hover:text-keelspot-text-primary focus:text-keelspot-text-primary',
	},
	primary: {
		contained:
			'border-0 text-white bg-keelspot-primary-main hover:bg-keelspot-primary-dark focus:bg-keelspot-primary-dark',
		outlined:
			'bg-white focus:bg-keelspot-primary-superLight hover:bg-keelspot-primary-superLight border-2 border-solid border-keelspot-primary-main text-keelspot-primary-main hover:text-keelspot-primary-dark focus:text-keelspot-primary-dark',
		ghost:
			'bg-white focus:bg-keelspot-primary-superLight hover:bg-keelspot-primary-superLight border-2 border-solid border-transparent hover:border-keelspot-primary-main text-keelspot-primary-main hover:text-keelspot-primary-dark focus:text-keelspot-primary-dark',
		text:
			'border-0 text-keelspot-primary-main hover:text-keelspot-primary-dark focus:text-keelspot-primary-dark',
	},
	secondary: {
		contained:
			'border-0 text-white bg-keelspot-secondary-main hover:bg-keelspot-secondary-dark focus:bg-keelspot-secondary-dark',
		outlined:
			'bg-white focus:bg-keelspot-secondary-superLight hover:bg-keelspot-secondary-superLight border-2 border-solid border-keelspot-secondary-main text-keelspot-secondary-main hover:text-keelspot-secondary-dark focus:text-keelspot-secondary-dark',
		ghost:
			'bg-white focus:bg-keelspot-secondary-superLight hover:bg-keelspot-secondary-superLight border-2 border-solid border-transparent hover:border-keelspot-secondary-main text-keelspot-secondary-main hover:text-keelspot-secondary-dark focus:text-keelspot-secondary-dark',
		text:
			'border-0 text-keelspot-secondary-main hover:text-keelspot-secondary-dark focus:text-keelspot-secondary-dark',
	},
	error: {
		contained:
			'border-0 text-white bg-keelspot-error-main hover:bg-keelspot-error-dark focus:bg-keelspot-error-dark',
		outlined:
			'bg-white focus:bg-keelspot-error-superLight hover:bg-keelspot-error-superLight border-2 border-solid border-keelspot-error-main text-keelspot-error-main hover:text-keelspot-error-dark focus:text-keelspot-error-dark',
		ghost:
			'bg-white focus:bg-keelspot-error-superLight hover:bg-keelspot-error-superLight border-2 border-transparent hover:border-keelspot-error-main text-keelspot-error-main hover:text-keelspot-error-dark focus:text-keelspot-error-dark',
		text:
			'border-0 text-keelspot-error-main hover:text-keelspot-error-dark focus:text-keelspot-error-dark',
	},
	success: {
		contained:
			'border-0 text-white bg-keelspot-success-main hover:bg-keelspot-success-dark focus:bg-keelspot-success-dark',
		outlined:
			'bg-white focus:bg-keelspot-success-superLight hover:bg-keelspot-success-superLight border-2 border-solid border-keelspot-success-main text-keelspot-success-main hover:text-keelspot-success-dark focus:text-keelspot-success-dark',
		ghost:
			'bg-white focus:bg-keelspot-success-superLight hover:bg-keelspot-success-superLight border-2 border-solid border-transparent hover:border-keelspot-success-main text-keelspot-success-main hover:text-keelspot-success-dark focus:text-keelspot-success-dark',
		text:
			'border-0 text-keelspot-success-main hover:text-keelspot-success-dark focus:text-keelspot-success-dark',
	},
	info: {
		contained:
			'border-0 text-white bg-keelspot-info-main hover:bg-keelspot-info-dark focus:bg-keelspot-info-dark',
		outlined:
			'bg-white focus:bg-keelspot-info-superLight hover:bg-keelspot-info-superLight border-2 border-solid border-keelspot-info-main text-keelspot-info-main hover:text-keelspot-info-dark focus:text-keelspot-info-dark',
		ghost:
			'bg-white focus:bg-keelspot-info-superLight hover:bg-keelspot-info-superLight border-2 border-solid border-transparent hover:border-keelspot-info-main text-keelspot-info-main hover:text-keelspot-info-dark focus:text-keelspot-info-dark',
		text:
			'border-0 text-keelspot-info-main hover:text-keelspot-info-dark focus:text-keelspot-info-dark',
	},
	warning: {
		contained:
			'border-0 text-white bg-keelspot-warning-main hover:bg-keelspot-warning-dark focus:bg-keelspot-warning-dark',
		outlined:
			'bg-white focus:bg-keelspot-warning-superLight hover:bg-keelspot-warning-superLight border-2 border-solid border-keelspot-warning-main text-keelspot-warning-main hover:text-keelspot-warning-dark focus:text-keelspot-warning-dark',
		ghost:
			'bg-white focus:bg-keelspot-warning-superLight hover:bg-keelspot-warning-superLight border-2 border-solid border-transparent hover:border-keelspot-warning-main text-keelspot-warning-main hover:text-keelspot-warning-dark focus:text-keelspot-warning-dark',
		text:
			'border-0 text-keelspot-warning-main hover:text-keelspot-warning-dark focus:text-keelspot-warning-dark',
	},
	header: {
		contained:
			'border-0 text-white bg-keelspot-headerBackground hover:bg-keelspot-headerBackground-dark focus:bg-keelspot-headerBackground-dark',
		outlined:
			'text-white bg-keelspot-headerBackground hover:bg-keelspot-headerBackground-dark focus:bg-keelspot-headerBackground-dark',
		ghost:
			'text-white bg-keelspot-headerBackground hover:bg-keelspot-headerBackground-dark focus:bg-keelspot-headerBackground-dark',
		text:
			'border-0 text-white bg-keelspot-headerBackground hover:bg-keelspot-headerBackground-dark focus:bg-keelspot-headerBackground-dark',
	},
};

const getGapAndFontSize = (size: SizeTypes) => {
	if (size === 'large') {
		return 'gap-3 text-base';
	}
	if (size === 'medium') {
		return 'gap-2 text-sm';
	}
	return 'gap-1 text-xs';
};

const getGapAndFontSizeForText = (size: SizeTypes) => {
	if (size === 'large') {
		return 'gap-2 text-md';
	}
	if (size === 'medium') {
		return 'gap-1 text-base text-sm md:text-md';
	}
	return 'gap-1 text-sm';
};

const getSize = (size: SizeTypes) => {
	if (size === 'large') {
		return 'px-6 py-5';
	}
	if (size === 'medium') {
		return 'px-2 py-2 md:px-4 md:py-3 text-xs md:text-sm';
	}
	if (size === 'xs') {
		return 'p-1 px-2';
	}
	return 'px-2 py-2';
};

const getSizeForText = (size: SizeTypes) => {
	if (size === 'large') {
		return 'px-3 py-3';
	}
	if (size === 'medium') {
		return 'px-2 py-2 ';
	}
	return 'px-1 py-1';
};

const baseClassMap =
	'leading-none font-semibold transition-all focus:ring-keelspot-blueLight active:translate-y-[1px] flex cursor-pointer disabled:cursor-default rounded-md justify-center items-center hover:shadow';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			startIcon,
			endIcon,
			color = 'primary',
			variant = 'contained',
			size = 'medium',
			children,
			className,
			disabled,
			...rest
		},
		ref,
	) => {
		const iconClass = disabled
			? 'pointer-events-none text-gray-400'
			: variant === 'contained'
			? 'pointer-events-none text-white'
			: `pointer-events-none text-keelspot-${color}-main`;

		return (
			<button
				ref={ref}
				className={(variant !== 'text'
					? [
							getGapAndFontSize(size),
							baseClassMap,
							disabled
								? 'border-0 text-gray-500 cursor-not-allowed'
								: (colorClassMap[color] && colorClassMap[color][variant]) || '',
							getSize(size),
							className,
					  ]
					: [
							baseClassMap,
							getGapAndFontSizeForText(size),
							getSizeForText(size),
							'bg-transparent border-solid border-transparent border-2 hover:border-gray-200 disabled:border-transparent focus:border-gray-200',
					  ]
				).join(' ')}
				disabled={disabled}
				{...rest}
			>
				{startIcon && <span className={iconClass}>{startIcon}</span>}
				{children && <span className='pointer-events-none'>{children || ''}</span>}
				{endIcon && <span className={iconClass}>{endIcon}</span>}
			</button>
		);
	},
);

Button.displayName = 'Button';
export default Button;
