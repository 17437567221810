import { useEffect, useRef, memo, useState } from 'react';
import { useTalkSessionContext } from '@/context/TalkSessionContext';
import { useConversation } from '@/hooks/useTalk';
import { Inbox, SelectConversationEvent } from 'talkjs/types/talk.types';
import Talk from 'talkjs';
import { generatePath, useNavigate } from 'react-router-dom';
import { APP, CHAT } from '@/config/path';
import { styled } from '@mui/material';

const Wrapper = styled('div')`
	width: 90vw;
	max-width: 468px;
	overflow-y: scroll;
	max-height: 300px;
	height: 90vh;
	&::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`;

const ChatInbox = ({ prop: onClose }: { prop: (() => void) | undefined }) => {
	const chatboxEl = useRef<any>();
	const navigate = useNavigate();
	const talkSession = useTalkSessionContext();
	const { createUserPopup } = useConversation(talkSession);
	const [_inbox, setInbox] = useState<Inbox>();

	useEffect(() => {
		if (!talkSession) return;
		SetUnreadMenssages(talkSession);
		setInbox((inbox) => {
			if (inbox) return inbox;
			const newInbox = talkSession.createInbox();
			newInbox.mount(chatboxEl.current);
			newInbox.onSelectConversation(handler);
			return newInbox;
		});
		function handler(event: SelectConversationEvent) {
			event.preventDefault();
			if (!talkSession || !onClose) return;
			// createUserPopup(talkSession, event.conversation.id);
			navigate(
				generatePath(`/${APP}/${CHAT.INDEX}/${CHAT.CONVERSATION}`, {
					conversationID: event.conversation.id,
				}),
			);
			onClose();
		}
	}, [createUserPopup, navigate, onClose, talkSession]);
	function SetUnreadMenssages(session: Talk.Session) {
		session.unreads.onChange(function (unreadConversations) {
			const amountOfUnreads = unreadConversations.length;
			const notifier = document.getElementById('notifier-badge');
			if (notifier) {
				notifier.innerText = amountOfUnreads.toString();
			}
		});
	}
	return (
		<Wrapper className='h-[430px]'>
			<div className='h-[420px]' ref={chatboxEl} />
		</Wrapper>
	);
};

export default memo(ChatInbox);
