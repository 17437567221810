
			// Conditionally load HubSpot script if on demo site
			if (window.location.origin === 'https://demo.keelspot.com') {
				const hsScript = document.createElement('script');
				hsScript.type = 'text/javascript';
				hsScript.id = 'hs-script-loader';
				hsScript.async = true;
				hsScript.defer = true;
				hsScript.src = '//js-eu1.hs-scripts.com/26746126.js';
				document.head.appendChild(hsScript);
			}
		