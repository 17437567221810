import { ReactNode } from 'react';
import { Button, DialogTitle, DialogActions, DialogContent, CircularProgress } from '@mui/material';
import { MdOutlineClose } from 'react-icons/md';

import * as S from './styled';
import { useTranslation } from 'react-i18next';
interface ModalProps {
	children: ReactNode;
	onClose?: () => void;
	onClick?: () => void;
	isOpen: boolean;
	title?: string;
	formId?: string;
	confirmText?: string | null;
	cancelText?: string | null;
	loading?: boolean;
	dialogAction?: boolean | React.ReactNode;
	disableCancelButton?: boolean;
	dialogWidth?: string;
}

const Modal = ({
	isOpen,
	onClose,
	children,
	title,
	formId,
	confirmText = null,
	cancelText = null,
	onClick,
	loading,
	dialogAction = true,
	disableCancelButton = false,
	dialogWidth,
}: ModalProps) => {
	const { t } = useTranslation();
	const confirmTextValue = confirmText || t('common.confirm');
	const cancelTextValue = cancelText || t('common.cancel');
	return (
		<S.StyledDialog open={isOpen} onClose={onClose} dialogWidth={dialogWidth}>
			{title && (
				<DialogTitle className='flex items-center justify-between mb-3'>
					<div className='font-semibold text-lg'>{title}</div>
					{onClose ? (
						<S.ModalCloseButton aria-label='close' onClick={onClose}>
							<MdOutlineClose />
						</S.ModalCloseButton>
					) : null}
				</DialogTitle>
			)}
			<DialogContent>{children}</DialogContent>
			{dialogAction === true && (
				<DialogActions>
					{!disableCancelButton && (
						<Button onClick={onClose} className='normal-case'>
							{cancelTextValue}
						</Button>
					)}
					<Button
						variant='contained'
						form={formId}
						color='primary'
						type={formId ? 'submit' : 'button'}
						disabled={loading}
						onClick={onClick}
						startIcon={loading ? <CircularProgress color='inherit' size={20} /> : null}
						className='normal-case'
					>
						{confirmTextValue}
					</Button>
				</DialogActions>
			)}
			{dialogAction && dialogAction !== true && dialogAction}
		</S.StyledDialog>
	);
};
export default Modal;
