// @ts-nocheck

import { useEffect, useState, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import { CONFIG } from '@/config';

export const useGetPositionsAndHistories = (netTonnageRange, cargoOnly) => {
	const { getAccessTokenSilently } = useAuth0();
	const { currentUser } = useGetCurrentUser();
	const [response, setResponse] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);

	const fetchAllPositionsAndHistories = useCallback(async () => {
		if (!currentUser) return; // Ensure we have a current user before fetching

		setLoading(true);
		const accessToken = await getAccessTokenSilently();
		const queryString = new URLSearchParams({
			netTonnageMin: netTonnageRange[0].toString(),
			netTonnageMax: netTonnageRange[1].toString(),
			cargoOnly: cargoOnly ? 'true' : 'false',
		}).toString();

		const url = `${CONFIG.API_V2}/all-vessels-and-routes?${queryString}`;
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
		}).then((res) => res.json());

		setResponse(response.data);
		setLoading(false);
	}, [getAccessTokenSilently, netTonnageRange, cargoOnly, currentUser]);

	useEffect(() => {
		fetchAllPositionsAndHistories();
	}, [fetchAllPositionsAndHistories]);

	return { response, loading };
};
