import type { CircleLayer, LayerProps, LineLayer, SymbolLayer } from 'react-map-gl';

export const VESSEL_MARKER_IMG_ID = 'vessel-marker';
export const VESSEL_MARKER_IMG_MATCH_MAKING_ID = 'vessel-marker-match-making';
export const CAPACITY_MARKER_IMG_ID = 'capacity-marker';
export const VESSEL_MARKER_IMG_OWNER_ID = 'vessel-owner-marker';
export const ENDPOINT_LOADING_MARKER_IMG_ID = 'endpoint-loading-marker';
export const ENDPOINT_UNLOADING_MARKER_IMG_ID = 'endpoint-unloading-marker';
export const VESSEL_SOURCE_ID = 'vessels';
export const VESSEL_SOURCE_MATCH_MAKING_ID = 'vessels-match-making';
export const VESSEL_LAYER_ID = 'vessels-layer';
export const VESSEL_LAYER_MATCH_MAKING_ID = 'vessels-layer-match-making';
export const ROUTE_SOURCE_ID = 'routes';
export const ROUTE_LAYER_ID = 'routes-layer';
export const ROUTE_SELECTED_LAYER_ID = 'routes-selected-layer';
export const ROUTE_HOVERED_LAYER_ID = 'routes-hovered-layer';
export const ENDPOINT_LOADING_SOURCE_ID = 'endpoints-loading';
export const ENDPOINT_UNLOADING_SOURCE_ID = 'endpoints-unloading';
export const ENDPOINT_LAYER_ID = 'endpoints-layer';
export const ENDPOINT_LOADING_LAYER_ID = 'endpoints-loading-layer';
export const ENDPOINT_UNLOADING_LAYER_ID = 'endpoints-unloading-layer';
export const ENDPOINT_SELECTED_LAYER_ID = 'endpoints-selected-layer';
export const ENDPOINT_HOVERED_LAYER_ID = 'endpoints-hovered-layer';
export const VESSEL_SELECTED_LAYER_ID = 'vessel-selected-layer';
export const VESSEL_HOVERED_LAYER_ID = 'vessel-hovered-layer';
export const CLUSTER_SHADOW_LAYER_ID = 'clustered-point-circle-shadow';
export const VESSEL_ROUTE_LINE_SOURCE_ID = 'vessel-route-line';
export const VESSEL_ROUTE_MARKER_SOURCE_ID = 'vessel-route-marker';
export const VESSEL_ROUTE_LINE_LAYER_ID = 'vessel-route-line-layer';
export const VESSEL_ROUTE_MARKER_LAYER_ID = 'vessel-route-marker-layer';

export const getLayers = (freightHoverId?: string, clusterHoverId?: string) => {
	const clusterLayer: CircleLayer = {
		id: 'clusters',
		type: 'circle',
		source: 'bundles',
		filter: ['has', 'point_count'],
		paint: {
			'circle-color': [
				'case',
				['==', ['get', 'cluster_id'], clusterHoverId ?? null],
				'#fff',
				['step', ['get', 'point_count'], '#003380', 100, '#003380', 750, '#003380'],
			],
			'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
			'circle-stroke-width': ['case', ['==', ['get', 'cluster_id'], freightHoverId ?? null], 2, 2],
			'circle-stroke-color': '#003380',
		},
	};

	const clusteredShadowLayer: CircleLayer = {
		id: CLUSTER_SHADOW_LAYER_ID,
		type: 'circle',
		source: 'bundles',
		filter: ['has', 'point_count'],
		paint: {
			'circle-translate': [0, 10],
			'circle-radius': ['step', ['get', 'point_count'], 30, 100, 50, 750, 60],
			'circle-stroke-width': 0,
			'circle-color': 'rgba(0, 0, 0, 0.8)',
			'circle-blur': 1.2,
		},
	};

	const clusterCountLayer: LayerProps = {
		id: 'cluster-count',
		type: 'symbol',
		source: 'bundles',
		filter: ['has', 'point_count'],
		layout: {
			'text-field': '{point_count_abbreviated}',
			'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
			'text-size': 18,
		},
		paint: {
			'text-color': ['case', ['==', ['get', 'cluster_id'], clusterHoverId ?? null], '#003380', '#fff'],
		},
	};

	const unclusteredPointLayer: CircleLayer = {
		id: 'unclustered-point-circle',
		type: 'circle',
		source: 'bundles',
		filter: ['!', ['has', 'point_count']],
		paint: {
			'circle-radius': 12,
			'circle-color': ['case', ['==', ['get', 'id'], freightHoverId ?? null], '#fff', '#003380'],
			'circle-stroke-width': ['case', ['==', ['get', 'id'], freightHoverId ?? null], 2, 2],
			'circle-stroke-color': '#003380',
		},
	};

	const unclusteredShadowLayer: CircleLayer = {
		id: 'unclustered-point-circle-shadow',
		type: 'circle',
		source: 'bundles',
		filter: ['!', ['has', 'point_count']],
		paint: {
			'circle-translate': [0, 10],
			'circle-radius': 20,
			'circle-stroke-width': 0,
			'circle-color': 'rgba(0, 0, 0, 0.8)',
			'circle-blur': 1.2,
		},
	};

	const unclusteredCountLayer: SymbolLayer = {
		id: 'unclustered-count',
		type: 'symbol',
		source: 'bundles',
		filter: ['!', ['has', 'point_count']],
		layout: {
			'text-field': '1',
			'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
			'text-size': 14,
		},
		paint: {
			'text-color': ['case', ['==', ['get', 'id'], freightHoverId ?? null], '#003380', '#fff'],
		},
	};

	const routeLineLayer: LineLayer = {
		id: VESSEL_ROUTE_LINE_LAYER_ID,
		type: 'line',
		source: VESSEL_ROUTE_LINE_SOURCE_ID,
		paint: {
			'line-color': '#FF5F52',
			'line-width': 4,
			'line-opacity': 0.8,
		},
	};

	const routeMarkerLayer: CircleLayer = {
		id: VESSEL_ROUTE_MARKER_LAYER_ID,
		type: 'circle',
		source: VESSEL_ROUTE_MARKER_SOURCE_ID,
		paint: {
			'circle-color': '#FF5F52',
			'circle-radius': 3.5,
		},
	};

	return {
		routeLineLayer,
		routeMarkerLayer,
		clusterLayer,
		clusterCountLayer,
		clusteredShadowLayer,
		unclusteredCountLayer,
		unclusteredPointLayer,
		unclusteredShadowLayer,
	};
};
