import { gql } from 'graphql-request';

export const REGISTER_USER = gql`
	mutation registerUser($input: RegisterUserInput!) {
		registerUser(input: $input) {
			message
			data {
				_id
				firstName
				lastName
			}
		}
	}
`;
export const REGISTER_USER_AND_COMPANY = gql`
	mutation registerUserAndCompany($input: RegisterUserAndCompanyInput!) {
		registerUserAndCompany(input: $input) {
			message
			data {
				_id
				firstName
				lastName
				companyName
			}
		}
	}
`;
export const CREATE_COMPANY = gql`
	mutation createCompany($input: CreateCompanyInput!) {
		createCompany(input: $input) {
			message
			data {
				_id
				name
			}
		}
	}
`;
export const UPDATE_AUTH = gql`
	mutation updateAuth($input: UpdateAuthUserInput!) {
		authMe(input: $input) {
			message
			data {
				_id
			}
		}
	}
`;

export const INVITE_MEMBERS = gql`
	mutation inviteMembers($input: InvitationMembersInput!) {
		inviteMembers(input: $input) {
			message
			data {
				_id
				email
			}
		}
	}
`;
export const DEACTIVATE_MEMBERS = gql`
	mutation deactiveMember($id: String!) {
		deactiveMember(_id: $id) {
			message
			data {
				_id
				email
				firstName
				lastName
				profilePhoto {
					_id
					path
					mimetype
				}
				status
				roles {
					_id
					name
					slug
				}
			}
		}
	}
`;

export const UPDATE_WIZARDS_SHOWN = gql`
	mutation updateWizardsShown($input: UpdateWizardsShownInput!) {
		updateWizardsShown(input: $input) {
			message
			data {
				_id
				wizardsShown {
					deliveryTerminalKey
					hasExplainedCommission
					tutorialHowToMakeAFreightRequest
					tutorialHowToMakeAnOffer
				}
			}
		}
	}
`;
