// @ts-ignore
// @ts-nocheck
import { useEffect, useState } from 'react';
import { CONFIG } from '@/config';
import { useAuth0 } from '@auth0/auth0-react';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';

type ContactType = {
	role: string;
	info: string;
	location: string;
};

type DeliveryDetails = {
	_id: string;
	offer: string;
	shipment: string;
	contacts: ContactType[];
	documents: any[];
	document_comments: string[];
};

const BASE_URL = `${CONFIG.API_V2}/delivery-details`;

export const updateInvoiceDelivery = async ({
	id,
	access_token,
	data,
}: {
	id: string;
	access_token: string;
	data: {
		isKeelspotInvoiceSent?: boolean;
		isKeelspotPaid?: boolean;
	};
}) => {
	return fetch(`${CONFIG.API_V2}/delivery-invoice-admin/${id}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
		body: JSON.stringify(data),
	}).then((res) => res.json());
};

export const deliveredDelivery = async ({
	id,
	access_token,
}: {
	id: string;
	access_token: string;
}) => {
	return fetch(`${BASE_URL}/set-delivered/${id}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
	}).then((res) => res.json());
};

export const useDeliveryDetails = (deliveryId?: string) => {
	const { getAccessTokenSilently } = useAuth0();
	const [response, setResponse] = useState<DeliveryDetails>();
	const [loading, setLoading] = useState(true);
	const fetchDelivery = async () => {
		const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
		const response = await fetch(`${BASE_URL}/${deliveryId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}).then((res) => res.json());

		setResponse(response);
		setLoading(false);
	};
	const reload = () => {
		setLoading(true);
		fetchDelivery();
	};
	useEffect(() => {
		if (deliveryId) {
			setLoading(true);
			fetchDelivery();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deliveryId]);
	return { response, reload, loading };
};

export const useGetAllMyDeliveries = (isDelivered: boolean) => {
	const { currentUser } = useGetCurrentUser();
	const { getAccessTokenSilently } = useAuth0();
	const [response, setResponse] = useState<DeliveryDetails[]>();
	const [loading, setLoading] = useState(true);

	const fetchAllDeliveryDetails = async () => {
		const { access_token } = await getAccessTokenSilently({ detailedResponse: true });
		const url = `${BASE_URL}/get-all`;

		const response = await fetch(`${url}?isDelivered=${isDelivered}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${access_token}`,
			},
		}).then((res) => {
			return res.json();
		});

		setResponse(response);
		setLoading(false);
	};

	const reload = () => {
		setLoading(true);
		fetchAllDeliveryDetails();
	};
	useEffect(() => {
		if (currentUser) {
			fetchAllDeliveryDetails();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser]);
	return { response, reload, loading };
};

/******* DOCUMENT_COMMENT RELATED BELOW *******/

export const addDocumentCommentToDeliveryDetails = async ({
	title,
	comment,
	access_token,
	deliveryDetailsId,
}: {
	title: string;
	comment: string;
	access_token: string;
	deliveryDetailsId: string;
}) => {
	return fetch(`${BASE_URL}/attach-document-comment/${deliveryDetailsId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
		body: JSON.stringify({
			title,
			comment,
		}),
	}).then((res) => res.json());
};

/* Booking Confirmation */
export const generateBookingConfirmation = async ({
	access_token,
	deliveryDetailsId,
}: {
	access_token: string;
	deliveryDetailsId: string;
}) => {
	return fetch(`${BASE_URL}/generate-booking-confirmation/${deliveryDetailsId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
	}).then((res) => res.json());
};

/******* DOCUMENT RELATED BELOW *******/

export const removeDocumentFromDeliveryDetails = async ({
	documentId,
	deliveryDetailsId,
	access_token,
}: {
	documentId: string;
	deliveryDetailsId: string;
	access_token: string;
}) => {
	return fetch(`${BASE_URL}/remove-document-file/${deliveryDetailsId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
		body: JSON.stringify({
			documentId,
		}),
	}).then((res) => res.json());
};

export const removeNoteFromDeliveryDetails = async ({
	noteId,
	deliveryDetailsId,
	access_token,
}: {
	noteId: string;
	deliveryDetailsId: string;
	access_token: string;
}) => {
	return fetch(`${BASE_URL}/remove-document-note/${deliveryDetailsId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
		body: JSON.stringify({
			noteId,
		}),
	}).then((res) => res.json());
};

export const addDocumentToDeliveryDetails = async ({
	ids,
	deliveryDetailsId,
	access_token,
}: {
	ids: string[];
	deliveryDetailsId: string;
	access_token: string;
}) => {
	return fetch(`${BASE_URL}/attach-document-file/${deliveryDetailsId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
		body: JSON.stringify({
			ids,
		}),
	}).then((res) => res.json());
};

/******* CONTACT RELATED BELOW *******/

export const addContactToDeliveryDetails = async ({
	role,
	contactId: contact,
	location,
	deliveryDetailsId,
	access_token,
}: {
	role: string;
	contactId: string;
	deliveryDetailsId: string;
	location: string;
	access_token: string;
}) => {
	return fetch(`${BASE_URL}/attach-contact/${deliveryDetailsId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
		body: JSON.stringify({
			contact,
			role,
			location,
		}),
	}).then((res) => res.json());
};

export const removeContactFromDeliveryDetails = async ({
	contactId: contact,
	deliveryDetailsId,
	access_token,
}: {
	contactId: string;
	deliveryDetailsId: string;
	access_token: string;
}) => {
	return fetch(`${BASE_URL}/detach-contact/${deliveryDetailsId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
		body: JSON.stringify({
			contact,
		}),
	}).then((res) => res.json());
};

// Billing
export const addBillingToDeliveryDetails = async ({
	billingId: billing,
	deliveryDetailsId,
	access_token,
}: {
	billingId: string;
	deliveryDetailsId: string;
	access_token: string;
}) => {
	return fetch(`${BASE_URL}/attach-billing/${deliveryDetailsId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
		body: JSON.stringify({
			billing,
		}),
	}).then((res) => res.json());
};

export const removeBillingFromDeliveryDetails = async ({
	billingId: billing,
	deliveryDetailsId,
	access_token,
}: {
	billingId: string;
	deliveryDetailsId: string;
	access_token: string;
}) => {
	return fetch(`${BASE_URL}/detach-billing/${deliveryDetailsId}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
		body: JSON.stringify({
			billing,
		}),
	}).then((res) => res.json());
};

export const getRelatedDeliveryDetails = async ({
	companyId,
	access_token,
}: {
	billingId: string;
	deliveryDetailsId: string;
	access_token: string;
}) => {
	return fetch(`${BASE_URL}/by-company/${companyId}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
	}).then((res) => res.json());
};
