import { type FC } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material';
import KeelspotLogo from '@/assets/KeelspotLogo';

const BoxWrapper = styled(Box)`
	display: flex;
	align-items: center;
	background-color: #b7d5fb;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	left: 0;
	padding: 12px;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 2000;
	gap: 12px;
	div {
		height: 3px;
		width: 130px;
		background: #fff;
		@keyframes animate-logo {
			0% {
				transform: scaleX(0);
			}
			100% {
				transform: scaleX(1);
			}
		}
		animation: 2s animate-logo ease;
	}
` as any;

export const Loader: FC = () => (
	<BoxWrapper>
		<KeelspotLogo />
		<div />
	</BoxWrapper>
);
