import { Popup } from 'react-map-gl';
import { styled } from '@mui/material/styles';

export const VesselProperties = styled('div')`
	> div:not(:nth-child(3n)) {
		border-right: 1px solid #e2e1e0;
	}
`;

export const AISWrapper = styled('div')`
	> div {
		display: grid;
		grid-template-columns: 227px auto;
		/* Other/Outlined Border */
		border-bottom: 1px solid #e2e1e0;
		padding: 12px 24px;
	}
`;

export const VesselIconWrapper = styled('div')`
	width: fit-content;
	display: flex;
	position: relative;
	z-index: 10;
	svg {
		width: 24px;
		height: 24px;
		padding: 3px;
		&.show-pop-up {
			border-radius: 50%;
			background: #f6f5f480;
		}
	}
` as any;

export const CustomMapPopUp = styled(Popup)`
	width: 100%;
	.mapboxgl-popup-tip {
		display: none;
	}
	.mapboxgl-popup-content {
		padding: 2px;

		margin-top: 24px;
		/* Neutral/800 */
		background: #434342;
		/* Map popup */
		box-shadow: box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.25);
		border-radius: 8px;
		.mapboxgl-popup-close-button {
			display: none;
		}
	}
	.MuiAvatar-root {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background: black;
	}
	.vessel-data {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		h6 {
			font-size: 18px;
			line-height: 137.5%;
			+ p {
				font-size: 10px;
				line-height: 157%;
			}
		}
		> div:not(:last-child) {
			border-right: 1px solid #e2e1e0;
		}
	}
	.vessel-data-list {
		padding: 14px 16px;
		display: grid;
		grid-template-columns: 92px auto;
		p {
			font-size: 14px;
			line-height: 157%;
		}
		& + .vessel-data-list {
			/* Neutral/600 */
			border-top: 1px solid #777675;
		}
	}
` as any;
