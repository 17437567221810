const KeelspotLogo = ({ color = '#ffffff', ...rest }: any) => (
	<svg
		{...rest}
		width='161'
		height='24'
		viewBox='0 0 161 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M10.7198 11.3039L18.9016 23.8011H13.4916L7.88121 14.884L4.6419 19.1934V23.8011H0V0.198896H4.6419V13.3923L13.6251 0.198896H18.3004L10.7198 11.3039Z'
			fill={color}
		/>
		<path
			d='M38.2051 23.8011H29.7896C27.118 23.8011 25.2368 23.3481 24.1459 22.442C23.0772 21.5138 22.5429 19.8895 22.5429 17.5691V0.198896H38.2051V4.30939H27.1848V9.8453H34.899L34.5651 13.9558H27.1848V17.2376C27.1848 18.1657 27.3963 18.8066 27.8193 19.1602C28.2423 19.5138 29.0104 19.6906 30.1236 19.6906H38.2051V23.8011Z'
			fill={color}
		/>
		<path
			d='M58.7587 23.8011H50.3432C47.6716 23.8011 45.7903 23.3481 44.6994 22.442C43.6308 21.5138 43.0965 19.8895 43.0965 17.5691V0.198896H58.7587V4.30939H47.7384V9.8453H55.4526L55.1186 13.9558H47.7384V17.2376C47.7384 18.1657 47.9499 18.8066 48.3729 19.1602C48.7959 19.5138 49.564 19.6906 50.6771 19.6906H58.7587V23.8011Z'
			fill={color}
		/>
		<path
			d='M78.3104 23.8011H71.2307C68.5591 23.8011 66.6222 23.2928 65.4199 22.2762C64.24 21.2376 63.65 19.558 63.65 17.2376V0.198896H68.2919V17.2376C68.2919 18.1657 68.5034 18.8066 68.9264 19.1602C69.3494 19.5138 70.1175 19.6906 71.2307 19.6906H78.3104V23.8011Z'
			fill={color}
		/>
		<path
			d='M98.3743 16.8398C98.3743 19.0718 97.5505 20.8287 95.903 22.1105C94.2555 23.3702 92.1517 24 89.5914 24C87.5654 24 85.6063 23.4475 83.7139 22.3425C82.7788 21.768 82.0107 20.9613 81.4096 19.9227C80.8085 18.8619 80.508 17.6575 80.508 16.3094L84.7157 16.1105C84.7157 17.3039 85.2278 18.232 86.2519 18.895C87.276 19.558 88.4114 19.8895 89.6582 19.8895C92.4411 19.8895 93.8326 18.9171 93.8326 16.9724C93.8326 15.3812 92.8864 14.442 90.994 14.1547L87.1202 13.7569C85.1833 13.4696 83.6471 12.7845 82.5117 11.7017C81.3762 10.6188 80.8085 9.06077 80.8085 7.02762C80.8085 4.99448 81.6211 3.31492 83.2463 1.98895C84.8716 0.662983 86.9198 0 89.391 0C91.8845 0 93.8882 0.629835 95.4021 1.8895C96.916 3.12707 97.673 4.72928 97.673 6.69613L93.532 6.89503C93.1535 4.97238 91.8511 4.01105 89.6248 4.01105C88.2667 4.01105 87.1981 4.29834 86.4189 4.87293C85.6619 5.44751 85.2834 6.1326 85.2834 6.92818C85.2834 7.70166 85.5617 8.32044 86.1183 8.78453C86.6749 9.22652 87.3651 9.50276 88.1888 9.61326L91.9958 10.0111C96.2481 10.5856 98.3743 12.8619 98.3743 16.8398Z'
			fill={color}
		/>
		<path
			d='M117.403 2.25414C118.784 3.60221 119.474 5.59116 119.474 8.22099C119.474 10.8508 118.784 12.8508 117.403 14.221C116.045 15.5691 114.12 16.2431 111.626 16.2431H107.051V23.8011H102.409V0.198896H111.626C114.12 0.198896 116.045 0.883979 117.403 2.25414ZM114.832 8.22099C114.832 5.61326 113.685 4.30939 111.392 4.30939H107.051V12.0994H111.392C112.505 12.0994 113.351 11.8011 113.93 11.2044C114.531 10.6077 114.832 9.61326 114.832 8.22099Z'
			fill={color}
		/>
		<path
			d='M141.145 10.9061V12.6961C141.145 16.6519 140.388 19.5249 138.874 21.3149C137.382 23.105 134.978 24 131.661 24C128.343 24 125.939 23.105 124.447 21.3149C122.978 19.5249 122.243 16.6188 122.243 12.5967V10.8729C122.243 7.20442 122.989 4.47514 124.481 2.68508C125.995 0.895028 128.377 0 131.627 0C134.9 0 137.304 0.906078 138.841 2.71823C140.377 4.53039 141.145 7.25967 141.145 10.9061ZM136.503 12.5967V11.3702C136.503 9.24862 136.236 7.55801 135.701 6.29834C135.078 4.83978 133.742 4.1105 131.694 4.1105C129.624 4.1105 128.277 4.83978 127.653 6.29834C127.141 7.49171 126.885 9.18232 126.885 11.3702V12.5967C126.885 15.4475 127.286 17.3702 128.087 18.3646C128.399 18.7845 128.856 19.1492 129.457 19.4586C130.08 19.7459 130.815 19.8895 131.661 19.8895C132.529 19.8895 133.264 19.7459 133.865 19.4586C134.466 19.1713 134.933 18.8177 135.267 18.3978C135.601 17.9779 135.857 17.4254 136.035 16.7403C136.347 15.6575 136.503 14.2762 136.503 12.5967Z'
			fill={color}
		/>
		<path
			d='M161 4.30939H154.622V23.8011H149.98V4.30939H143.635V0.198896H161V4.30939Z'
			fill={color}
		/>
	</svg>
);

export default KeelspotLogo;
