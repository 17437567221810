import { UserMeta } from '@/components/Chat/Chat';
import { useState } from 'react';
import Talk from 'talkjs';

let chatPopup: Talk.Popup;

export function useConversation(session: Talk.Session | undefined) {
	const [conversation, setConversation] = useState<Talk.ConversationBuilder>();

	function createUserPopup(session: Talk.Session, conversation: Talk.ConversationBuilder | string) {
		if (!chatPopup) {
			chatPopup = session.createPopup({ showCloseInHeader: true });
			chatPopup.mount({ show: true });
		}
		chatPopup.select(conversation);
		chatPopup.show();
	}

	async function createUserConversation(targetUsers: UserMeta[]) {
		if (!targetUsers) throw 'erro targetUsers';
		if (!session) throw 'erro session';
		await Talk.ready;
		const currentTalkUser = createTalkUser(session.me as UserMeta);
		const usersTalks = targetUsers.map(createTalkUser);
		const otherTalkUser = usersTalks[0];

		const conversationBuilder = session.getOrCreateConversation(
			Talk.oneOnOneId(currentTalkUser, otherTalkUser),
		);
		conversationBuilder.setParticipant(currentTalkUser);
		usersTalks.map((user) => conversationBuilder.setParticipant(user));
		setConversation(conversationBuilder);
		createUserPopup(session, conversationBuilder);
	}
	async function createGroupConversation(conversationId: string) {
		if (!session) throw 'erro session';
		await Talk.ready;
		const conversationBuilder = session.getOrCreateConversation(conversationId);
		setConversation(conversationBuilder);
		// createUserPopup(session, conversationBuilder);
	}

	function createTalkUser(applicationUser: UserMeta): Talk.User {
		return new Talk.User({
			id: applicationUser.id,
			name: applicationUser.name,
			photoUrl: applicationUser.photoUrl,
			configuration: 'demo_default',
			welcomeMessage: applicationUser.welcomeMessage,
		});
	}

	return { conversation, createUserConversation, createUserPopup, createGroupConversation };
}
