import { z } from 'zod';
import { FormEvent, ReactNode, useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	useForm,
	FormProvider,
	SubmitHandler,
	SubmitErrorHandler,
	FieldValues,
} from 'react-hook-form';

type TMethodsProps<SchemaType extends z.ZodSchema> = {
	initialValues?: z.infer<SchemaType>;
	schema?: SchemaType;
	mode?: 'onSubmit' | 'onChange' | 'onBlur' | 'onTouched' | 'all';
};

type TFormProps<SchemaType extends FieldValues> = {
	onSubmit: SubmitHandler<SchemaType>;
	onInvalid?: SubmitErrorHandler<SchemaType>;
	children: ReactNode;
	formId?: string;
};

export default function useRHForm<SchemaType extends z.ZodSchema>({
	initialValues,
	schema,
	mode,
}: TMethodsProps<SchemaType>) {
	type ValidationSchema = SchemaType extends undefined ? never : z.infer<SchemaType>;
	const methods = useForm<ValidationSchema>({
		resolver: !schema ? undefined : zodResolver(schema),
		mode: mode || 'all',
		defaultValues: initialValues,
	});

	const Form = useMemo(() => {
		const FormComponent = ({
			formId,
			children,
			onSubmit,
			onInvalid,
		}: TFormProps<ValidationSchema>) => {
			const submit = (event: FormEvent<HTMLFormElement>) => {
				event?.stopPropagation();
				event?.preventDefault();

				const actualSubmit = methods.handleSubmit(onSubmit, onInvalid);
				actualSubmit(event);
			};
			return (
				<FormProvider {...methods}>
					<form id={formId} onSubmit={submit}>
						{children}
					</form>
				</FormProvider>
			);
		};
		return FormComponent;
	}, [methods]);

	return {
		methods,
		Form,
	};
}
