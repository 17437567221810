import type { LayerProps, VectorSourceRaw } from 'react-map-gl';

interface Layers {
	dnl: Array<LayerProps>;
}

interface Sources {
	dnl: VectorSourceRaw;
}

export const sources: Sources = {
	dnl: {
		type: 'vector',
		maxzoom: 18,
		tiles: ['https://cache.kartverket.no/dnl/{z}/{x}/{y}.mvt'],
	},
};

export const layers: Layers = {
	dnl: [
		{
			id: 'svalbard1000_vann',
			type: 'fill',
			source: 'dnl',
			'source-layer': 'svalbard_s1000_vann',
			minzoom: 4,
			maxzoom: 9,
			paint: {
				'fill-color': '#e7f6fd',
			},
		},
		{
			id: 'svalbard250_vann',
			type: 'fill',
			source: 'dnl',
			'source-layer': 'svalbard_s250_vann',
			minzoom: 8,
			maxzoom: 11,
			paint: {
				'fill-color': 'rgba(142, 164, 210, 07)',
			},
		},

		{
			id: 'svalbard1000_isbre',
			type: 'fill',
			source: 'dnl',
			'source-layer': 'svalbard_s1000_isbreer',
			minzoom: 4,
			maxzoom: 8,
			paint: {
				'fill-color': 'rgba(142, 164, 210, 07)',
			},
		},
		{
			id: 'svalbard250_isbre',
			type: 'fill',
			source: 'dnl',
			'source-layer': 'svalbard_s250_isbreer',
			minzoom: 8,
			maxzoom: 11,
			paint: {
				'fill-color': 'rgba(142, 164, 210, 07)',
			},
		},
		{
			id: 'svalbard100_isbre',
			type: 'fill',
			source: 'dnl',
			'source-layer': 'svalbard_s100_isbreer',
			minzoom: 11,
			maxzoom: 15,
			paint: {
				'fill-color': 'rgba(142, 164, 210, 07)',
			},
		},
		{
			interactive: true,
			id: 'svalbard250_koter',
			minzoom: 10,
			maxzoom: 13,
			type: 'line',
			source: 'dnl',
			'source-layer': 'svalbard_s250_koter',
			paint: {
				'line-width': 1,
				'line-color': 'rgba(142, 164, 210, 07)',
				'line-blur': 1.8,
			},
		},

		{
			interactive: true,
			id: 'svalbard100_koter',
			minzoom: 12,
			type: 'line',
			source: 'dnl',
			'source-layer': 'svalbard_s100_koter',
			paint: {
				'line-width': 1,
				'line-color': 'rgba(142, 164, 210, 07)',
				'line-blur': 1.8,
			},
		},
		{
			id: 'Dybdekontur',
			type: 'line',
			minzoom: 8,
			source: 'dnl',
			'source-layer': 'Dybdekontur',
			paint: {
				'line-width': 1,
				'line-color': 'rgba(142, 164, 210,0.7)',
			},
		},
		{
			id: 'MudretOmrade_Grense',
			type: 'line',
			minzoom: 11,
			source: 'dnl',
			'source-layer': 'MudretOmrade_Grense',
			paint: {
				'line-width': 1,
				'line-color': 'rgba(190,190,190,1)',
				'line-dasharray': [5, 5],
			},
		},
		{
			id: 'MudretOmrade',
			type: 'fill',
			minzoom: 11,
			source: 'dnl',
			'source-layer': 'MudretOmrade',
			paint: {
				'fill-color': [
					'interpolate',
					['linear'],
					['to-number', ['get', 'minimumsdybde']],
					0.5,
					'#8cc7ed',
					5,
					'#cce9f2',
					10,
					'#e7f6fd',
					2000,
					'#e7f6fd',
				],
			},
		},
		{
			id: 'Flytebrygge',
			type: 'fill',
			minzoom: 11,
			source: 'dnl',
			'source-layer': 'Flytebrygge',
			paint: {
				'fill-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'Flytebrygge_Grense',
			type: 'line',
			minzoom: 11,
			source: 'dnl',
			'source-layer': 'Flytebrygge_Grense',
			paint: {
				'line-width': 1,
				'line-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'flytebrygge_l',
			type: 'line',
			minzoom: 11,
			source: 'dnl',
			'source-layer': 'Flytebrygge_l',
			paint: {
				'line-width': 1,
				'line-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'Flytedokk',
			type: 'fill',
			minzoom: 11,
			source: 'dnl',
			'source-layer': 'Flytedokk',
			paint: {
				'fill-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'flytedokk_grense',
			type: 'line',
			minzoom: 11,
			source: 'dnl',
			'source-layer': 'flytedokk_grense',
			paint: {
				'line-width': 1,
				'line-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'Torrdokk_Grense',
			type: 'line',
			minzoom: 11,
			source: 'dnl',
			'source-layer': 'Torrdokk_Grense',
			paint: {
				'line-width': 1,
				'line-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'Konstruertkystkontur',
			type: 'line',
			minzoom: 12,
			source: 'dnl',
			'source-layer': 'Konstruertkystkontur',
			paint: {
				'line-width': 1,
				'line-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'konst_kyst_l',
			type: 'line',
			minzoom: 12,
			source: 'dnl',
			'source-layer': 'konst_kyst_l',
			paint: {
				'line-width': 1,
				'line-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'Kystkontur',
			type: 'line',
			minzoom: 12,
			source: 'dnl',
			'source-layer': 'Kystkontur',
			paint: {
				'line-width': 1,
				'line-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'Molo_n50',
			type: 'line',
			minzoom: 11,
			source: 'dnl',
			'source-layer': 'N50Anleggslinje',
			filter: ['==', 'objtype', 'Molo'],
			paint: {
				'line-width': 1,
				'line-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'KaiBrygge_n50',
			type: 'line',
			minzoom: 11,
			source: 'dnl',
			'source-layer': 'N50Anleggslinje',
			filter: ['==', 'objtype', 'KaiBrygge'],
			paint: {
				'line-width': 1,
				'line-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'svalbard250_KaiBrygge',
			type: 'line',
			minzoom: 8,
			maxzoom: 11,
			source: 'dnl',
			'source-layer': 'svalbard_s250_teknisit_linje',
			filter: ['==', 'objekttypenavn', 'KaiBryggeKant'],
			paint: {
				'line-width': 1,
				'line-color': 'rgba(73, 81, 111,0.7)',
			},
		},

		{
			id: 'svalbard100_KaiBrygge',
			type: 'line',
			minzoom: 11,
			source: 'dnl',
			'source-layer': 'svalbard_s100_teknisit_linje',
			filter: ['==', 'objekttypenavn', 'KaiBryggeKant'],
			paint: {
				'line-width': 1,
				'line-color': 'rgba(73, 81, 111,0.7)',
			},
		},
		{
			id: 'Dybdekontur-label',
			type: 'symbol',
			source: 'dnl',
			'source-layer': 'Dybdekontur',
			minzoom: 11,
			layout: {
				visibility: 'visible',
				'symbol-placement': 'line',
				'text-font': ['Open Sans Regular'],

				'text-size': 12,
				'symbol-sort-key': ['to-number', ['get', 'dybde']],
				'text-field': ['get', 'dybde'],
			},
			paint: {
				'text-color': '#8EA4D2',
				'text-halo-width': 1.5,
			},
		},

		{
			id: 'Dybdepunkt-label',
			type: 'symbol',
			minzoom: 12,
			source: 'dnl',
			'source-layer': 'Dybdepunkt',
			layout: {
				visibility: 'visible',
				'text-padding': 10,
				'text-font': ['Open Sans Regular'],
				'text-size': 12,
				'symbol-sort-key': ['to-number', ['get', 'dybde']],
				'text-field': [
					'case',
					['<=', ['to-number', ['get', 'dybde']], 30],
					['get', 'dybde'],
					['to-string', ['round', ['-', ['to-number', ['get', 'dybde']], 0.5]]],
				],
			},
			paint: {
				'text-color': '#6279B8',
				'text-halo-width': 1.5,
			},
		},
		{
			id: 'skvalpeskjaer',
			type: 'symbol',
			source: 'dnl',
			'source-layer': 'grunne',
			filter: [
				'all',
				['>', ['to-number', ['get', 'dybde']], 0],
				['<', ['to-number', ['get', 'dybde']], 0.5],
			],
			layout: {
				'icon-image': 'skvalpeskjaer',
				'icon-allow-overlap': true,
			},
		},
		{
			id: 'stjernelus',
			type: 'symbol',
			source: 'dnl',
			'source-layer': 'grunne',
			filter: ['all', ['<', ['to-number', ['get', 'dybde']], 0]],
			layout: {
				'icon-image': 'stjernelus',
				'icon-allow-overlap': true,
			},
		},
		{
			id: 'grunne',
			type: 'symbol',
			source: 'dnl',
			'source-layer': 'grunne',
			filter: [
				'all',
				['>', ['to-number', ['get', 'dybde']], 0.5],
				['<', ['to-number', ['get', 'dybde']], 10],
			],
			layout: {
				'icon-image': 'grunne',
				'icon-allow-overlap': true,
				'icon-offset': [-2, -2],
			},
		},
		{
			id: 'grunne-label',
			type: 'symbol',
			source: 'dnl',
			'source-layer': 'grunne',
			minzoom: 12,
			filter: ['all', ['>', ['to-number', ['get', 'dybde']], 0.5]],
			layout: {
				visibility: 'visible',
				'text-anchor': 'top-left',
				'text-font': ['Open Sans Bold'],
				'text-size': 11,
				'symbol-sort-key': ['to-number', ['get', 'dybde']],
				'text-field': ['get', 'dybde'],
			},
			paint: {
				'text-color': 'rgba(142, 164, 210, 07)',
				'text-halo-width': 1.5,
			},
		},
	],
};
