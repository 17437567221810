import { styled } from '@mui/material';

export const GroupHeader = styled('div')(({ theme }) => ({
	position: 'sticky',
	top: '-8px',
	padding: '4px 10px',
	textTransform: 'uppercase',
	color: theme.palette.text.secondary,
	backgroundColor: theme.palette.background.paper,
}));

export const GroupItems = styled('ul')(({ theme }: any) => ({
	padding: '0px',
	color: theme.palette.text.primary,
}));

export const FieldWrapper = styled('div')`
	.MuiInputBase-root {
		padding: 9px 12px;
	}
`;

export const FileUploadPreview = styled('div')`
	background: rgba(43, 127, 187, 0.04);
	/* Other/Outlined Border */
	border: 1px solid #e2e1e0;
	border-radius: 8px;
	width: 146px;
	height: 124px;
	padding: 8px;
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	overflow: hidden;
	img {
		margin: 0 auto;
		max-height: 100%;
		max-width: 100%;
		display: block;
	}
`;

export const FileUploadPreviewImageWrapper = styled('div')`
	flex: 1;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
`;
