import { FC, useMemo, ReactNode } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

import { useThemeContext } from '@context/ThemeContext';
import { lightThemeOptions } from './lightThemeOptions';
import { baseThemeOptions } from './baseThemeOptions';

export const CustomTheme = { ...baseThemeOptions, ...lightThemeOptions };

interface Props {
	children: ReactNode;
}

const createThemeHelper = (_theme: 'dark' | 'light') => {
	// const isDark = theme === 'dark';
	return createTheme(lightThemeOptions, baseThemeOptions);
};

export const ThemeProvider: FC<Props> = ({ children }) => {
	const { currentTheme } = useThemeContext();
	const muiTheme = useMemo(() => createThemeHelper(currentTheme), [currentTheme]);

	return (
		<StyledEngineProvider injectFirst>
			<MuiThemeProvider theme={muiTheme}>
				<CssBaseline />
				{children}
			</MuiThemeProvider>
		</StyledEngineProvider>
	);
};
