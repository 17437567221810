import { useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Avatar, ButtonBase, Tooltip } from '@mui/material';

import { getInitials } from '@/utils/strings';
import { AccountPopover } from './AccountPopover';
import { useDisclosure } from '@hooks/useDisclosure';
import { useTranslation } from 'react-i18next';

export const AccountButton = ({
	currentComponent,
}: {
	currentComponent: HTMLDivElement | null;
}) => {
	const anchorRef = useRef<HTMLButtonElement | null>(null);
	const { isOpen, onToggle, onClose } = useDisclosure();
	const { user } = useAuth0();
	const fullName = user?.name ?? '';
	const initials = getInitials(fullName);
	const { t } = useTranslation();

	return (
		<>
			<Tooltip title={t('accountDropdown.me')} arrow>
				<Box component={ButtonBase} onClick={onToggle} ref={anchorRef} className={`flex items-center`}>
					<Avatar className='h-10 w-10' src={user?.picture}>
						{initials}
					</Avatar>
				</Box>
			</Tooltip>
			<AccountPopover anchorEl={currentComponent} onClose={onClose} open={isOpen} />
		</>
	);
};
