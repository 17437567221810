// @ts-nocheck

import {
	Avatar,
	Divider,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Popover,
	Typography,
} from '@mui/material';
import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { useTranslation } from 'react-i18next';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { getInitials } from '@/utils/strings';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import { OperationEnum } from '@/enums/OperationEnum';

import { ProfileAvatarRoot, ProfileDetails, ProfileMenuList } from './Styled';
import { useDisclosure } from '@/hooks/useDisclosure';
import { SwitchOperationsModal } from '../../sideMenu/SwitchOperations';
import { useNavigate } from 'react-router-dom';
import { isInDemoMode } from '@/utils/isInDemoMode';

interface AccountPopoverProps {
	anchorEl: null | Element;
	onClose?: () => void;
	open?: boolean;
}

const SwitchOperations = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { t } = useTranslation();
	return (
		<>
			<MenuItem onClick={onOpen}>
				<ListItemIcon>
					<SwapHorizIcon fontSize='small' />
				</ListItemIcon>
				<ListItemText
					primary={<Typography variant='body1'>{t('accountDropdown.switchOperation')}</Typography>}
				/>
			</MenuItem>
			<SwitchOperationsModal isOpen={isOpen} onClose={onClose} />
		</>
	);
};

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
	const { logout, user } = useAuth0();
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const { anchorEl, onClose, open, ...other } = props;
	const { currentUser } = useGetCurrentUser();
	const currentLanguage = i18n.language;

	const fullName = user?.name ?? '';
	const initials = getInitials(fullName);

	const handleLogout = async (): Promise<void> => {
		try {
			// Clean up the local storage
			// localStorage.clear();
			logout({ logoutParams: { returnTo: window.location.origin } });
		} catch (err) {
			console.error(err);
			// toast.error('Unable to logout.');
		}
	};

	const handleSwitchLanguages = async () => {
		await i18n.changeLanguage(currentLanguage === 'nb' ? 'en' : 'nb');
		onClose && onClose();
	};

	const handleNavigateUserProfile = async () => {
		navigate('/app/profile');
	};

	const handleNavigateCompanyProfile = async () => {
		navigate(`/app/company/company/${currentUser.companyId}`);
	};

	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: -10,
				horizontal: 'right',
			}}
			keepMounted
			onClose={onClose}
			open={!!open}
			PaperProps={{ sx: { width: 300 } }}
			transitionDuration={0}
			{...other}
		>
			<ProfileAvatarRoot>
				<Avatar src={user?.picture} className='h-10 w-10'>
					{initials}
				</Avatar>
				<ProfileDetails>
					<Typography variant='body1'>{currentUser?.name ?? ''}</Typography>
					<Typography color='textSecondary' variant='caption'>
						{currentUser?.company ?? ''}
					</Typography>
				</ProfileDetails>
			</ProfileAvatarRoot>
			<Divider />
			<ProfileMenuList>
				{!isInDemoMode && (
					<>
						<MenuItem onClick={handleNavigateUserProfile}>
							<ListItemIcon>
								<PersonIcon fontSize='small' />
							</ListItemIcon>
							<ListItemText primary={<Typography variant='body1'>{'User settings'}</Typography>} />
						</MenuItem>
						<MenuItem onClick={handleNavigateCompanyProfile}>
							<ListItemIcon>
								<BusinessIcon fontSize='small' />
							</ListItemIcon>
							<ListItemText primary={<Typography variant='body1'>{'Company profile'}</Typography>} />
						</MenuItem>
						{(currentUser?.isAdmin ||
							currentUser?.companyPrimaryOperationSlug === OperationEnum.SHIP_OPERATOR) && (
							<SwitchOperations />
						)}
					</>
				)}
				<Divider />
				<MenuItem onClick={handleLogout}>
					<ListItemIcon>
						<ExitToAppIcon fontSize='small' />
					</ListItemIcon>
					<ListItemText
						primary={<Typography variant='body1'>{t('accountDropdown.logout')}</Typography>}
					/>
				</MenuItem>
			</ProfileMenuList>
		</Popover>
	);
};
