import styled from 'styled-components';
import locationIcon from '@/assets/images/location.svg';
import fullscreenIcon from '@/assets/images/fullscreen.svg';
import plusIcon from '@/assets/images/plus.svg';
import minusIcon from '@/assets/images/minus.svg';
import { Autocomplete, Paper } from '@mui/material';

export const MapContainer = styled('div')`
	minheight: calc(100vh - (72px + 53px));
	.mapboxgl-map {
		top: 0;
		bottom: 0;
		position: absolute !important;
	}
	.mapboxgl-ctrl-attrib {
		display: none !important;
	}
	.mapboxgl-ctrl-top-left .mapboxgl-ctrl {
		margin: 24px 0 0 24px;
		svg.mapboxgl-ctrl-geocoder--icon {
			width: 24px;
			height: 24px;
			top: 16px;
			left: 12px;
		}
		input.mapboxgl-ctrl-geocoder--input {
			/* Background/Paper */
			background: #ffffff;
			/* Shadow/8 */
			box-shadow: 0px 2px 4px rgba(34, 34, 33, 0.06), 0px 4px 6px rgba(119, 118, 117, 0.12);
			border-radius: 8px;
			height: 56px;
			width: 300px;
			padding-left: 42px;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			/* Text/Secondary */
			color: #777675;
		}
	}
	.mapboxgl-ctrl-bottom-right {
		bottom: 14px;
		right: 14px;
		.mapboxgl-ctrl-group {
			margin-right: 0;
			border-radius: 24px;
			background: none;
			&:not(:empty) {
				box-shadow: none;
			}
			button {
				width: 40px;
				height: 40px;
				background: rgba(67, 67, 66, 1) !important;
				box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
				-webkit-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
				-moz-box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.1);
				border-radius: 24px;

				&,
				:not(:disabled):hover {
					background-color: #fff;
				}
			}
			//geolocate
			.mapboxgl-ctrl-geolocate {
				.mapboxgl-ctrl-icon {
					background-image: url(${locationIcon});
				}
			}
			//full screen button
			.mapboxgl-ctrl-fullscreen {
				.mapboxgl-ctrl-icon {
					background-image: url(${fullscreenIcon});
				}
			}

			//zoom in button
			.mapboxgl-ctrl-zoom-in {
				border-radius: 24px;
				.mapboxgl-ctrl-icon {
					background-image: url(${plusIcon});
				}
			}

			//zoom out
			.mapboxgl-ctrl-zoom-out {
				margin-top: 10px;
				border-radius: 24px;
				.mapboxgl-ctrl-icon {
					background-image: url(${minusIcon});
				}
			}

			+ .mapboxgl-ctrl-scale {
				display: none;
			}
		}
		#satellite-control-container {
			.mapboxgl-ctrl-icon {
				color: white;
			}
		}
	}
	.action-btn {
		cursor: pointer;
		width: 40px;
		height: 40px;
		box-shadow: 0px 54px 15px rgba(0, 0, 0, 0.01), 0px 34px 14px rgba(0, 0, 0, 0.04),
			0px 19px 12px rgba(0, 0, 0, 0.14), 0px 9px 9px rgba(0, 0, 0, 0.24),
			0px 2px 5px rgba(0, 0, 0, 0.27), 0px 0px 0px rgba(0, 0, 0, 0.28);
		border-radius: 24px;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 2;
		outline: none;
		border: none;
		padding: 4px;
		&.eye-control {
			right: 24px;
			bottom: 24px;
			+ .eye-control {
				// multiple copies of eye icon
				display: none;
			}
		}
	}
	@media (hover: none) and (pointer: coarse) {
		.mapboxgl-ctrl-bottom-right {
			display: none;
		}
	}
`;

export const GeocoderSearchBar = styled(Autocomplete)<any>((props: any) => ({
	'.MuiAutocomplete-inputRoot': {
		backgroundColor: props.theme.palette.background.paper,
		borderRadius: props.$isOpen ? '8px 8px 0px 0px' : '8px 8px 8px 8px',
	},
	'& fieldset': {
		border: 'none',
	},
	'&:hover': {
		border: 'none',
	},
	width:
		'min(400px, calc(100vw - 24px - var(--main-menu-drawer-width) - var(--map-drawer-width) - var(--account-notification-width)))',

	[props.theme.breakpoints.down('lg')]: {
		width: 'min(400px, calc(100vw - 24px - var(--main-menu-drawer-width) - var(--map-drawer-width)))',
	},
}));

export const GeocoderDropdownPaper = styled(Paper)`
	background: #fff;
	border-top: none;
	border: 1px solid #e2e1e0;
	box-shadow: 0px 2px 4px rgba(34, 34, 33, 0.06), 0px 4px 6px rgba(119, 118, 117, 0.12);
	border-radius: 0px 0px 8px 8px;
	.MuiAutocomplete-listbox {
		& svg {
			fill: #777675;
		}
	}
`;
