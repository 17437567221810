import { useQuery } from '@tanstack/react-query';
import { useClient } from '@context/GraphqlRequestContext';
import { RequestOptions } from 'graphql-request';
type IConfig = {
	enabled?: boolean;
	retryOnMount?: boolean;
	signal?: AbortSignal;
};

type IQueryParams<Variables extends object, QueryName extends string> = {
	queryName: QueryName;
	gql: string;
	args?: Variables;
	config?: IConfig;
};

//Custom hook to trigger graphql queries

export const useQueries = <
	ResponseDataType,
	QueryName extends string = string,
	Variables extends object = Record<string, unknown>,
	RequestResponse extends Record<QueryName, { data: ResponseDataType }> = Record<
		QueryName,
		{ data: ResponseDataType }
	>,
>(
	params: IQueryParams<Variables, QueryName>,
	clientType: 'public' | 'private' = 'private',
) => {
	const { client, publicClient } = useClient();
	const CACHE_KEY = [params.queryName, params?.args].filter(Boolean);
	const { args: variables, queryName, gql, config = {} } = params;
	const { signal, ...useQueryConfig } = config;
	const queryClient = clientType === 'private' ? client : publicClient;

	return useQuery(
		CACHE_KEY,
		async () => {
			const requestResponse = await queryClient.rawRequest<RequestResponse, Variables>({
				query: gql,
				signal: signal as RequestOptions['signal'],
				variables,
			});

			const { data } = requestResponse.data[queryName];

			return data;
		},
		{
			...useQueryConfig,
		},
	);
};
