export const neutral = {
	100: '#F6F5F4',
	200: '#EFEEED',
	300: '#E2E1E0',
	400: '#BFBEBD',
	500: '#A09F9E',
	600: '#777675',
	700: '#636261',
	800: '#434342',
	900: '#222221',
};
export const grey = {
	50: '#FAFAFA',
	100: '#F5F5F5',
	200: '#EEEEEE',
	300: '#E0E0E0',
	400: '#BDBDBD',
	500: '#9E9E9E',
	600: '#757575',
	700: '#616161',
	800: '#424242',
	900: '#212121',
	A100: '#D5D5D5',
	A200: '#AAAAAA',
	A400: '#616161',
	A700: '#303030',
};

export const background = {
	default: '#FBFAF9',
	paper: '#FFFFFF',
};
export const common = {
	black: '#000000',
	white: '#FFFFFF',
};
export const divider = '#E2E1E0';
export const snackbar = '#323232';

export const primary = {
	main: '#0C84DB',
	light: '#8BBCDB',
	dark: '#003380',
	contrastText: '#FFFFFF',
};

export const secondary = {
	main: '#C62828',
	light: '#FF5F52',
	dark: '#8E0000',
	contrastText: '#FFFFFF',
};

export const success = {
	main: '#269400',
	light: '#43C6B7',
	dark: '#0E8074',
	contrastText: '#FFFFFF',
};

export const info = {
	main: '#64B6F7',
	light: '#83C4F8',
	dark: '#467FAC',
	contrastText: '#FFFFFF',
};

export const warning = {
	main: '#FFB020',
	light: '#FFBF4C',
	dark: '#845B10',
	contrastText: '#FFFFFF',
};

export const error = {
	main: '#D50000',
	light: '#FF5131',
	dark: '#9B0000',
	contrastText: '#FFFFFF',
};

export const text = {
	primary: '#222221',
	secondary: '#777675',
	disabled: '#434342',
};
