// @ts-nocheck

import { CONFIG } from '@/config';

export const updateCompanyRoles = async ({
	access_token,
	selectedCompanyRoles,
	id,
}: {
	selectedCompanyRoles: string[];
	id: string;
	access_token: string;
}) => {
	const updateCompany = await fetch(`${CONFIG.API_V2}/company/update-roles/${id}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
		body: JSON.stringify({ selectedCompanyRoles }),
	});
	// Check status
	if (updateCompany.status === 200) {
		return updateCompany.json();
	}
	return null;
};

export const getCompanyById = async ({
	access_token,
	id,
}: {
	id: string;
	access_token: string;
}) => {
	const co = await fetch(`${CONFIG.API_V2}/company/by-id/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
	});
	// Check status
	if (co.status === 200) {
		return co.json();
	}
	return null;
};

export const getReviewsForCompany = async ({
	access_token,
	id,
}: {
	id: string;
	access_token: string;
}) => {
	const revs = await fetch(`${CONFIG.API_V2}/reviews/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
	});
	// Check status
	if (revs.status === 200) {
		return revs.json();
	}
	return [];
};

export const getDocumentsForCompany = async ({
	access_token,
	id,
}: {
	id: string;
	access_token: string;
}) => {
	const revs = await fetch(`${CONFIG.API_V2}/documents/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
	});
	// Check status
	if (revs.status === 200) {
		return revs.json();
	}
	return [];
};

export const getFleetForCompany = async ({
	access_token,
	id,
}: {
	id: string;
	access_token: string;
}) => {
	const revs = await fetch(`${CONFIG.API_V2}/company/fleet/${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${access_token}`,
		},
	});
	// Check status
	if (revs.status === 200) {
		return revs.json();
	}
	return [];
};
