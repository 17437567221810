/* eslint-disable react/prop-types */
// @ts-nocheck

import { Typography } from '@mui/material';
import { FileUploadPreview, FileUploadPreviewImageWrapper } from './form/Styled';
import ModalImage from 'react-modal-image';
import { thumbnailForFile, isExcelFile, isPDFFile } from '@/utils/fileType';

const UploadedFile = ({ file }) => {
	const fileThumbnail = thumbnailForFile(file);
	const realPath = file.fullPath || file.path;
	return (
		<FileUploadPreview key={file._id}>
			<Typography variant='subtitle2' className='truncate'>
				{file?.originalName ?? 'Unknown'}
			</Typography>
			<FileUploadPreviewImageWrapper>
				{isExcelFile(file) || isPDFFile(file) ? (
					// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
					<img
						src={fileThumbnail}
						alt={file._id}
						key={`${file._id}_img`}
						onClick={() => window.open(realPath, '_blank')}
						style={{ cursor: 'pointer' }} // Adding style for cursor
					/>
				) : (
					<ModalImage small={realPath} large={realPath} />
				)}
			</FileUploadPreviewImageWrapper>
		</FileUploadPreview>
	);
};

export default UploadedFile;
