const TabPanel = ({ children, nobg = false }: any) => {
	return (
		<div
			className={`${!nobg ? 'bg-white px-6 py-8 shadow' : 'py-4'}  flex flex-col gap-4 rounded-md`}
		>
			{children}
		</div>
	);
};

export default TabPanel;
