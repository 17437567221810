import {
	Component,
	PropsWithChildren,
	FunctionComponent,
	ReactElement,
	ComponentType,
	PropsWithRef,
	ErrorInfo,
	isValidElement,
	useState,
	Dispatch,
	SetStateAction,
} from 'react';

const changedArray = (a: Array<unknown> = [], b: Array<unknown> = []) =>
	a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]));

interface FallbackProps {
	error: Error;
	resetErrorBoundary: (...args: Array<unknown>) => void;
}

interface ErrorBoundaryPropsWithComponent {
	onResetKeysChange?: (
		prevResetKeys: Array<unknown> | undefined,
		resetKeys: Array<unknown> | undefined,
	) => void;
	onReset?: (...args: Array<unknown>) => void;
	onError?: (error: Error, info: { componentStack: string }) => void;
	resetKeys?: Array<unknown>;
	FallbackComponent: ComponentType<FallbackProps>;
}

declare function FallbackRender(
	props: FallbackProps,
): ReactElement<unknown, string | FunctionComponent | typeof Component> | null;

interface ErrorBoundaryPropsWithRender {
	onResetKeysChange?: (
		prevResetKeys: Array<unknown> | undefined,
		resetKeys: Array<unknown> | undefined,
	) => void;
	onReset?: (...args: Array<unknown>) => void;
	onError?: (error: Error, info: { componentStack: string }) => void;
	resetKeys?: Array<unknown>;
	fallbackRender: typeof FallbackRender;
}

interface ErrorBoundaryPropsWithFallback {
	onResetKeysChange?: (
		prevResetKeys: Array<unknown> | undefined,
		resetKeys: Array<unknown> | undefined,
	) => void;
	onReset?: (...args: Array<unknown>) => void;
	onError?: (error: Error, info: { componentStack: string }) => void;
	resetKeys?: Array<unknown>;
	fallback: ReactElement<unknown, string | FunctionComponent | typeof Component> | null;
}

type ErrorBoundaryProps =
	| ErrorBoundaryPropsWithFallback
	| ErrorBoundaryPropsWithComponent
	| ErrorBoundaryPropsWithRender;

type ErrorBoundaryState = { error: Error | null };

const initialState: ErrorBoundaryState = { error: null };

class ErrorBoundary extends Component<
	PropsWithRef<PropsWithChildren<ErrorBoundaryProps>>,
	ErrorBoundaryState
> {
	static getDerivedStateFromError(error: Error) {
		return { error };
	}

	updatedWithError = false;

	constructor(props: any) {
		super(props);
		this.state = initialState;
	}

	componentDidMount() {
		const { error } = this.state;

		if (error !== null) {
			this.updatedWithError = true;
		}
	}

	componentDidUpdate(prevProps: ErrorBoundaryProps) {
		const { error } = this.state;
		const { resetKeys } = this.props;

		// There's an edge case where if the thing that triggered the error
		// happens to *also* be in the resetKeys array, we'd end up resetting
		// the error boundary immediately. This would likely trigger a second
		// error to be thrown.
		// So we make sure that we don't check the resetKeys on the first call
		// of cDU after the error is set
		if (error !== null && !this.updatedWithError) {
			this.updatedWithError = true;
			return;
		}

		if (error !== null && changedArray(prevProps.resetKeys, resetKeys)) {
			this.props.onResetKeysChange?.(prevProps.resetKeys, resetKeys);
			this.reset();
		}
	}

	componentDidCatch(error: Error, info: ErrorInfo) {
		this.props.onError?.(error, info);
	}

	resetErrorBoundary = (...args: Array<unknown>) => {
		this.props.onReset?.(...args);
		this.reset();
	};

	reset() {
		this.updatedWithError = false;
		this.setState(initialState);
	}

	render() {
		const { error } = this.state;
		// @ts-expect-error ts(2339) (at least one of these will be defined though, and we check for their existence)
		const { fallbackRender, FallbackComponent, fallback } = this.props;

		if (error !== null) {
			const props = {
				error,
				resetErrorBoundary: this.resetErrorBoundary,
			};
			if (isValidElement(fallback)) {
				return fallback;
			}
			if (typeof fallbackRender === 'function') {
				return (fallbackRender as typeof FallbackRender)(props);
			}
			if (FallbackComponent) {
				return <FallbackComponent {...props} />;
			}
			throw new Error(
				'react-error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop',
			);
		}

		return this.props.children;
	}
}

function withErrorBoundary<T extends ComponentType>(
	Component: ComponentType<T>,
	errorBoundaryProps: ErrorBoundaryProps,
): ComponentType<T> {
	const Wrapped: ComponentType<T> = (props) => {
		return (
			<ErrorBoundary {...errorBoundaryProps}>
				<Component {...props} />
			</ErrorBoundary>
		);
	};

	// Format for display in DevTools
	const name = Component.displayName || Component.name || 'Unknown';
	Wrapped.displayName = `withErrorBoundary(${name})`;

	return Wrapped;
}

function useErrorHandler<P = Error>(
	givenError?: P | null | undefined,
): Dispatch<SetStateAction<P | null>> {
	const [error, setError] = useState<P | null>(null);
	if (givenError) throw givenError;
	if (error) throw error;
	return setError;
}

export { ErrorBoundary, withErrorBoundary, useErrorHandler };
export type {
	FallbackProps,
	ErrorBoundaryPropsWithComponent,
	ErrorBoundaryPropsWithRender,
	ErrorBoundaryPropsWithFallback,
	ErrorBoundaryProps,
};
export { default as ErrorFallback } from './ErrorFallback';
