import React, { useContext, useState } from 'react';

const initialState: any[] = [null, null];

const SelectVesselContext = React.createContext(initialState);

const SelectVesselProvider = ({ children }: { children?: any }) => {
	const [selectedVessel, setSelectedVessel] = useState({
		id: null,
		coordinates: null,
		matchmaking: false,
	});
	return (
		<SelectVesselContext.Provider value={[selectedVessel, setSelectedVessel]}>
			{children}
		</SelectVesselContext.Provider>
	);
};

export function useSelectVessel() {
	const context = useContext(SelectVesselContext);
	if (context === undefined) throw Error('You forgot to wrap your app with <MapProvider />');
	return context;
}

export const useSelectVesselContext = () => useContext(SelectVesselContext);

export { SelectVesselProvider, SelectVesselContext };
