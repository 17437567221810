import { ThemeOptions } from '@mui/material';

import type {} from '@mui/x-date-pickers/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';

import { neutral, text, divider } from './colors';
export const components: ThemeOptions['components'] = {
	MuiDateRangePickerDay: {
		styleOverrides: {
			day: {
				':disabled': {
					opacity: '0.4',
				},
			},
		},
	},
	MuiAvatar: {
		styleOverrides: {
			root: {
				backgroundColor: neutral[800],
				color: '#FFFFFF',
			},
		},
	},
	MuiChip: {
		styleOverrides: {
			root: {
				'&.MuiChip-filledDefault': {
					backgroundColor: neutral[200],
					'& .MuiChip-deleteIcon': {
						color: neutral[400],
					},
				},
				'&.MuiChip-outlinedDefault': {
					'& .MuiChip-deleteIcon': {
						color: neutral[300],
					},
				},
			},
		},
	},
	MuiInputBase: {
		styleOverrides: {
			input: {
				'&::placeholder': {
					opacity: 1,
					color: text.secondary,
				},
			},
		},
	},
	MuiOutlinedInput: {
		styleOverrides: {
			notchedOutline: {
				borderColor: divider,
			},
		},
	},
	MuiMenu: {
		styleOverrides: {
			paper: {
				borderColor: divider,
				borderStyle: 'solid',
				borderWidth: 1,
			},
		},
	},
	MuiPopover: {
		styleOverrides: {
			paper: {
				borderColor: divider,
				borderStyle: 'solid',
				borderWidth: 1,
			},
		},
	},
	MuiSwitch: {
		styleOverrides: {
			switchBase: {
				color: neutral[500],
			},
			track: {
				backgroundColor: neutral[400],
				opacity: 1,
			},
		},
	},
	MuiTableCell: {
		styleOverrides: {
			root: {
				borderBottom: `1px solid ${divider}`,
			},
		},
	},
	MuiTableHead: {
		styleOverrides: {
			root: {
				backgroundColor: neutral[100],
				'.MuiTableCell-root': {
					color: neutral[700],
				},
			},
		},
	},
};
