import { gql } from 'graphql-request';

export const CREATE_VESSEL = gql`
	mutation ($input: CreateVesselInput!) {
		createVessel(input: $input) {
			message
			data {
				_id
				callSign
				imoNumber
				registerNumber
				name
				email
				status
				dimension {
					overallLength
					depth
					length
					width
				}
				specification {
					vesselType
					constructionDate
					country
					jnr
					material
					propulsion
				}
				additionalInfo {
					sideDoor
					equippedWithCrane
					numberOfCranes
					craneDetail
				}
				createdAt
				updatedAt
			}
		}
	}
`;

export const UPDATE_VESSEL = gql`
	mutation ($input: UpdateVesselInput!) {
		updateVessel(input: $input) {
			message
			data {
				_id
				callSign
				imoNumber
				registerNumber
				name
				email
				status
				dimension {
					overallLength
					depth
					length
					width
				}
				specification {
					vesselType
					constructionDate
					country
					jnr
					material
					propulsion
				}
				additionalInfo {
					sideDoor
					equippedWithCrane
					numberOfCranes
					craneDetail
				}
				createdAt
				updatedAt
			}
		}
	}
`;

export const DELETE_VESSEL = gql`
	mutation ($input: String!) {
		deleteVessel(_id: $input) {
			message
		}
	}
`;
