import { ThemeOptions } from '@mui/material';

import { typography } from './variables/baseTypography';
import { components } from './variables/baseComponents';

export const baseThemeOptions: ThemeOptions = {
	typography,
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1000,
			lg: 1280,
			xl: 1920,
		},
	},
	components,
	direction: 'ltr',
	shape: {
		borderRadius: 8,
	},
	zIndex: {
		appBar: 1100,
		drawer: 1101,
	},
};
