// eslint-disable-next-line
// @ts-nocheck
import { useState } from 'react';
import { z } from 'zod';
import jwtDecode from 'jwt-decode';
import { Modal } from '@/components/modal';
import { ControlledField, useRHForm } from '@/components/form';
import { Typography, CardActionArea, CircularProgress, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import { CONFIG } from '@/config';
import ShipperImage from '@/assets/demo/shipper_card.png';
import CarrierImage from '@/assets/demo/carrier_card.png';
import TextLabel from '@/components/atomic/TextLabel';
import { MdWarning } from 'react-icons/md';
import Button from '@/components/Button';

const CardButton = ({ text, title, onClick, image, altText }: any) => {
	return (
		<Card sx={{ maxWidth: 345 }} role='button' onClick={onClick}>
			<CardActionArea>
				<div className='px-6 py-6'>
					<img src={image} className='w-full h-[160px] object-contain mt-4' alt={altText} />
					<Typography gutterBottom variant='h5' className='text-center'>
						{title}
					</Typography>
					<Typography variant='body2' color='text.secondary' className='text-center'>
						{text}
					</Typography>
				</div>
			</CardActionArea>
		</Card>
	);
};

export const fetchToken = async (role: 'shipper' | 'carrier', setLoading, setFailedTokenFetch) => {
	const FRONTPAGE_URL = `${window.location.origin}/app/map`;
	setLoading(true);
	localStorage.setItem('demoToken', Date.now());
	const response = await fetch(`${CONFIG.API_V2}/demo-token/${role}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	}).then((response) => response.json());

	if (response?.access_token) {
		const { access_token, expires_in, id_token, scope, token_type } = response;
		const decodedUser = jwtDecode(id_token);
		const { iss, aud, iat, exp, ...rest } = decodedUser;
		const decodedAccessToken = jwtDecode(response.access_token);
		const parts = id_token.split('.');

		const namespace = '@@auth0spajs@@';
		const clientId = CONFIG.AUTH0_CLIENT_ID;
		const audience = 'https://demo.keelspot.com';
		const key = `${namespace}::${clientId}::${audience}::${scope} offline_access`;

		const value = {
			body: {
				access_token: access_token,
				id_token: id_token,
				scope: scope,
				expires_in: expires_in,
				token_type,
				decodedToken: {
					encoded: {
						header: parts[0],
						payload: parts[1],
						signature: parts[2],
					},
					header: JSON.parse(atob(parts[0])),
					claims: {
						__raw: id_token,
						...decodedUser,
						nonce: 'N1AxazMxOS1SclZDVDh0UX5jQUdKSU1uTVpSQWFkWmQzV3JsTmd5a2pnNg==',
					},
					user: {
						iss: 'https://keelspot-demo.eu.auth0.com/',
						...rest,
					},
				},
				audience: 'https://demo.keelspot.com/',
				oauthTokenScope: 'openid profile email',
				client_id: '5J2kfE3iy2FawPvUxuBVvbUQXdvUsfQN',
			},
			expiresAt: Date.now() + 867000 + expires_in, //1914738825, // XXX
		};
		const localStorage = [
			{
				key,
				value: await JSON.stringify(value),
			},
			{
				key: 'hasAuthToken',
				value: 'true',
			},
		];
		localStorage.forEach((item) => {
			window.localStorage.setItem(item.key, item.value);
		});
		window.location.href = FRONTPAGE_URL;
	} else {
		console.error('Failed to fetch token');
		setFailedTokenFetch(true);
	}
};

const RequireEmail = ({ emailProvided, setEmailProvided }: any) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const {
		Form,
		methods: { control },
	} = useRHForm({
		initialValues: {
			email: '',
		},
		schema: z.object({
			email: z
				.string()
				.email('Invalid email format')
				.refine(
					(email) => {
						const domain = email.split('@')[1];
						const disallowedDomains = ['gmail.com', 'yahoo.com', 'hotmail.com'];
						return !disallowedDomains.includes(domain);
					},
					{ message: 'Please use a company email address' },
				),
		}),
	});
	return (
		<Modal title={' '} isOpen={!emailProvided} dialogAction={false}>
			<Form
				onSubmit={async ({ email }) => {
					// store to local storage?
					setIsSubmitting(true);
					await fetch(`${CONFIG.API_V2}/demo/lead`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ email }),
					}).then((res) => res.json());

					setIsSubmitting(false);
					setEmailProvided(true);
				}}
			>
				<div className='flex flex-col gap-6 px-4 mb-12 pt-2 items-center'>
					<div className='flex flex-col gap-2 items-center'>
						<Typography variant='h4' className='text-center'>
							Thanks for trying Keelspot!
						</Typography>
						<span className='text-keelspot-text-secondary text-center text-xl'>
							Please provide your company email to continue.
						</span>
					</div>
					<div className='flex flex-col gap-1 items-center w-full max-w-[260px]'>
						<ControlledField
							control={control}
							name='email'
							Component={TextField}
							componentProps={{
								label: 'Your email',
								fullWidth: true,
							}}
						/>
						<Button
							startIcon={isSubmitting ? <CircularProgress size={24} /> : null}
							size='large'
							className='w-full'
							type='submit'
						>
							Continue
						</Button>
					</div>
				</div>
			</Form>
		</Modal>
	);
};

const DemoFrontpage = () => {
	const [loading, setLoading] = useState(false);
	const [failedTokenFetch, setFailedTokenFetch] = useState(false);
	const [emailProvided, setEmailProvided] = useState(false);

	const loginAsCarrier = async () => {
		// Set the local storage to the shipper role
		fetchToken('carrier', setLoading, setFailedTokenFetch);
	};
	const loginAsShipper = async () => {
		// Set the local storage to the shipper role
		fetchToken('shipper', setLoading, setFailedTokenFetch);
	};

	if (failedTokenFetch) {
		return (
			<div className='flex items-center justify-center'>
				<TextLabel
					size='md'
					text='Failed to fetch token. Please try again later.'
					color='error'
					icon={<MdWarning size={20} />}
				/>
			</div>
		);
	}
	if (loading) {
		return (
			<div className='flex items-center justify-center'>
				<CircularProgress />
			</div>
		);
	}
	return (
		<div className='flex flex-col items-center gap-2 max-w-[800px] px-8 mx-auto'>
			<RequireEmail emailProvided={emailProvided} setEmailProvided={setEmailProvided} />
			<Typography variant='h4' className='text-center'>
				How would you like to try Keelspot?
			</Typography>
			<div className='flex gap-4 justify-center flex-wrap my-8'>
				<CardButton
					title='Try as a shipper'
					text='As a shipper, you can post and manage your freight requests, find vessels for your cargo, negotiate offers and manage your deliveries.'
					image={CarrierImage}
					alt='Illustration of a shipper'
					onClick={loginAsShipper}
				/>
				<CardButton
					title='Try as a carrier'
					text='As a carrier, you can find cargo for your vessels, submit offers the shippers and announce the available capacity of your vessels.'
					image={ShipperImage}
					alt='Illustration of a carrier'
					onClick={loginAsCarrier}
				/>
			</div>
			<TextLabel
				size='md'
				text='In the demo, request data is just for example purposes. The system is reset every 24 hours.'
				color='primary'
				icon={<MdWarning size={20} />}
			/>
		</div>
	);
};

export default DemoFrontpage;
