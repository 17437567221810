// eslint-disable-next-line
// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom/client';
import { CONFIG } from '@/config';
import * as Sentry from '@sentry/react';
import App from './App';

let container: any = null;
let sentryLoaded = false;

document.addEventListener('DOMContentLoaded', function () {
	if (!container) {
		if (!sentryLoaded) {
			// Sentry
			Sentry.init({
				dsn: 'https://e2cdf7ab994582f73e0c664158b7620e@o4505781645934592.ingest.sentry.io/4505781649211392',
				environment: CONFIG.APP_ENV,
				integrations: [
					new Sentry.BrowserTracing({
						// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
						tracePropagationTargets: [
							'localhost',
							'https://staging.keelspot.com',
							'https://app.keelspot.com',
						],
					}),
					new Sentry.Replay(),
				],
				// Performance Monitoring
				tracesSampleRate: 0.2, // Capture 0-100% of the transactions, reduce in production!
				// Session Replay
				replaysSessionSampleRate: CONFIG.APP_ENV === 'production' ? 1.0 : 0.0,
				replaysOnErrorSampleRate: CONFIG.APP_ENV === 'production' ? 1.0 : 0.0,
			});
			sentryLoaded = true;
		}

		container = document.getElementById('root') as HTMLElement;
		const root = ReactDOM.createRoot(container);
		root.render(
			<React.StrictMode>
				<App />
			</React.StrictMode>,
		);
	}
});
