import {
	MutationFunction,
	UseMutationOptions,
	useMutation,
	useQueryClient,
} from '@tanstack/react-query';

import { useClient } from '@context/GraphqlRequestContext';
import { RequestOptions } from 'graphql-request';

const useGenericMutation = <Variables = any, MutationResponse = unknown, MutationError = unknown>(
	dependentQueries: Array<unknown>,
	func: MutationFunction<MutationResponse, Variables>,
	config: Omit<
		UseMutationOptions<MutationResponse, MutationError, Variables, unknown>,
		'onError' | 'onSettled'
	> = {},
) => {
	const queryClient = useQueryClient();

	return useMutation<MutationResponse, MutationError, Variables>(func, {
		onError: (__, _, context) => {
			queryClient.setQueryData(dependentQueries, context);
		},
		onSettled: () => {
			queryClient.invalidateQueries(dependentQueries);
		},
		...config,
	});
};

export const useMutations = <
	Variables = any,
	MutationResponse extends object = any,
	MutationError extends object = any,
>(
	dependentQueries: Array<unknown>,
	query: string,
	config?: Omit<
		UseMutationOptions<MutationResponse, MutationError, Variables, unknown>,
		'onError' | 'onSettled'
	>,
	clientType: 'public' | 'private' = 'private',
	requestConfig?: {
		signal?: AbortSignal;
	},
) => {
	const { client, publicClient } = useClient();
	const queryClient = clientType === 'private' ? client : publicClient;

	return useGenericMutation<Variables, MutationResponse, MutationError>(
		dependentQueries,
		async (variables: Variables) => {
			const { data } = await queryClient.rawRequest<MutationResponse, Variables>({
				query: query,
				signal: requestConfig?.signal as RequestOptions['signal'],
				variables,
			});
			return data;
		},
		config,
	);
};
