// @ts-nocheck

import { useTheme } from '@mui/material/styles';
import { Button, Container, Typography, useMediaQuery } from '@mui/material';

import { useTranslation } from 'react-i18next';

interface FallbackProps {
	error?: Error;
	resetErrorBoundary?: (...args: Array<unknown>) => void;
}

export default function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
	const { t } = useTranslation();

	const theme = useTheme();
	const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

	console.log(error);

	// Don't mess with this unless you've got a better solution.
	// The better solution is getting rid of all of the bad dynamic imports (./)
	// or something else.
	// In the meantime - hard reload if we get a bad dynamic import.
	if (
		!window.location.hash.includes('er') &&
		error?.message.includes('dynamically imported module')
	) {
		window.location = `${window.location}#er`;
		window.location.reload(true);
	}

	return (
		<div className='flex flex-col gap-4 p-4'>
			<Container maxWidth='lg'>
				<Typography align='center' variant={mobileDevice ? 'h4' : 'h1'}>
					{t('errorFallback.heading')}
				</Typography>
				<Typography align='center' color='textSecondary' variant='subtitle2'>
					{error?.message}
				</Typography>
				<Typography align='center' color='textSecondary' variant='subtitle2'>
					{error?.stack}
				</Typography>
				<div className='flex align-center justify-center'>
					<img
						className='w-full max-w-[400px]'
						alt='something went wrong'
						src={`/static/error/error500_${theme.palette.mode}.svg`}
					/>
				</div>
				<div className='flex align-center justify-center'>
					<Button component='a' variant='outlined' onClick={resetErrorBoundary}>
						{t('errorFallback.buttonText')}
					</Button>
				</div>
			</Container>
		</div>
	);
}
