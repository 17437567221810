import { UserMeta } from '@/components/Chat/Chat';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';
import React, { useContext, useEffect, useState } from 'react';
import Talk from 'talkjs';

let session: Talk.Session | undefined;

const TalkSesionContext = React.createContext(session);

const TalkSessionProvider = ({ children }: { children?: any }) => {
	const { currentUser } = useGetCurrentUser();
	const { session } = useTalk(currentUser);

	function useTalk(loggedInUser: UserMeta | undefined) {
		const [session, setSession] = useState<Talk.Session>();

		useEffect(() => {
			if (!loggedInUser) return;
			const currentUser = new Talk.User(loggedInUser);
			setSession(
				new Talk.Session({
					appId: 'te04k3i6',
					me: currentUser,
				}),
			);
		}, [loggedInUser]);

		return { session };
	}
	return <TalkSesionContext.Provider value={session}>{children}</TalkSesionContext.Provider>;
};

export const useTalkSessionContext = () => useContext(TalkSesionContext);

export { TalkSessionProvider, TalkSesionContext };
