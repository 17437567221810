import { Fragment } from 'react';

type Props = {
	subTitle: string | React.ReactNode;
	title: string | React.ReactNode;
	preTitle?: string | React.ReactNode;
	postTitle?: string | React.ReactNode;
};

export const SpecificationText = ({ subTitle, title, preTitle, postTitle }: Props) => (
	<div className='flex flex-col'>
		<span className='text-keelspot-text-secondary text-sm'>{subTitle}</span>
		<span className='text-keelspot-text-primary font-semibold text-xl md:text-2xl leading-none'>
			{preTitle && <small className='text-sm pr-1'>{preTitle}</small>}
			{title}
			{postTitle && <small className='text-sm pl-1'>{postTitle}</small>}
		</span>
	</div>
);

export const SpecificationTextGroup = ({ items }: { items: Props[] }) => (
	<div className='flex flex-col md:flex-row gap-4 md:gap-6 md:items-stretch'>
		{items.map(({ subTitle, ...rest }, index) => (
			<Fragment key={`spec-group-text-${index}`}>
				{index > 0 ? <div className='w-[1px] bg-keelspot-border-light' /> : null}
				<SpecificationText subTitle={subTitle} {...rest} />
			</Fragment>
		))}
	</div>
);
