import { CustomTheme } from '@/theme/Theme';
import { Typography } from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { PropsWithChildren, memo } from 'react';

const MapLabelBase = ({ children }: PropsWithChildren) => {
	const theme = useTheme<typeof CustomTheme>();
	return (
		<Box
			sx={{
				background: theme.palette.snackbar,
				color: theme.palette.error?.contrastText,
				borderRadius: '10px',
				paddingLeft: '8px',
				paddingRight: '8px',
				paddingTop: '2px',
				paddingBottom: '2px',
				marginBottom: '4px',
			}}
		>
			<Typography color='inherit' fontWeight={500} fontSize='12px' lineHeight='18px'>
				{children}
			</Typography>
		</Box>
	);
};

export const MapLabel = memo(MapLabelBase);
