import React, { useContext, useState } from 'react';

const initialState: any[] = [null, null];

const MapDrawerContext = React.createContext(initialState);

const MapDrawerProvider = ({ children }: { children?: any }) => {
	const [currentTab, setCurrentTab] = useState(0);
	const [freightId, setFreightId] = useState();
	const freightHover = { freightId, setFreightId };
	return (
		<MapDrawerContext.Provider value={[currentTab, setCurrentTab, freightHover]}>
			{children}
		</MapDrawerContext.Provider>
	);
};

export function useMapDrawer() {
	const context = useContext(MapDrawerContext);
	if (context === undefined) throw Error('You forgot to wrap your app with <MapDrawerProvider />');
	return context;
}

export const useMapDrawerContext = () => useContext(MapDrawerContext);

export { MapDrawerProvider, MapDrawerContext };
