import { toast } from 'react-toastify';
import { QueryClient, MutationCache, QueryCache } from '@tanstack/react-query';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 5 * 1000,
			refetchOnWindowFocus: false,
			retry: 1,
		},
	},
	mutationCache: new MutationCache({
		onError: (error: any) => {
			const errors = error?.response?.errors;
			if (errors?.length) {
				errors.map((err: any) => toast.error(err?.message || 'Internal Server Error'));
			}
		},
	}),
	queryCache: new QueryCache({
		onError: (error: any, query) => {
			if (query.state.data !== undefined) {
				//toast.error(`Internal server error: ${error?.message}`);
			}
		},
	}),
});
export default queryClient;
