import { IAttachment } from '@/interfaces/IAttachment';
import { gql } from 'graphql-request';

export const UPLOAD_MEDIA = gql`
	mutation multipleMediaUpload($file: [MediaInput]!) {
		multipleMediaUpload(file: $file) {
			message
			data {
				_id
				originalName
				path
				mimetype
			}
		}
	}
`;

export const DELETE_MEDIA = gql`
	mutation deleteMedia($id: String!) {
		deleteMedia(_id: $id) {
			message
		}
	}
`;

export interface IUPLOAD_MEDIA_RESPONSE {
	multipleMediaUpload: {
		message: string;
		data: Array<IAttachment>;
	};
}

export interface IDELETE_MEDIA_RESPONSE {
	deleteMedia: {
		message: string;
	};
}
