import { Tooltip } from '@mui/material';
import { MdVerified } from 'react-icons/md';
import { twMerge } from 'tailwind-merge';

export const BlueCheck = ({
	className,
	size,
	isBluecheck,
}: {
	className?: string;
	size?: number;
	isBluecheck: boolean;
}) => {
	if (!isBluecheck) {
		return null;
	}
	return (
		<Tooltip title={'Company verified by Keelspot'} arrow>
			<MdVerified
				size={size || 20}
				tabIndex={0}
				className={twMerge(
					'text-keelspot-primary-main inline-flex items-center cursor:hover-pointer pl-1',
					className,
				)}
			/>
		</Tooltip>
	);
};
