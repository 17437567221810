import i18n from 'i18next';

export function getInitials(str: string) {
	return (
		str
			.split(' ')
			.map((n) => n[0])
			.join('')
			.toUpperCase() ?? i18n.t('common.NA')
	);
}

export function getPluralWordIfNecessary(word: string, isPlural: boolean) {
	if (isPlural) return `${word}s`;
	return word;
}

export function clusterList(list: string[]) {
	if (!list) {
		return '';
	}

	const formattedList = [];
	let currentElement = list[0];
	let count = 1;

	for (let i = 1; i < list.length; i++) {
		if (list[i] === currentElement) {
			count++;
		} else {
			formattedList.push(`${currentElement}${count > 1 ? ` (${count})` : ''}`);
			currentElement = list[i];
			count = 1;
		}
	}
	formattedList.push(`${currentElement}${count > 1 ? ` (${count})` : ''}`);
	return formattedList.join(', ');
}
