import { gql } from 'graphql-request';

const PORT_ADDRESS_RESPONSE = `
	uuid
	portName
	countryIso
	countryName
	unlocode
	portType
	geographic {
		type
		coordinates
	}
	areaLvl1
	areaLvl2
	state
	city
	municipality
	postcode
	type
`;

export const SEARCH_PORTS = gql`
	query portFinder($name: String!) {
		portFinder(name: $name) {
			message
			data {
				uuid
				portName
				countryIso
				countryName
				unlocode
				portType
				geographic {
					coordinates
					type
				}
			}
		}
	}
`;

export const SEARCH_PORTS_AND_ADDRESS = gql`
	query portFinderGeoapifyMerge($name: String!, $type: String) {
		portFinderGeoapifyMerge(name: $name, type: $type) {
			message
			data {
				quays {
					_id
					locationName
					countryIso
					countyName
					locationName
					unlocode
					geographic {
						type
						coordinates
					}
					type
				}
				quayPorts {
					uuid
					portName
					countryIso
					countryName
					unlocode
					portType
					geographic {
						type
						coordinates
					}
					areaLvl1
					areaLvl2
					state
					city
					municipality
					postcode
					type
				}
				addresses {
					${PORT_ADDRESS_RESPONSE}
				}
				ports {
					${PORT_ADDRESS_RESPONSE}
				}
			}
		}
	}
`;

export const VESSEL_SEARCH = gql`
	query vesselSearch($imoNumber: String!) {
		vesselSearch(imoNumber: $imoNumber) {
			message
			data {
				imoNumber
				callSign
				name
				registerNumber
				dimension {
					length
					width
					depth
					grossTonnage
					netTonnage
				}
				specification {
					vesselType
					material
					propulsion
					constructionDate
					country
				}
				vesselPosition {
					latitude
					longitude
					course
				}
			}
		}
	}
`;
