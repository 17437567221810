export const RequestFreightIcon = ({
	active,
	className,
}: {
	active: boolean;
	className: string;
}) => {
	const primaryBlue = '#0C84DB';
	const darkBlue = '#003380';
	const grey = '#777675';
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='52'
			height='53'
			viewBox='0 0 52 53'
			fill='none'
			className={className}
		>
			<path
				d='M29.0678 4.2959H23.4648V8.3909H29.0678V4.2959Z'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M23.3218 16.8406C23.3218 18.5306 24.7388 19.8826 26.4418 19.7656C27.9108 19.6746 29.0938 18.4786 29.1848 17.0226C29.2628 15.8136 28.5998 14.7476 27.6118 14.2276C26.7668 13.7856 26.2598 12.9016 26.2598 11.9526V8.39062'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.5918 25.4336H44.6808'
				stroke={active ? darkBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M7.5918 46.3506H44.6808'
				stroke={active ? darkBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M9.38574 46.3513V25.4473L19.4737 18.5703'
				stroke={active ? darkBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M42.8998 46.3513V25.4473L32.7988 18.5703'
				stroke={active ? darkBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M37.3228 30.6338V41.1638'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M31.7979 30.6338V41.1638'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M26.2729 30.6338V41.1638'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M20.7607 30.6338V41.1638'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M15.2358 30.6338V41.1638'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
