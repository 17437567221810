/* eslint-disable react/display-name */
import { Suspense } from 'react';
import { CircularProgress } from '@mui/material';

export const Loadable = (Component: React.ComponentType<any>) => (props: JSX.IntrinsicAttributes) =>
	(
		<Suspense
			fallback={
				<div className='flex items-center justify-center w-full h-full'>
					<CircularProgress />
				</div>
			}
		>
			<Component {...props} />
		</Suspense>
	);
