import PDFThumb from '@/assets/images/pdf-thumb.png';
import EXCELThumb from '@/assets/images/excel-thumb.png';

export const isExcelFile = (file: any) => {
	return (
		file?.mimetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
		file?.mimetype === 'application/vnd.ms-excel'
	);
};

export const isPDFFile = (file: any) => {
	return file?.mimetype === 'application/pdf';
};

export const thumbnailForFile = (file: any) => {
	if (isPDFFile(file)) {
		return PDFThumb;
	}
	if (isExcelFile(file)) {
		return EXCELThumb;
	}
	return file?.fullPath || file?.path;
};
