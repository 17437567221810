import React, { useCallback, useState, useMemo } from 'react';
import { Control, Controller, FieldValues, Path, PathValue, useController } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import {
	Box,
	IconButton,
	Typography,
	TextField,
	InputAdornment,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material';

import { MdClose, MdOutlineFileUpload } from 'react-icons/md';
import { FileUploadPreview, FileUploadPreviewImageWrapper } from './Styled';

import { useTranslation } from 'react-i18next';
import { IAttachment } from '@/interfaces/IAttachment';
import { thumbnailForFile } from '@/utils/fileType';

const GLOBAL_FILE_COUNT_LIMIT = 50;

function UploadInput<Schema extends FieldValues>({
	limit,
	multiple,
	name,
	control,
	attachments,
	loading = false,
	accept = 'image/jpg, image/png, image/jpeg, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	onRemoveAttachment,
	hideTextField,
}: {
	limit: number;
	multiple: boolean;
	name: Path<Schema>;
	control: Control<Schema>;
	attachments?: IAttachment[];
	loading?: boolean;
	updateValue?: any;
	accept?: string;
	onRemoveAttachment: (attachment: IAttachment) => void;
	hideTextField?: boolean;
}) {
	const {
		field,
		fieldState: { error },
	} = useController({ name, control });
	const { t } = useTranslation();
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [attachmentToRemove, setAttachmentToRemove] = useState<null | IAttachment>(null);
	const [componentIdPrefix] = useState(() => `upload-input-${Math.random()}`);
	const [uploadError, setUploadError] = useState<string>('');

	const fileCountLimit = useMemo(() => {
		return Math.min(GLOBAL_FILE_COUNT_LIMIT, limit);
	}, [limit]);

	const errorMsg = error?.message || '';

	const handleOnChange = useCallback(
		(e: React.SyntheticEvent<EventTarget>) => {
			setUploadError('');
			const target = e.target as HTMLInputElement;
			if (!target.files) return;

			const newFiles = Object.values(target.files);
			const currentFileList = field.value || [];
			const updatedFileList = [...currentFileList, ...newFiles];
			const attachmentsCount = attachments?.length || 0;
			if (attachmentsCount + updatedFileList.length > fileCountLimit) {
				setUploadError(
					t('common.uploadInput.limitExceeded', { count: fileCountLimit }) ||
						`The files shoud not be more than ${fileCountLimit}`,
				);
			} else {
				field.onChange(updatedFileList);
			}
			target.value = '';
		},
		[fileCountLimit, t, field, attachments],
	);

	const textInputLabelText = useMemo(() => {
		const count = attachments?.length || 0;
		if (!count) {
			return t('common.uploadInput.attachFile');
		}
		return t('common.uploadInput.attachedFiles', { count: attachments?.length || 0 });
	}, [t, attachments]);

	const handleOnRemoveClick = useCallback((attachment: IAttachment) => {
		return () => {
			setAttachmentToRemove(attachment);
			setConfirmModalOpen(true);
		};
	}, []);

	const handleConfirmModalAccept = useCallback(() => {
		if (attachmentToRemove) {
			onRemoveAttachment(attachmentToRemove);
		}
		setConfirmModalOpen(false);
	}, [attachmentToRemove, onRemoveAttachment]);

	return (
		<>
			{!hideTextField && (
				<TextField
					fullWidth
					className='pt-6 pb-4'
					value={textInputLabelText}
					//disabled
					type='text'
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								{loading ? (
									<CircularProgress color='inherit' size={20} />
								) : (
									<IconButton aria-label='upload' component='label'>
										<Controller
											name={name}
											defaultValue={'' as PathValue<Schema, Path<Schema>>}
											control={control}
											render={({ field: { name, onBlur, ref } }) => (
												<input
													type='file'
													accept={accept}
													className='opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer'
													onBlur={onBlur}
													name={name}
													multiple={multiple}
													ref={ref}
													onChange={handleOnChange}
												/>
											)}
										/>
										<MdOutlineFileUpload />
									</IconButton>
								)}
							</InputAdornment>
						),
					}}
					error={!!error || !!uploadError}
					helperText={errorMsg || uploadError}
				/>
			)}
			{attachments && attachments?.length > 0 ? (
				<div className='flex gap-4 flex-wrap'>
					{attachments.map((item) => {
						const fileUrl = thumbnailForFile(item);

						return (
							<FileUploadPreview key={item._id}>
								<Box className='flex gap-2.5 items-center justify-between'>
									<Typography variant='subtitle2' className='truncate'>
										{item.originalName}
									</Typography>
									<div>
										{loading ? (
											<CircularProgress className='cursor-wait' size={24} />
										) : (
											<MdClose
												color='#BFBEBD'
												size={24}
												onClick={handleOnRemoveClick(item)}
												className='cursor-pointer min-w-[24px]'
											/>
										)}
									</div>
								</Box>
								<FileUploadPreviewImageWrapper>
									<img src={fileUrl} alt='upload' />
								</FileUploadPreviewImageWrapper>
							</FileUploadPreview>
						);
					})}
				</div>
			) : null}

			<Dialog
				open={confirmModalOpen}
				onClose={() => {
					setConfirmModalOpen(false);
				}}
				aria-labelledby={`${componentIdPrefix}-title`}
				aria-describedby={`${componentIdPrefix}-description`}
			>
				<DialogTitle id={`${componentIdPrefix}-title`}>
					{t('common.uploadInput.attachments.removeTitleConfirmation')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id={`${componentIdPrefix}-description`}>
						{t('common.uploadInput.attachments.removeTextConfirmation')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setConfirmModalOpen(false);
						}}
					>
						{t('common.cancel')}
					</Button>
					<Button onClick={handleConfirmModalAccept}>{t('common.remove')}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default UploadInput;
