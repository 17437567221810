export const OfferVesselIcon = ({ active, className }: { active: boolean; className: string }) => {
	const primaryBlue = '#0C84DB';
	const darkBlue = '#003380';
	const grey = '#777675';
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='52'
			height='53'
			viewBox='0 0 52 53'
			fill='none'
			className={className}
		>
			<path
				d='M10.8057 28.3564L29.4204 28.3564L29.4204 16.9608L10.8057 16.9608L10.8057 28.3564Z'
				stroke={active ? darkBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M24.4692 21.2529V24.0189'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M30 32.499V35.3064'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M34.6338 32.499V35.3064'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M15.811 32.499V35.3064'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M20.4434 32.499V35.3064'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M39.5288 32.501V33.5935'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M11.0542 32.499V33.5916'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M20.1133 21.2529V24.0189'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M15.7568 21.2529V24.0189'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M39.1965 46.9748L47.3975 37.1973H38.2837L35.5593 39.8664H13.5563L10.6106 37.1973H4V40.3228C4 43.1578 5.78403 45.578 8.28719 46.5461'
				stroke={active ? darkBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M42.0594 48.3585C38.9754 48.3585 38.9754 45.0947 35.8914 45.0947C32.8074 45.0947 32.8074 48.3585 29.7234 48.3585C26.6393 48.3585 26.6393 45.0947 23.5553 45.0947C20.4713 45.0947 20.4713 48.3585 17.3735 48.3585C14.2895 48.3585 14.2895 45.0947 11.1916 45.0947C8.10761 45.0947 8.10761 48.3585 5.00977 48.3585'
				stroke={active ? primaryBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M43.9548 37.1837V28.3604H6.44873V37.1837'
				stroke={active ? darkBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M25.2017 28.3604V39.8528'
				stroke={active ? darkBlue : grey}
				strokeWidth='3'
				strokeMiterlimit='10'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};
