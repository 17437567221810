import { CustomTheme } from '@/theme/Theme';

export const PointA = (
	<svg xmlns='http://www.w3.org/2000/svg' width='26' height='32' viewBox='0 0 26 32' fill='none'>
		<path
			d='M3.85327 3.70365C6.28754 1.33081 9.57459 -0.000168623 13 0C16.4254 -0.000168623 19.7125 1.33081 22.1467 3.70365C24.581 6.0765 25.9657 9.29943 26 12.672C26 21.4399 14.625 31.1999 14.0562 31.6159C13.7619 31.8638 13.3873 32 13 32C12.6127 32 12.2381 31.8638 11.9438 31.6159L11.9411 31.6136C11.4162 31.1658 0 21.4246 0 12.672C0.0342541 9.29943 1.419 6.0765 3.85327 3.70365Z'
			fill={CustomTheme.palette.primary.dark}
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M13.0045 22C17.975 22 22.0045 17.9706 22.0045 13C22.0045 8.02944 17.975 4 13.0045 4C8.03389 4 4.00446 8.02944 4.00446 13C4.00446 17.9706 8.03389 22 13.0045 22ZM18.3411 13H15.6745L15.6745 7.66667L10.3411 7.66667V13H7.67448L13.0078 18.3333L18.3411 13Z'
			fill='white'
		/>
	</svg>
);
