/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/display-name */
// @ts-nocheck
import { memo, useMemo, useState, useEffect, useRef, useCallback } from 'react';
import { Avatar, Typography } from '@mui/material';
import { IoMdLocate } from 'react-icons/io';
import { MdAddShoppingCart } from 'react-icons/md';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { VesselProperties } from './Styles';
import { AIS } from './AIS';
import { IVessel } from '@/interfaces/datalastic/IVessel';
import { getFlagByCountryIso } from '@/helpers/flagIcon';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import vesselPlaceholderImage from '@/assets/images/vesselPlaceholderImage.png';
import Button from '@/components/Button';
import { CONFIG } from '@/config';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';

export const ImageFadeIn = styled('img')`
	object-fit: cover;
	opacity: 0;
	&.loaded {
		opacity: 1;
	}
`;

export const Accordion = styled('div')`
	&:hover,
	&:focus {
		> div:first-of-type {
			background: #f2f7fa;
		}
	}
`;

interface VesselAccordionProps {
	payload: IVessel;
	onClick: (index: any) => void;
	expanded: boolean;
	flyToVessel: (center: [number, number]) => void;
}

export const VesselImage = memo(
	({
		src,
		className = '',
		placeholdOnFail = true,
		showSource = true,
	}: {
		src: string;
		className?: string;
		placeholdOnFail?: boolean;
		showSource?: boolean;
	}) => {
		const [hasLoadedImage, setHasLoadedImage] = useState(false);
		const [placeHolderImage, setPlaceHolderImage] = useState(false);
		const [hide, setHide] = useState(false);
		const { t } = useTranslation();

		const handleLoad = useCallback(() => {
			if (placeholdOnFail) {
				setHasLoadedImage(true);
			} else {
				setHide(true);
			}
		}, [placeholdOnFail]);

		const handleError = useCallback(() => {
			setPlaceHolderImage(true);
		}, []);

		return (
			<div className={`${hide ? 'hidden' : ''} relative`}>
				<ImageFadeIn
					alt={' '}
					src={
						placeHolderImage ? vesselPlaceholderImage : `https://www.ship-info.com/Vessels/${src}.jpg`
					}
					className={`${hasLoadedImage ? 'loaded ' : ''} w-full transition-opacity ${className}`}
					onError={handleError}
					onLoad={handleLoad}
				/>
				{showSource && (
					<div className={`absolute bottom-1 py-2 left-0 z-5 w-full bg-gray-200 bg-opacity-80`}>
						<a
							className='text-xs px-2 text-gray-500 italic no-underline hover:underline'
							target='_blank'
							href={`https://www.ship-info.com/prog/ship.asp?id=${src}`}
							rel='noreferrer'
						>
							{t('common.seePhotoCredit')}
						</a>
					</div>
				)}
			</div>
		);
	},
);

export const valOrDash = (value: number): number | string => (value !== 0 ? value : '-');

export const VesselDetails = memo(({ payload }: { payload: IVessel }) => {
	const { t } = useTranslation();
	return (
		<div>
			<VesselImage className='h-[300px]' src={payload.imo} />
			<VesselProperties className='grid grid-cols-3 pt-4 pb-4 gap-y-8 text-center'>
				<div>
					<span className='font-semibold text-2xl'>
						{valOrDash(Math.round((payload.dimension?.length || 0) * 100) / 100)} m
					</span>
					<Typography variant='body2' color='textSecondary' className='uppercase'>
						{t('vesselDetails.length')}
					</Typography>
				</div>
				<div>
					<span className='font-semibold text-2xl'>
						{valOrDash(Math.round((payload.dimension?.width || 0) * 100) / 100)} m
					</span>
					<Typography variant='body2' color='textSecondary' className='uppercase'>
						{t('vesselDetails.width')}
					</Typography>
				</div>
				<div>
					<span className='font-semibold text-2xl'>
						{valOrDash(Math.round((payload.dimension?.depth || 0) * 100) / 100)} m
					</span>
					<Typography variant='body2' color='textSecondary' className='uppercase'>
						{t('vesselDetails.depth')}
					</Typography>
				</div>
			</VesselProperties>
			<AIS payload={payload} />
		</div>
	);
});

export const VesselAccordion = memo(
	({ payload, onClick, flyToVessel, expanded, isAdmin }: VesselAccordionProps) => {
		const { t } = useTranslation();
		const [isInCart, setIsInCart] = useState(false);
		const [localPayload, setLocalPayload] = useState(payload);

		const countryImgSrc = useMemo(
			() => getFlagByCountryIso(localPayload.specification?.country),
			[localPayload.specification?.country],
		);

		const handleAccordionClick = useCallback(() => {
			if (!expanded) {
				onClick({ id: localPayload.id, coordinates: [localPayload.lon, localPayload.lat] });
			} else {
				onClick({ id: null, coordinates: null });
			}
		}, [expanded, onClick, localPayload.id, localPayload.lon, localPayload.lat]);

		const handleLocateClick = useCallback(
			(e: { stopPropagation: () => void }) => {
				e.stopPropagation();
				flyToVessel([localPayload.lon, localPayload.lat]);
			},
			[flyToVessel, localPayload.lon, localPayload.lat],
		);

		const checkIfInCart = useCallback(() => {
			const cart = JSON.parse(localStorage.getItem('kscart')) || [];
			return cart.some((item) => item.id === localPayload.id);
		}, [localPayload.id]);

		const handleAddToCart = useCallback(
			(e: { stopPropagation: () => void }) => {
				e.stopPropagation();
				const cart = JSON.parse(localStorage.getItem('kscart')) || [];
				if (!isInCart) {
					cart.push({
						id: localPayload.id,
						name: localPayload.name,
						ownership: localPayload.ownership,
						isAdminVerified: localPayload.isAdminVerified,
					});
					localStorage.setItem('kscart', JSON.stringify(cart));
					window.dispatchEvent(new Event('cartUpdated'));
					toast.success(`Added ${localPayload.name} to cart`, { autoClose: 1000 });
					setIsInCart(true);
				} else {
					toast.info(`${localPayload.name} is already in the cart`, { autoClose: 1000 });
				}
			},
			[
				localPayload.name,
				localPayload.id,
				localPayload.ownership,
				localPayload.isAdminVerified,
				isInCart,
			],
		);

		const handleOwnershipUpdate = useCallback((updatedPayload) => {
			updatedPayload.isAdminVerified = true;
			setLocalPayload(updatedPayload);
		}, []);

		useEffect(() => {
			setIsInCart(checkIfInCart());

			const handleStorageChange = () => {
				setIsInCart(checkIfInCart());
			};

			window.addEventListener('cartUpdated', handleStorageChange);

			return () => {
				window.removeEventListener('cartUpdated', handleStorageChange);
			};
		}, [checkIfInCart]);

		return (
			<div className='flex flex-col'>
				<Accordion
					onClick={handleAccordionClick}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							handleAccordionClick();
						}
					}}
					role='button'
					tabIndex={0}
					className='flex flex-col borderBottomSolid cursor-pointer border-b border-keelspot-border-light'
				>
					<div
						className={`${expanded ? 'bg-keelspot-pale-blue' : ''} flex justify-between w-full px-4 py-3`}
					>
						<div className='flex gap-4 items-center'>
							<Avatar
								alt={localPayload.country_iso}
								src={countryImgSrc}
								sx={{ width: 40, height: 40, backgroundColor: 'rgb(0, 45, 73)' }}
							>
								<DirectionsBoatIcon />
							</Avatar>
							<div>
								<Typography variant='body1'>
									{localPayload.name} {isAdmin && localPayload.isAdminVerified && '✅'}
								</Typography>
								<Typography variant='body2' color='textSecondary'>
									{localPayload.dl_subtype ||
										localPayload.type_specific ||
										localPayload.additionalInfo?.vesselType}
								</Typography>
							</div>
						</div>
						<div className='flex items-center gap-4'>
							{isAdmin && (
								<MdAddShoppingCart
									className='pointer'
									onClick={handleAddToCart}
									size={24}
									color={isInCart ? '#4CAF50' : '#777675'}
								/>
							)}
							<IoMdLocate className='pointer' onClick={handleLocateClick} size={24} color='#777675' />
							<ExpandMoreIcon className={`${expanded ? 'rotate-180 ' : ''}transition-transform`} />
						</div>
					</div>
				</Accordion>
				{expanded && <VesselDetails payload={localPayload} />}
				{expanded && isAdmin && (
					<OwnershipForm payload={localPayload} setPayload={handleOwnershipUpdate} />
				)}
			</div>
		);
	},
);
VesselAccordion.displayName = 'VesselAccordion';

export const VesselVisibleWrapper = (props: any) => {
	const [isVisible, setIsVisible] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const observer = new IntersectionObserver(([entry]) => {
			setIsVisible(entry.isIntersecting);
		});
		const currentRef = ref.current;
		if (currentRef) {
			observer.observe(currentRef);
		}
		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, []);

	return (
		<div className='min-h-[72px] flex flex-col' ref={ref}>
			{/* {isVisible && <VesselAccordion {...props} />} */}
			<VesselAccordion {...props} />
		</div>
	);
};

export const OwnershipForm = ({ payload, setPayload }) => {
	const [loadingStates, setLoadingStates] = useState({});
	const { getAccessTokenSilently } = useAuth0();
	const [localOwnership, setLocalOwnership] = useState([...payload.ownership, {}]);

	const copyToLocalStorage = (formData) => {
		const formValues = {
			name: formData.get('name'),
			email: formData.get('email'),
			tel: formData.get('tel'),
			web: formData.get('web'),
		};
		localStorage.setItem('ks_copiedContact', JSON.stringify(formValues));
	};

	const pasteFromLocalStorage = (form) => {
		const storedData = localStorage.getItem('ks_copiedContact');
		if (storedData) {
			const parsedData = JSON.parse(storedData);
			form.elements.name.value = parsedData.name || '';
			form.elements.email.value = parsedData.email || '';
			form.elements.tel.value = parsedData.tel || '';
			form.elements.web.value = parsedData.web || '';
		}
	};

	useEffect(() => {
		setLocalOwnership([...payload.ownership, {}]);
	}, [payload.ownership]);

	const handleDelete = async (ownershipId) => {
		setLoadingStates((prev) => ({ ...prev, [`delete_${ownershipId}`]: true }));

		const { access_token } = await getAccessTokenSilently({ detailedResponse: true });

		try {
			const response = await fetch(`${CONFIG.API_V2}/stats-admin/vessel-ownership`, {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access_token}`,
				},
				body: JSON.stringify({
					shipId: payload.id,
					ownershipId: ownershipId,
				}),
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			// Update the local state
			const newOwnership = payload?.ownership?.filter((item) => item._id !== ownershipId);
			setPayload({ ...payload, ownership: newOwnership });

			toast.success('Deleted successfully!');
		} catch (error) {
			console.error('Error:', error);
			toast.error(`Failed to delete. ${error}`);
		} finally {
			setLoadingStates((prev) => ({ ...prev, [`delete_${ownershipId}`]: false }));
		}
	};

	const handleSubmit = async (event, index) => {
		event.preventDefault();
		const ownerData = Object.fromEntries(new FormData(event.target).entries());
		ownerData.shipId = payload.id;

		if (index === payload?.ownership?.length) {
			delete ownerData._id; // New entry
		} else {
			ownerData._id = payload.ownership?.[index]._id; // Existing entry
		}

		setLoadingStates((prev) => ({ ...prev, [index]: true }));
		const { access_token } = await getAccessTokenSilently({ detailedResponse: true });

		try {
			const response = await fetch(`${CONFIG.API_V2}/stats-admin/vessel-ownership`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${access_token}`,
				},
				body: JSON.stringify(ownerData),
			});

			if (!response.ok) {
				toast.error(`Failed to save. ${response.statusText}`);
				throw new Error('Network response was not ok');
			}

			const result = await response.json();
			const newOwnership = [...payload.ownership];

			if (index === payload.ownership?.length) {
				newOwnership.push(result);
			} else {
				newOwnership[index] = result;
			}
			setPayload({ ...payload, ownership: newOwnership });

			toast.success('Saved successfully!');
		} catch (error) {
			console.error('Error:', error);
			toast.error(`Failed to save. ${error}`);
		} finally {
			setLoadingStates((prev) => ({ ...prev, [index]: false }));
		}
	};

	if (!payload.ownership) {
		return <></>;
	}

	return (
		<div className='flex flex-col'>
			{localOwnership.map((owner, index) => (
				<form
					key={owner._id || index}
					className='mb-4 p-4 border rounded shadow-sm'
					onSubmit={(e) => handleSubmit(e, index)}
				>
					<input type='hidden' name='_id' defaultValue={owner._id || ''} />
					<div className='mb-2'>
						<label className='block text-sm font-medium text-gray-700'>Name</label>
						<input
							type='text'
							name='name'
							defaultValue={owner.name || ''}
							className='mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
						/>
					</div>
					<div className='mb-2'>
						<label className='block text-sm font-medium text-gray-700'>Email</label>
						<input
							type='text'
							name='email'
							defaultValue={owner.email || ''}
							className='mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
						/>
					</div>
					<div className='mb-2'>
						<label className='block text-sm font-medium text-gray-700'>Tel</label>
						<input
							type='text'
							name='tel'
							defaultValue={owner.tel || ''}
							className='mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
						/>
					</div>
					<div className='mb-2'>
						<label className='block text-sm font-medium text-gray-700'>Web</label>
						<input
							type='text'
							name='web'
							defaultValue={owner.web || ''}
							className='mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
						/>
					</div>
					<div className='flex flex-row gap-2'>
						<Button
							color='info'
							type='button'
							variant='outlined'
							size='medium'
							onClick={(e) => {
								e.preventDefault();
								const form = e.target.closest('form');
								const formData = new FormData(form);
								copyToLocalStorage(formData);
								toast.success(`Copied!`, { autoClose: 600 });
							}}
						>
							Copy
						</Button>
						<Button
							color='info'
							type='button'
							variant='outlined'
							size='medium'
							onClick={(e) => {
								e.preventDefault();
								const form = e.target.closest('form');
								pasteFromLocalStorage(form);
							}}
						>
							Paste
						</Button>
						<Button type='submit' variant='outlined' size='medium' disabled={loadingStates[index]}>
							{loadingStates[index] ? 'Saving...' : index === payload?.ownership?.length ? 'Add' : 'Save'}
						</Button>
						{index !== payload?.ownership?.length && (
							<Button
								type='button'
								variant='outlined'
								color='error'
								size='medium'
								onClick={() => handleDelete(owner._id)}
								disabled={loadingStates[`delete_${owner._id}`]}
							>
								{loadingStates[`delete_${owner._id}`] ? 'Deleting...' : 'Delete'}
							</Button>
						)}
					</div>
				</form>
			))}
		</div>
	);
};
