import { useCallback, useState } from 'react';
import { Tooltip } from '@mui/material';
import { CreditScoreModal } from './CreditScoreModal';
import type { SizeType } from './CompanyInfo';

export const CreditScoreDots = ({ creditScore, ...rest }: { creditScore: number }) => {
	return (
		<div {...rest} className='cursor-pointer flex gap-2 items-center'>
			<span className='text-xs font-normal'>Creditsafe™ </span>
			<div className='flex gap-[1px] p-1 rounded-sm border-1 border-solid border-keelspot-border-light'>
				{Array.from({ length: 5 }, (_, i) => {
					let dotColor;
					if (creditScore >= 0 && creditScore <= 20) {
						dotColor = 'bg-red-500';
					} else if (creditScore > 20 && creditScore <= 60) {
						dotColor = 'bg-yellow-500';
					} else if (creditScore > 60 && creditScore <= 100) {
						dotColor = 'bg-green-500';
					} else {
						dotColor = 'bg-gray-200';
					}
					const coloredDotsThreshold = Math.ceil(creditScore / 20);
					const isColored = i < coloredDotsThreshold;

					return (
						<div
							key={i}
							className={`w-2 h-2 ${isColored ? dotColor : 'bg-gray-200'}`}
							style={{ transition: 'background-color 0.3s' }}
						/>
					);
				})}
			</div>
			<span className='text-xs font-semibold'>{creditScore ? creditScore : '?'}/100</span>
		</div>
	);
};

export const CreditScore = ({
	creditScore,
	creditGrade,
	creditDescription,
	size,
}: {
	creditScore: number;
	creditDescription: string | undefined;
	creditGrade: string | undefined;
	size: SizeType;
}) => {
	const [showModal, setShowModal] = useState(false);
	const creditScoreClass = useCallback(() => {
		if (!creditScore) {
			return 'bg-gray-200 hover:bg-gray-300';
		}
		if (creditScore > 90) {
			return 'bg-green-500 hover:bg-green-600';
		}
		if (creditScore > 70) {
			return 'bg-yellow-500 hover:bg-yellow-600';
		}
		return 'bg-red-500 hover:bg-red-600';
	}, [creditScore]);
	const wrapperButtonProps = {
		role: 'button',
		tabIndex: 0,
		onClick: () => setShowModal(true),
		onKeyUp: (e: any) => {
			if (e.key === 'Enter') {
				setShowModal(true);
			}
		},
	};
	return (
		<>
			<Tooltip title='Learn more about this rating' arrow>
				<>
					{size === 'sm' && <CreditScoreDots {...wrapperButtonProps} creditScore={creditScore} />}
					{size !== 'sm' && (
						<div
							className={`cursor-pointer w-[100px] h-[100px] flex flex-col items-center justify-center rounded ${creditScoreClass()}`}
							{...wrapperButtonProps}
						>
							{creditScore > 0 ? (
								<>
									<span className='text-xs'>Credit rating</span>
									<span className='text-3xl font-semibold'>{creditScore > 0 ? creditScore : '?'}</span>
									<span className='text-xs font-semibold'>out of 100</span>
								</>
							) : (
								<div className='text-xs text-center px-2'>Unknown credit score</div>
							)}
						</div>
					)}
				</>
			</Tooltip>
			{showModal && (
				<CreditScoreModal
					creditGrade={creditGrade}
					creditDescription={creditDescription}
					onClose={() => setShowModal(false)}
				/>
			)}
		</>
	);
};
