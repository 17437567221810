const config = {
	NODE_ENV: import.meta.env.NODE_ENV,
	APP_ENV: import.meta.env.KEELSPOT_APP_ENV,
	BASE_URI: import.meta.env.KEELSPOT_API_URI,
	API_V2: import.meta.env.KEELSPOT_API_URI.replace('/graphql/', '/v2'),
	PUBLIC_BASE_URI: import.meta.env.KEELSPOT_PUBLIC_API_URI,
	AUTH0_DOMAIN: import.meta.env.KEELSPOT_AUTH0_DOMAIN,
	AUTH0_CLIENT_ID: import.meta.env.KEELSPOT_AUTH0_CLIENT_ID,
	AUTH0_AUDIENCE: import.meta.env.KEELSPOT_AUTH0_AUDIENCE,
	MAPBOX_ACCESS_TOKEN: import.meta.env.KEELSPOT_MAPBOX_ACCESS_TOKEN,
	GEOAPIFY_BASE_URI: import.meta.env.KEELSPOT_GEOAPIFY_BASE_URI,
	GEOAPIFY_API_KEY: import.meta.env.KEELSPOT_GEOAPIFY_API_KEY,
	MUI_X_PRO_LICENSE_KEY: import.meta.env.KEELSPOT_MUI_X_PRO_LICENSE_KEY,
	TALK_JS_USER_ID: import.meta.env.TALK_JS_USER_ID,
	GOOGLE_ANALYTICS_ID: import.meta.env.KEELSPOT_GOOGLE_ANALYTICS_ID,
	WARNING_MESSAGE: undefined,
};

export default config;
