export const APP = 'app';
export const AUTH = 'auth';
export const REGISTER = 'register';
export const PENDING_CONFIRM_EMAIL = 'pending-confirm-email';
export const INVITATION = 'invitation';
export const COMPANY_DETAILS = 'company-details';
export const CONFIRM_EMAIL = 'confirm-email';
export const SELECT_ROLE = 'select-role';
export const FORGET_PASSWORD = 'forget-password';
export const RESET_PASSWORD = 'reset-password';
export const EMAIL_VERIFY = 'email-verify';
export const INSURANCE_ADMIN = 'insurance-admin';
export const ADMIN = {
	INDEX: 'admin',
	OKR: {
		INDEX: 'reports',
	},
	USERS: {
		INDEX: 'users',
		DETAIL: 'detail/:userID',
	},
	FREIGHT_REQUESTS: {
		INDEX: 'freight-requests-list',
		DETAIL: 'detail/:requestID',
	},
	VIEWCLICKS: {
		DETAIL: 'viewclick/:shipmentID',
	},
	DELIVERIES: {
		INDEX: 'deliveries',
	},
	STATS: {
		INDEX: 'stats',
	},
	MATCH_MAKING: {
		INDEX: 'match-making',
		MATCH_MAKING_TODO: 'match-making-todo/:tab',
		SHIPMENT: 'shipment/:shipmentId',
		TODOS: 'todos/:shipmentId',
		EMAIL_APPROVALS: 'email-approvals/:shipmentId',
	},
	AUTOMATED_MATCHMAKING: {
		INDEX: 'automated-matchmaking',
	},
	CIRCULATION: {
		INDEX: 'circulation',
		CIRCULATE: 'circulate',
		POKE_SHIPPERS: 'poke-shippers',
		EDIT_LISTS: 'edit-lists',
	},
};

export const SHARE = {
	INDEX: 'share',
	SHIPMENT: {
		INDEX: 'shipments',
		DETAIL: 'detail/:requestID',
	},
	VESSEL: {
		INDEX: 'vessels',
		DETAIL: 'vessels/:vesselID',
		DETAILSLUG: 'vessels/:vesselID/:slug',
	},
};

// export const PUBVES = {
// 	INDEX: 'vessels',
// 	VESSEL: {
// 		INDEX: 'vessels',
// 		DETAIL: 'vessels/:vesselID',
// 		// DETAILSLUG: 'vessels/:vesselID/:slug',
// 	},
// };

export const MAP = 'map';
export const PROFILE = 'profile';
export const COMPANY_PROFILE = {
	INDEX: 'company',
	VIEW: 'company/:companyID',
	// VIEW_TABS: 'company/:companyID',
	VIEW_TABS: 'company/:companyID/:tab',
	EDIT: 'edit',
	TAB: ':tab',
};

// export const DELIVERIES = {
// 	INDEX: 'deliveries',
// 	TAB: ':tab',
// 	VIEW: 'view/:deliveryDetailsID',
// 	VIEW_TABS: 'view/:deliveryDetailsID/:tab',
// };

export const REQUEST_DETAIL = {
	INDEX: 'request/:requestID',
	EDIT: 'edit',
	MAKE_OFFER: 'make-offer',
	OFFER_SUMMARY: 'offer-summary',
};

export const OFFERS = {
	INDEX: 'offers',
	VIEW: 'view/:offerID',
	EDIT: 'edit/:requestID',
	COUNTER_OFFER: 'negotiate/:offerID',
};

export const FLEET = {
	INDEX: 'fleet',
	VIEW: 'view/:vesselID',
	EDIT: 'edit/:vesselID',
	ADD: 'add/:imoNumber',
	AUTOFILL_SEARCH_IMO: 'search-imo',
};

export const AVAILABLECAPACITY = {
	INDEX: 'capacity',
	INQUIRE: 'inquire/:capacityID',
	INQUIRY: 'inquiry/:inquiryID',
};

export const INVOICES = {
	INDEX: 'invoices',
};

//Freight
export const FREIGHT = {
	INDEX: 'freight',
	REQUEST: 'request',
	REQUEST_DRAFT: 'request/:freightID/:tab',
	SUMMARY: 'summary',
	BUNDLE_SUMMARY: 'bundle-summary',
	VIEW: 'view/:freightID',
	EDIT: 'edit/:freightID',
	BIDS: 'offers/:freightID',
};

export const CHAT = {
	INDEX: 'inbox',
	CONVERSATION: 'conversation/:conversationID',
};

export const FREIGHT_FORWARDER_MAP = 'ff-map';
export const SHIP_OPERATOR_MAP = 'so-map';

export const FREIGHT_FORWARDER = 'freight-forwarder';
export const SHIP_OPERATOR = 'ship-operator';

export const DELIVERIES = {
	INDEX: 'deliveries',
	TAB: ':tab',
	VIEW: 'view/:deliveryDetailsID',
	VIEW_TABS: 'view/:deliveryDetailsID/:tab',
};

export const DEMO = {
	INDEX: 'demo',
};

export const CARRIERS = {
	INDEX: 'carriers',
};

export const POSITIONS = {
	INDEX: 'positions',
	MAP: 'map',
	ADD: 'add',
};

export const HEATMAP = {
	INDEX: 'heatmap',
	MAP: 'map',
};
