import { FC, createContext, Dispatch, SetStateAction, ReactNode, useContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocalStorage } from '@hooks/useLocalStorage';

type ThemeMode = 'dark' | 'light';
interface ICurrentTheme {
	currentTheme: ThemeMode;
	setTheme: Dispatch<SetStateAction<ThemeMode>>;
}
interface Props {
	children: ReactNode;
}
const CurrentThemeContext = createContext<ICurrentTheme>({} as ICurrentTheme);
const ThemeContextProvider: FC<Props> = ({ children }) => {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const [theme, setTheme] = useLocalStorage<ThemeMode>('theme', prefersDarkMode ? 'dark' : 'light');

	return (
		<CurrentThemeContext.Provider value={{ currentTheme: theme, setTheme }}>
			{children}
		</CurrentThemeContext.Provider>
	);
};

const useThemeContext = () => {
	const context = useContext(CurrentThemeContext);

	if (context) {
		return context;
	}

	throw new Error(`useThemeContext must be used within a AuthContextProvider`);
};

export { ThemeContextProvider, useThemeContext };
