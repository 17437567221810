import { shadows } from './variables/shadows';
import { components } from './variables/components';
import {
	background,
	divider,
	error,
	info,
	neutral,
	primary,
	secondary,
	success,
	text,
	warning,
	grey,
	common,
	snackbar,
} from './variables/colors';

export type PaletteMode = 'light' | 'dark';

export const lightThemeOptions = {
	components,
	palette: {
		common,
		background,
		grey,
		divider,
		error,
		info,
		mode: 'light' as PaletteMode,
		neutral,
		primary,
		secondary,
		success,
		text,
		warning,
		snackbar,
	},
	shadows,
};
