import { useQueries } from '@/graphql/useQueries';
import {
	CREATE_VESSEL,
	DELETE_VESSEL,
	GET_EACH_VESSEL,
	LIST_OWN_VESSEL,
	UPDATE_VESSEL,
} from '@/graphql/vessel';
import { useMutations } from '@/graphql/useMutations';
import { VESSEL_SEARCH } from '@/graphql/integrations';
import {
	GET_VESSEL_TYPES,
	LIST_ALL_VESSELS_DATALASTIC,
	LIST_ALL_VESSELS_DETAILED_DATALASTIC_ADMIN,
	LIST_VESSEL_PORT_ADDRESS,
} from '@/graphql/vessel/query';
import { IOption } from '@/components/map/GeocoderControl';

export const useCreateVessel = (callback?: any) =>
	useMutations(['listAllVesselsByOwner'], CREATE_VESSEL, {
		onSuccess: () => {
			callback?.();
		},
	});

export const useUpdateVessel = (callback?: any) =>
	useMutations(['listAllVesselsByOwner'], UPDATE_VESSEL, {
		onSuccess: () => {
			callback?.();
		},
	});

export const useGetOwnVessel = () =>
	useQueries<any>({
		queryName: 'listAllVesselsByOwner',
		gql: LIST_OWN_VESSEL,
	});

export const useGetVesselDetail = (id?: string) =>
	useQueries<any>({
		queryName: 'vessel',
		gql: GET_EACH_VESSEL,
		args: { id },
	});

// XXX: Deprecate this function and use local values
export const useGetVesselTypes = () =>
	useQueries<any>({
		queryName: 'listVesselTypes',
		gql: GET_VESSEL_TYPES,
	});

export const useGetVesselSearch = ({ imoNumber }: { imoNumber: string }) =>
	useQueries<any>({
		queryName: 'vesselSearch',
		gql: VESSEL_SEARCH,
		args: { imoNumber: imoNumber },
		config: {
			enabled: !!imoNumber,
			retryOnMount: false,
		},
	});

export const useGetVesselHistoricalPositionDatalastic = (id: string) =>
	useQueries<any>({
		queryName: 'trackVesselsHistoryPosition',
		gql: LIST_ALL_VESSELS_DATALASTIC,
		args: { id: id ?? null },
	});

export const useGetTrackVesselsCurrentPosition = (owner?: string) =>
	useQueries<any>({
		queryName: 'trackVesselsCurrentPosition',
		gql: LIST_ALL_VESSELS_DETAILED_DATALASTIC_ADMIN,
		args: { owner: owner },
	});

export const useGetVesselPortAndAddress = (owner: string, query: string, enable = false) =>
	useQueries<IOption[]>({
		queryName: 'searchByPortVesselAndAddress',
		gql: LIST_VESSEL_PORT_ADDRESS,
		args: { owner, query },
		config: {
			enabled: enable,
		},
	});

export const useDeleteVessel = (callback?: any) =>
	useMutations(['listAllVesselsByOwner'], DELETE_VESSEL, {
		onSuccess: () => {
			callback?.();
		},
	});
