import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@/components/modal';
import { Typography } from '@mui/material';
import Button from '@components/Button';
import { InlineTextButton } from '@/components/atomic/InlineTextButton';
import TextLabel from '@/components/atomic/TextLabel';
import { MdAutoFixHigh } from 'react-icons/md';

const DEFAULT_DELAY = 5000;

export const SignupModalContent = () => {
	const navigate = useNavigate();
	return (
		<div className='flex flex-col px-8 pb-8 gap-6'>
			<div className='flex flex-col gap-2 items-center'>
				<TextLabel
					size='sm'
					text={`Register for free!`}
					color='success'
					icon={<MdAutoFixHigh size={20} />}
				/>
				<Typography className='text-center' variant='h5'>
					Join Keelspot today!
				</Typography>
				<Typography className='text-center' variant='body1'>
					Get access to all our features and more!
				</Typography>
			</div>
			<div className='flex flex-col gap-1 items-center'>
				<Button
					className='w-full'
					onClick={() => {
						window.open('https://api.keelspot.com/signup');
					}}
				>
					Register New Account
				</Button>
				<Button
					variant='outlined'
					onClick={() => navigate('https://app.keelspot.com')}
					className='w-full'
				>
					Login
				</Button>
			</div>
			<div className='flex flex-col items-center'>
				<span>Find out more on our webpage</span>
				<span>
					<InlineTextButton as='a' href='https://www.keelspot.com'>
						Keelspot.com
					</InlineTextButton>
				</span>
			</div>
		</div>
	);
};

export const PromptSignup = ({ active, delay }: { active: boolean; delay?: number }) => {
	const [isOpen, setIsOpen] = useState(false);
	useEffect(() => {
		let timeout: any;
		try {
			clearTimeout(timeout);
		} catch (e) {
			/* Do nothin */
		}
		if (active) {
			timeout = setTimeout(() => {
				setIsOpen(true);
			}, delay || DEFAULT_DELAY);
		}
		return () => {
			clearTimeout(timeout);
		};
	}, [active, delay]);
	if (!open) {
		return null;
	}
	return (
		<Modal title=' ' isOpen={isOpen} onClose={() => setIsOpen(false)} dialogAction={false}>
			<SignupModalContent />
		</Modal>
	);
};
