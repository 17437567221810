import { gql } from 'graphql-request';

export const UPDATE_NOTIFICATION = gql`
	mutation ($_id: String!) {
		updateNotificationStatus(_id: $_id) {
			message
			data {
				_id
				notification
				type
				active
				relationId
			}
		}
	}
`;
export const DELETE_NOTIFICATION = gql`
	mutation ($_id: String!) {
		deleteNotification(_id: $_id) {
			message
			data {
				_id
				notification
				type
				active
				relationId
			}
		}
	}
`;
