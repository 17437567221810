import { useMutations } from '@/graphql/useMutations';
import { gql } from 'graphql-request';

export const CREATE_VIEWCLICK = gql`
	mutation ($input: CreateViewClick!) {
		createViewClick(input: $input) {
			message
		}
	}
`;

export const useCreateViewClick = (callback?: any) =>
	useMutations(['createViewClick'], CREATE_VIEWCLICK, {
		onSuccess: (args: any) => {
			callback?.(args?.data);
		},
	});
