import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { SelectProps } from '@mui/material/Select';
import { CircularProgress } from '@mui/material';

export type ControlledSelectProps<
	Schema extends FieldValues,
	Name extends Path<Schema>,
	Value = PathValue<Schema, Name>,
> = Omit<SelectProps<Value>, 'name' | 'control'> & {
	name: Name;
	label?: string;
	control: Control<Schema>;
	required?: boolean;
	rules?: any;
	children: any;
	inputProps?: any;
	className?: string;
	disabled?: boolean;
	isLoading?: boolean;
};

function ControlledSelect<Schema extends FieldValues, Name extends Path<Schema>>({
	name,
	label,
	control,
	children,
	className,
	rules,
	inputProps,
	disabled,
	isLoading,
	...props
}: ControlledSelectProps<Schema, Name>) {
	const labelId = `${name}-label`;
	return (
		<FormControl fullWidth>
			<InputLabel id={labelId} disabled={disabled ?? isLoading}>
				{label}
			</InputLabel>
			<Controller
				rules={rules}
				name={name}
				control={control}
				render={({ field: { onBlur, onChange, value, ref }, fieldState: { error } }) => {
					const hasError = !!error;
					const errorMsg = hasError ? error.message : ' ';
					if (props.inputRef) {
						ref(props.inputRef);
					}
					return (
						<>
							<Select<PathValue<Schema, Name>>
								labelId={labelId}
								label={label}
								error={hasError}
								onChange={onChange}
								onBlur={onBlur}
								value={value}
								inputRef={ref}
								inputProps={inputProps}
								{...props}
								disabled={disabled ?? isLoading}
								className={className}
								startAdornment={isLoading && <CircularProgress size={20} />}
							>
								{children}
							</Select>
							{hasError && <FormHelperText error={hasError}>{errorMsg}</FormHelperText>}
						</>
					);
				}}
			/>
		</FormControl>
	);
}
export default ControlledSelect;
