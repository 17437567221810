import { useState, useRef } from 'react';
import { MessageIcon } from './toolbar/MessageIcon';
import { styled } from '@mui/material';
import {
	AccountButton,
	NotificationsButton,
	SwitchOperationButton,
	SwitchOperationDemoButton,
	SinceLastLogin,
} from './toolbar';
import { isInDemoMode } from '@/utils/isInDemoMode';
import Button from '@/components/Button';
import { PromptSignup } from '@/components/PromptSignup';
import { CartIcon } from './toolbar/CartIcon';
import useGetCurrentUser from '@/hooks/useGetCurrentUser';

const DemoTag = styled('div')`
	width: 0;
	height: 0;
	border-top: 70px solid #ff0000;
	border-bottom: 50px solid transparent;
	border-left: 140px solid transparent;
	position: fixed;
	pointer-events: none;
	top: 0;
	right: 0;
	span {
		position: absolute;
		top: -59px;
		width: 100px;
		right: -6px;
		text-align: center;
		font-size: 14px;
		transform: rotate(28deg);
		display: block;
		color: #fff;
		font-weight: 700;
		line-height: 14px;
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
	}
`;

export const Navbar = () => {
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [promptActive, setPromptActive] = useState(true);
	const [promptTimer, setPromptTimer] = useState(90000); // Lets give them 1.5 minutes before prompting
	const { currentUser } = useGetCurrentUser();
	const isAdmin = currentUser?.isAdmin;
	return (
		<div ref={containerRef} className='flex gap-4'>
			{!isInDemoMode ? (
				<div className='flex'>
					{/*<LanguageButton currentComponent={containerRef.current} />*/}
					<SinceLastLogin />
					{isAdmin && <CartIcon currentComponent={containerRef.current} />}
					<MessageIcon currentComponent={containerRef.current} />
					<NotificationsButton currentComponent={containerRef.current} />
				</div>
			) : (
				<div className='flex items-center'>
					<PromptSignup delay={promptTimer} active={promptActive} />
					<Button
						color='success'
						onClick={() => {
							setPromptTimer(100);
							setPromptActive(true);
						}}
					>
						Create an Account
					</Button>
				</div>
			)}
			{isInDemoMode ? (
				<SwitchOperationDemoButton className='max-lg:hidden' />
			) : (
				<SwitchOperationButton className='max-lg:hidden' />
			)}
			{isInDemoMode && (
				<DemoTag>
					<span>
						NOW
						<br />
						DEMO MODE!
					</span>
				</DemoTag>
			)}
			<div className={`flex items-center ${isInDemoMode ? 'mr-20' : ''}`}>
				<AccountButton currentComponent={containerRef.current} />
			</div>
		</div>
	);
};
